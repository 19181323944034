// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deactivateButtonComponent .btn[data-v-c700fcd2] {
  height: 3em;
}
.deactivateButtonComponent .fadeHeight-enter-active[data-v-c700fcd2],
.deactivateButtonComponent .fadeHeight-leave-active[data-v-c700fcd2] {
  transition-duration: 0.5s;
  transition-property: all;
  transition-timing-function: ease-in-out;
  max-height: 2300px;
  overflow: hidden;
}
.deactivateButtonComponent .fadeHeight-enter[data-v-c700fcd2],
.deactivateButtonComponent .fadeHeight-leave-to[data-v-c700fcd2] {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./_deactivate-button.scss"],"names":[],"mappings":"AAEI;EACI,WAAA;AADR;AAII;;EAEI,yBAAA;EACA,wBAAA;EACA,uCAAA;EACA,kBAAA;EACA,gBAAA;AAFR;AAII;;EAGI,UAAA;EACA,eAAA;EACA,gBAAA;AAHR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n.deactivateButtonComponent {\n    .btn {\n        height: 3em;\n    }\n\n    .fadeHeight-enter-active,\n    .fadeHeight-leave-active {\n        transition-duration: 0.5s;\n        transition-property: all;\n        transition-timing-function: ease-in-out;\n        max-height: 2300px;\n        overflow: hidden;\n    }\n    .fadeHeight-enter,\n    .fadeHeight-leave-to\n    {\n        opacity: 0;\n        max-height: 0px;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
