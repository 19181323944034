import PlaidLink from 'vue_root/authorized/subscriber/components/plaid-link/plaid-link';
import eventBus from 'vue_root/plugins/eventBus.js';

export default {
    components: {
        PlaidLink,
    },
    data,
    mounted,
    created,
    beforeDestroy,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
};

function created(){
    this.initializeView();
}

function mounted(){
    eventBus.$on('bank-accounts-refresh', this.refreshBankAccountsList);
}

function beforeDestroy(){
    eventBus.$off('bank-accounts-refresh', this.refreshBankAccountsList);
}

function data(){
    return {
        bankAccounts: [],
        isSaving: false,
        errorMessages: [],
    };
}

function getComputed(){
    return {
        noAccountsLinked,
        loadingBankAccounts,
        accountPurposeOptions,
        canContinue,
        checkboxChecking,
        checkboxCredit,
    };

    function noAccountsLinked(){
        return !this.bankAccounts.length;
    }

    function loadingBankAccounts(){
        const vm = this;
        return vm.$store.state.authorized.bankAccounts.isFetchingBankAccounts;
    }

    function accountPurposeOptions(){
        const vm = this;
        const primaryCheckingOptionDisabled = vm.bankAccounts.some(({ purpose }) => purpose === 'primary_checking');
        const options = [
            { text: 'Select account type', value: '', disabled: true },
            { text: 'Checking Account', value: 'primary_checking', disabled: primaryCheckingOptionDisabled },
            { text: 'Savings Account', value: 'primary_savings', disabled: false },
            { text: 'Credit Card', value: 'credit', disabled: false },
        ];
        return options;
    }

    function canContinue(){
        const haveSingleCheckingAccount = this.bankAccounts.filter(({ purpose }) => purpose === 'primary_checking').length === 1;
        this.$store.commit('authorized/bankAccounts/SET_IS_CHECKING_ACCOUNT_SELECTED', haveSingleCheckingAccount);

        return haveSingleCheckingAccount;
    }

    function checkboxChecking(){
        return this.bankAccounts.some(({ purpose }) => purpose === 'primary_checking');
    }

    function checkboxCredit(){
        return this.bankAccounts.some(({ purpose }) => purpose === 'credit');
    }
}

function getWatchers(){
    return {
        loadingBankAccounts(newStatus, oldStatus){
            if(oldStatus && !newStatus){
                this.refreshBankAccountsList(true);
            }
        }
    };
}

function getMethods(){
    return {
        initializeView,
        refreshBankAccountsList,
        sortBankAccounts,
        setBankAccountDisplayProperties,
        updateBankAccount,
        saveAndContinue,
    };

    function initializeView(){
        return this.refreshBankAccountsList();
    }

    function refreshBankAccountsList(reset = false){
        const vm = this;
        const localCopyOfBankAccounts = JSON.parse(JSON.stringify(vm.$store.state.authorized.bankAccounts.bankAccounts));
        const localCopyOfInsitutionBankAccounts = localCopyOfBankAccounts.filter(({ institution_account }) => !!institution_account);
        localCopyOfInsitutionBankAccounts.forEach(vm.setBankAccountDisplayProperties);
        if(reset){
            vm.bankAccounts = localCopyOfInsitutionBankAccounts;
        } else {
            localCopyOfInsitutionBankAccounts.forEach(updateDisplayedAccountList);
        }
        vm.sortBankAccounts();
        return Promise.resolve();

        function updateDisplayedAccountList(bankAccount){
            const workingCopy = vm.bankAccounts.find(({ id }) => id === +bankAccount.id);
            if(!workingCopy){
                vm.bankAccounts.push(bankAccount);
            } else if(!workingCopy.isDirty){
                Object.assign(workingCopy, bankAccount);
            }
        }
    }

    function sortBankAccounts(){
        const vm = this;
        vm.bankAccounts = vm.bankAccounts.sort((a, b) => {
            const accountPurposeOrder = [
                '',
                'primary_checking',
                'primary_savings',
                'credit',
            ];
            const checkedPurposes = [];
            let isHigher = false;
            accountPurposeOrder.forEach(purpose => {
                isHigher = isHigher || a.purpose === purpose && !checkedPurposes.includes(b.purpose);
                if(!isHigher){
                    checkedPurposes.push(purpose);
                }
            });
            if(a.purpose === b.purpose){
                isHigher = a.created_at < b.created_at;
            }
            return isHigher ? -1 : 0;
        });
    }

    function setBankAccountDisplayProperties(bankAccount, defaults = {}){
        const vm = this;
        const accountPurposeValid = ['primary_checking', 'primary_savings', 'credit'].includes(bankAccount.purpose);
        if(!accountPurposeValid){
            bankAccount.purpose = '';
        }
        vm.$set(bankAccount, 'originalPurpose', bankAccount.purpose);
        vm.$set(bankAccount, 'isSaving', (defaults.isSaving || false));
    }

    async function saveAndContinue(){
        const vm = this;
        try {
            vm.isSaving = true;
            vm.errorMessages = [];
            const dirtyBankAccounts = vm.bankAccounts.filter(({ originalPurpose, purpose }) => originalPurpose !== purpose);
            const primaryCheckingAccount = dirtyBankAccounts.filter(({ purpose }) => purpose === 'primary_checking')[0];
            const saveOtherPromises = dirtyBankAccounts.filter(({ purpose }) => purpose !== 'primary_checking').map(vm.updateBankAccount);
            await Promise.all(saveOtherPromises);

            return vm.updateBankAccount(primaryCheckingAccount)
                .then(() => {
                    vm.bankAccounts.splice(0);
                    vm.$nextTick(() => {
                        vm.refreshBankAccountsList(true);
                        vm.$router.push({ 'name': 'onboarding-buckets' });
                    });
                }).finally(resetLoadingState);
        } catch(error){
            const errorMessage = error.appMessage || (error.data && error.data.message);
            if(errorMessage){
                vm.errorMessages.push(errorMessage);
            }
        }

        function resetLoadingState(){
            vm.isSaving = false;
        }
    }

    function updateBankAccount(bankAccount){
        const vm = this;
        const isCreditCardAccount = bankAccount.purpose === 'credit';
        if(isCreditCardAccount){
            bankAccount.name = bankAccount.institution_account.name || 'Credit Card';
        }

        return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', bankAccount)
            .then(onUpdateBankAccount)
            .catch(updateBankAccountFailure);

        function onUpdateBankAccount(){
            const isSavingAccount = bankAccount.purpose === 'primary_savings';
            if(!isSavingAccount){
                return Promise.resolve();
            } else {
                const childSavingBucket = new BankAccount({
                    name: bankAccount.institution_account.name || '',
                    purpose: 'savings',
                    color: 'cyan',
                    balance_current: bankAccount.balance_current || 0,
                    parent_bank_account_id: bankAccount.id,
                });

                return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', childSavingBucket);
            }
        }

        function updateBankAccountFailure(error){
            const errorMessage = error.appMessage || (error.data && error.data.message);
            if(errorMessage){
                vm.errorMessages.push(errorMessage);
            }
        }
    }
}

function BankAccount(defaults = {}){
    this.parent_bank_account_id = defaults.parent_bank_account_id || null;
    this.sub_accounts = defaults.sub_accounts || [];
    this.sub_account_order = isNaN(parseInt(defaults.sub_account_order)) ? null : defaults.sub_account_order;
    this.name = defaults.name || '';
    this.slug = defaults.slug || null;
    this.color = defaults.color || '';
    this.purpose = defaults.purpose || 'none';
    this.is_balance_overridden = defaults.is_balance_overridden || false;
    this.appears_in_account_list = defaults.appears_in_account_list || false;
    this.balance_current = defaults.balance_current || 0;
    this.balance_available = defaults.balance_available || 0;
    this.allocation_balance_adjustment = defaults.allocation_balance_adjustment || 0;
    this.assignment_balance_adjustment = defaults.assignment_balance_adjustment || 0;
    this.id = defaults.id || null;
}
