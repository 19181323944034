// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reportsPage[data-v-72631a14] {
  padding-bottom: 25px;
}
.reportsPage .graphSection[data-v-72631a14] {
  margin-bottom: 25px;
}
@media only screen and (max-width : 768px) {
.reportsPage .container[data-v-72631a14] {
    margin: 0;
    padding: 0;
}
}`, "",{"version":3,"sources":["webpack://./_reports.scss"],"names":[],"mappings":"AACA;EACI,oBAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;AAGI;AACI;IACI,SAAA;IACA,UAAA;AADV;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n.reportsPage {\n    padding-bottom: 25px;\n\n    .graphSection {\n        margin-bottom: 25px;\n    }\n    \n    @media #{$breakpointTabletMax} {\n        .container {\n            margin: 0;\n            padding: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
