import { render, staticRenderFns } from "./create-coupon.vue?vue&type=template&id=05b8b021&scoped=true"
import script from "./create-coupon.controller.js?vue&type=script&lang=js&external"
export * from "./create-coupon.controller.js?vue&type=script&lang=js&external"
import style0 from "./_create-coupon.scss?vue&type=style&index=0&id=05b8b021&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b8b021",
  null
  
)

export default component.exports