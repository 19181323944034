import * as _ from 'lodash';

export default {
    created: created,
    computed: getComputed(),
    data: data,
    methods: getMethods(),
    destroyed: destroyed
};

function created(){
    const vm = this;
    const plaidEnv = window.appEnv.plaidEnv;
    if(!plaidEnv || plaidEnv.toLowerCase() !== 'sandbox'){
        vm.isDisabled = true;
        return;
    }

    vm.getAllUsers();
    vm.debouncedHandlerGetAllUsers = _.debounce(vm.getAllUsers, 500);
    vm.isLocalWebhookTestingDisabled = !process.env.SMEE_CLIENT_SOURCE;
}

function destroyed(){
    this.$store.dispatch('authorized/WATCHING_WEBHOOKS_FROM_PLAID', false);
}

function data(){
    return {
        isDisabled: false,
        loadingSpinner: false,

        users: [],
        totalUserCount: 0,
        debouncedHandlerGetAllUsers: null,

        userQuery: {
            searchString: '',
            numPage: 1,
            perPage: 50,
            sortBy: '',
            sortOrder: 'asc'
        },

        errorMessages: [],
        userTableColumns: [
            {
                key: 'id',
                label: 'User ID',
                sortable: true
            },
            {
                key: 'name',
                label: 'Name',
                sortable: false,
                tdClass: 'text-nowrap'
            },
            {
                key: 'email',
                label: 'Email',
                sortable: true
            },
            {
                key: 'linked_bank_accounts',
                label: 'Linked Bank Accounts'
            },
        ],
        bankAccountsTableColumns: [
            {
                key: 'id',
                label: 'Bank Account ID',
                sortable: true
            },
            {
                key: 'name',
                label: 'Name',
                sortable: true,
            },
            {
                key: 'slug',
                label: 'Slug',
                sortable: true
            },
            {
                key: 'purpose',
                label: 'purpose',
                sortable: true
            },
            {
                key: 'balance_current',
                label: 'Balance',
                sortable: true,
                class: 'text-right',
                formatter: (value) => `${value || 0}$`
            },
            {
                key: 'actions',
                label: ''
            },
        ],
        webhookTypes: [
            'TRANSACTIONS',
            'LIABILITIES',
        ],
        webhookCodes: [
            'DEFAULT_UPDATE',
            'RECURRING_TRANSACTIONS_UPDATE',
            // 'SYNC_UPDATES_AVAILABLE' # only needed for transactionc/sync product
        ],

        isLoadingUserBankAccount: false,

        webhookModal: {
            user: null,
            bankAccount: null,
            category: null,
            amount: null,
            date: null,
            amountValidationError: '',
            isMakingTestWebhookRequest: false,
            apiErrors: []
        },

        isLocalWebhookTestingDisabled: false,
    };
}

function getComputed(){
    return {
        displayedUsers,
        isTestingWebhookDisabled,
        availableWebhookTypes,
        availableWebhookCodes,
    };

    function displayedUsers(){
        const vm = this;
        return vm.users.filter(bySearchString);

        function bySearchString(user){
            if(!vm.searchString){
                return true;
            }
            const name = (user.name || '');
            const email = (user.email || '');
            const plan = ((user.account && user.account.subscription_plan) || '');
            const origin = ((user.account && user.account.subscription_origin) || '');
            const status = ((user.account && user.account.status) || '');
            const braintreeId = ((user.account && user.account.braintree_customer_id) || '');
            const userInfoToSearch = name.concat(email, plan, origin, status, braintreeId).toLowerCase();
            return userInfoToSearch.includes(vm.searchString.toLowerCase());
        }
    }

    function isTestingWebhookDisabled(){
        const vm = this;
        return !vm.webhookModal.code;
    }

    function availableWebhookTypes(){
        const vm = this;
        const isCreditCardSelected = vm.webhookModal.bankAccount && vm.webhookModal.bankAccount.type === 'credit';
        if(!isCreditCardSelected){
            return vm.webhookTypes.filter(type => type !== 'LIABILITIES');
        }

        return vm.webhookTypes;
    }

    function availableWebhookCodes(){
        const vm = this;
        const selectedLiabilityType = vm.webhookModal.type === 'LIABILITIES';
        if(selectedLiabilityType){
            return ['DEFAULT_UPDATE'];
        }

        return vm.webhookCodes;
    }
}

function getMethods(){
    return {
        getAllUsers,
        handleChangePagination,
        handleChangeSort,
        loadUserBankAccounts,

        makeTestWebhookRequest,
        openWebhookModal,
        initializeWebhookModal,
        preventCloseWhileLoading,
        resetLogin,
    };

    function getAllUsers(){
        const vm = this;
        vm.loadingSpinner = true;
        Vue.appApi().authorized().admin().getAllUsers(vm.userQuery).then(setUsers).catch(displayError).finally(resetLoadingState);
        function setUsers(response){
            const { total, users } = response.data;
            vm.totalUserCount = total;
            vm.users = users.map(setDisplayProperties);
            function setDisplayProperties(user){
                user.account = user.accounts[0];
                user.account.isReactivatingAccount = false;
                user.account.isConfirmingReactivation = false;
                return user;
            }
        }
        function displayError(response){
            if(response.appMessage){
                vm.errorMessages.push(response.appMessage);
            }

            vm.totalUserCount = 0;
        }
        function resetLoadingState(){
            vm.loadingSpinner = false;
        }
    }

    function handleChangePagination(event){
        const vm = this;
        Object.assign(vm.userQuery, event);
        vm.getAllUsers();
    }

    function handleChangeSort(event){
        const vm = this;
        Object.assign(vm.userQuery, {
            sortBy: event.sortBy,
            sortOrder: event.sortDesc ? 'desc' : 'asc'
        });
        vm.getAllUsers();
    }

    function loadUserBankAccounts(user){
        if(!user){
            return;
        }

        const vm = this;
        user.isLoadingBankAccounts = true;
        const ownerAccountId = user.accounts.length && user.accounts[0].id;
        Vue.appApi().authorized().bankAccount().loadWithInstitutionAccountsOf(ownerAccountId)
            .then(handleSuccess)
            .catch(displayError)
            .finally(resetLoadingState);

        function handleSuccess(response){
            user.bankAccounts = response.data.filter(filterOnlyWithInstituion).map(formatName);

            function filterOnlyWithInstituion(bankAccount){
                return !!bankAccount.institution_account;
            }

            function formatName(bankAccount){
                if(bankAccount.slug === 'primary_checking'){
                    bankAccount.name = 'Primary Checking';
                } else if(bankAccount.slug === 'primary_savings'){
                    bankAccount.name = 'Primary Savings';
                }

                if(!bankAccount.name){
                    bankAccount.name = `${bankAccount.institution_account.institution.name} ${bankAccount.institution_account.name} x-${bankAccount.institution_account.mask}`;
                }

                bankAccount.isResettingLogin = false;

                return bankAccount;
            }
        }

        function displayError(response){
            if(response.appMessage){
                vm.errorMessages.push(response.appMessage);
            }
        }

        function resetLoadingState(){
            user.isLoadingBankAccounts = false;
            vm.$forceUpdate();
        }
    }

    function makeTestWebhookRequest(){
        const vm = this;
        vm.webhookModal.isMakingTestWebhookRequest = true;
        vm.webhookModal.apiErrors = [];
        const payload = {
            instituion_account_id: vm.webhookModal.bankAccount.institution_account.id,
            type: vm.webhookModal.type,
            code: vm.webhookModal.code || '',
        };

        vm.$store.dispatch('authorized/WATCHING_WEBHOOKS_FROM_PLAID', true);
        return Vue.appApi().authorized().admin().testPlaid().fireWebhook(payload)
            .then(handleSuccess)
            .catch(displayError);
        function handleSuccess(){
            vm.$bvToast.toast('Webhook fired successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
            });
            closeModal();
        }
        function closeModal(){
            vm.webhookModal.isMakingTestWebhookRequest = false;
            vm.$refs.makeWebhookModal.hide();
        }
        function displayError(error){
            vm.webhookModal.isMakingTestWebhookRequest = false;
            if(error.appMessage){
                vm.webhookModal.apiErrors.push(error.appMessage);
            }
        }
    }

    function resetLogin(bankAccount){
        const vm = this;
        const payload = {
            instituion_account_id: bankAccount.institution_account.id
        };

        bankAccount.isResettingLogin = true;
        vm.$store.dispatch('authorized/WATCHING_WEBHOOKS_FROM_PLAID', false);

        return Vue.appApi().authorized().admin().testPlaid().resetLogin(payload)
            .then(handleSuccess)
            .catch(displayError);
        function handleSuccess(){
            vm.$bvToast.toast('Login reset successfully.', {
                title: 'Success',
                variant: 'success',
                solid: true,
            });
            bankAccount.isResettingLogin = false;
        }
        function displayError(error){
            bankAccount.isResettingLogin = false;
            if(error.appMessage){
                vm.$bvToast.toast(error.appMessage, {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        }
    }

    function openWebhookModal(user, bankAccount){
        const vm = this;
        vm.webhookModal.user = user;
        vm.webhookModal.bankAccount = bankAccount;

        vm.$refs.makeWebhookModal.show();
    }

    function initializeWebhookModal(){
        const vm = this;
        vm.webhookModal.apiErrors = [];
        vm.webhookModal.amountValidationError = '';
        vm.webhookModal.isMakingTestWebhookRequest = false;
        vm.webhookModal.amount = 0;
    }

    function preventCloseWhileLoading(event){
        const vm = this;
        if(vm.webhookModal.isMakingTestWebhookRequest){
            event.preventDefault();
        }
    }
}
