import { render, staticRenderFns } from "./forgot-password.vue?vue&type=template&id=505fa940&scoped=true"
import script from "./forgot-password.controller.js?vue&type=script&lang=js&external"
export * from "./forgot-password.controller.js?vue&type=script&lang=js&external"
import style0 from "./_forgot-password.scss?vue&type=style&index=0&id=505fa940&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505fa940",
  null
  
)

export default component.exports