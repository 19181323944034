// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo[data-v-16bcc449] {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./_manage-subscription.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AACJ","sourcesContent":[".logo {\n    width: 200px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
