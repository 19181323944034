export default {
    data,
    mounted,
    methods: getMethods()
};

function data(){
    return {
        apiErrors: [],
        isVerifyingEmail: false,
        user: {},
        codeInvalid: false,
        linkExpired: false,
    };
}

function mounted(){
    const vm = this;
    const emailToVerify = vm.$route.query.email;
    const verificationToken = vm.$route.query.token;

    if(emailToVerify && verificationToken){
        vm.verifyEmailAddress({ email: emailToVerify, token: verificationToken });
    }
}

function getMethods(){
    return {
        verifyEmailAddress,
    };

    function verifyEmailAddress(payload){
        const vm = this;
        vm.isVerifyingEmail = true;
        Vue.appApi().guest().user().verifyEmailAddress(payload).then(setUser).catch(handleError).finally(resetSpinner);

        function setUser(response){
            vm.user = response.data;
            vm.$store.commit('user/SET_USER', vm.user);
        }

        function handleError({ appMessage, data }){
            vm.linkExpired = data?.message === 'LINK_EXPIRED';
            vm.codeInvalid = data?.message === 'CODE_INVALID';

            if(!vm.codeInvalid && !vm.linkExpired && appMessage){
                vm.apiErrors = [appMessage];
            }
        }

        function resetSpinner(){
            vm.isVerifyingEmail = false;
        }
    }
}
