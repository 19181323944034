import { render, staticRenderFns } from "./business-priorities.vue?vue&type=template&id=ef4ba26a&scoped=true"
import script from "./business-priorities.controller.js?vue&type=script&lang=js&external"
export * from "./business-priorities.controller.js?vue&type=script&lang=js&external"
import style0 from "./_business-priorities.scss?vue&type=style&index=0&id=ef4ba26a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef4ba26a",
  null
  
)

export default component.exports