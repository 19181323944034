import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin';

export default {
    props: {
        parentBankAccount: Object
    },
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    mixins: [sortBankAccounts, formatAsDecimal],
    data: data,
    computed: getComputed(),
    methods: getMethods()
};

function data(){
    return {
        isSaving: false,
        bankAccount: null,
        errorMessages: [],
        isCloseConfirmed: false,
        isMasterView: false,
        merchants: [],
        rules: [],
        isLoadingRules: true,
        isInEditMode: false,
        isDeletingRule: false,
        ruleToDelete: null,
        currentRule: {
            amount_from: null,
            amount_to: null,
            custom_description: '',
        },
        selectedAccount: { label: 'Select a bucket', value: null },
        selectedMerchant: '',
        errorRuleDuplicated: false,
    };
}

function getComputed(){
    return {
        isSaveButtonEnabled,
        bankAccounts,
        accountSelectOptions,
    };

    function isSaveButtonEnabled(){
        const vm = this;
        const accountSelectedValid = vm.selectedAccount.value && vm.selectedMerchant;
        const targetValid = vm.selectedMerchant && (vm.selectedMerchant !== 'Custom' || vm.currentRule.custom_description);
        const amountRangeValid =
            (!vm.currentRule.amount_from || +vm.currentRule.amount_from > 0) &&
            (!vm.currentRule.amount_to || +vm.currentRule.amount_to > 0) &&
            (!vm.currentRule.amount_from || !vm.currentRule.amount_to || +vm.currentRule.amount_from <= +vm.currentRule.amount_to);
        return accountSelectedValid && targetValid && amountRangeValid;
    }

    function bankAccounts(){
        const vm = this;
        const hiddenPurposes = [
            'none',
            'primary_checking',
            'primary_savings',
            'unassigned',
            'credit'
        ];

        return Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts)
            .filter(filterByParentAndPurpose);

        function filterByParentAndPurpose(bankAccount){
            const { purpose, parent_bank_account_id: parentBankAccountId } = bankAccount;
            const checkParent = !vm.parentBankAccount || parentBankAccountId === vm.parentBankAccount.id;
            const isFiltered = !hiddenPurposes.includes(purpose) && checkParent;

            return isFiltered;
        }
    }

    function accountSelectOptions(){
        const vm = this;
        const nullOptions = [{ label: 'Select a bucket', value: null }];
        const options = vm.bankAccounts.sort(vm.byModifiedStoreOrder).map((bankAccount) => {
            return {
                label: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name,
                color: bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.color : bankAccount.color,
                icon: bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.icon : bankAccount.icon,
                value: bankAccount
            };
        });
        return nullOptions.concat(options);
    }
}

function getMethods(){
    return {
        loadMerchants,
        loadRules,
        openModal,
        toggleMasterView,
        initEditValues,
        displayApiErrors,
        addNewRule,
        editRule,
        deleteRule,
        save,
        getBankAccountColor,
        getBankAccountName,
        getBankAccountIcon,
        backToList,
    };

    function loadMerchants(){
        const vm = this;
        Vue.appApi().authorized().bankAccount(vm.bankAccount.id).transactionAutomation().getMerchants().then(setMerchantsData);

        function setMerchantsData({ data }){
            const customOptions = ['Custom'];
            vm.merchants = customOptions.concat(data.sort((a, b) => a.localeCompare(b)));
        }
    }

    function loadRules(){
        const vm = this;
        const getAll = vm.isMasterView;
        vm.isLoadingRules = true;
        Vue.appApi().authorized().bankAccount(vm.bankAccount.id).transactionAutomation().getRules(getAll)
            .then(setRulesData)
            .catch(vm.displayApiErrors)
            .finally(resetLoadingState);

        function setRulesData({ data }){
            vm.rules = data.map(addDisplayLabel).sort(byBankAccountAndMerchant);
            vm.rules.reduce((foundbankAccountIds, rule) => {
                if(foundbankAccountIds.includes(rule.bank_account_id)){
                    return foundbankAccountIds;
                }

                foundbankAccountIds.push(rule.bank_account_id);
                rule.showBankAccountInfo = true;
                return foundbankAccountIds;
            }, []);
        }

        function byBankAccountAndMerchant(a, b){
            const hasSameParentBankAccount = a.bank_account.parent_bank_account_id === b.bank_account.parent_bank_account_id;
            if(!hasSameParentBankAccount){
                const allBankAccounts = vm.$store.state.authorized.bankAccounts.bankAccounts;
                const bParentBankAccountIndex = allBankAccounts.findIndex(({ id }) => id === b.bank_account.parent_bank_account_id);
                const bParentBankAccountSlug = allBankAccounts[bParentBankAccountIndex]?.slug;

                return bParentBankAccountSlug === 'primary_checking' ? 1 : -1;
            }

            const bankAccountCompare = a.bank_account_id === b.bank_account_id ? 0 : vm.byModifiedStoreOrder(a.bank_account, b.bank_account);
            return bankAccountCompare !== 0 ? bankAccountCompare : a.merchantLabel.localeCompare(b.merchantLabel);
        }

        function addDisplayLabel(rule){
            if(rule.amount_from && rule.amount_to){
                rule.amountRange = `Between ${vm.formatAsDecimal(rule.amount_from, true)} and ${vm.formatAsDecimal(rule.amount_to, true)}`;
            } else if(rule.amount_from){
                rule.amountRange = `Greater than ${vm.formatAsDecimal(rule.amount_from, true)}`;
            } else if(rule.amount_to){
                rule.amountRange = `Less than ${vm.formatAsDecimal(rule.amount_to, true)}`;
            } else {
                rule.amountRange = '';
            }

            if(rule.merchant === 'Custom'){
                rule.merchantLabel = rule.custom_description;
            } else {
                rule.merchantLabel = rule.merchant;
            }

            return rule;
        }

        function resetLoadingState(){
            vm.isLoadingRules = false;
        }
    }

    function openModal(bankAccount){
        const vm = this;

        vm.bankAccount = bankAccount;
        vm.errorMessages = [];
        vm.isCloseConfirmed = false;
        vm.isMasterView = false;
        vm.isInEditMode = false;
        vm.loadMerchants();
        vm.loadRules();

        vm.$refs.transactionAutomationModal.show();
    }

    function save(){
        const vm = this;
        vm.isSaving = true;
        vm.errorRuleDuplicated = false;

        const payload = {
            ...vm.currentRule,
            bank_account_id: vm.selectedAccount.value.id,
            merchant: vm.selectedMerchant,
            custom_description: vm.selectedMerchant === 'Custom' ? vm.currentRule.custom_description : '',
        };

        Vue.appApi().authorized().bankAccount(payload.bank_account_id).transactionAutomation().storeRule(payload)
            .then(onSaveSuccess)
            .catch(vm.displayApiErrors)
            .finally(resetSavingState);

        function onSaveSuccess(){
            vm.isInEditMode = false;
            vm.initEditValues();
            vm.loadRules();
        }

        function resetSavingState(){
            vm.isSaving = false;
        }
    }

    function deleteRule(rule = null, confirmed = true, event){
        if(event){
            event.stopPropagation();
        }

        const vm = this;

        if(!confirmed){
            vm.ruleToDelete = rule;
            vm.$refs.confirmDeleteModal.show();
            return;
        }

        if(!vm.ruleToDelete || !vm.ruleToDelete.id){
            return;
        }

        vm.isDeletingRule = true;
        vm.$refs.confirmDeleteModal.hide();

        Vue.appApi().authorized().bankAccount(vm.ruleToDelete.bank_account.id)
            .transactionAutomation().removeRule(vm.ruleToDelete.id)
            .then(onDeleteSuccess)
            .catch(vm.displayApiErrors)
            .finally(resetDeletingState);

        function onDeleteSuccess(){
            const index = vm.rules.findIndex(({ id }) => id === vm.ruleToDelete.id);
            vm.rules.splice(index, 1);
            vm.isInEditMode = false;
        }

        function resetDeletingState(){
            vm.isDeletingRule = false;
        }
    }

    function displayApiErrors(err){
        const vm = this;
        if(err.data?.message === 'RULE_DUPLICATE'){
            vm.errorRuleDuplicated = true;
        } else if(err.appMessage){
            vm.errorMessages.push(err.appMessage);
        }
    }

    function addNewRule(){
        const vm = this;
        vm.isMasterView = false;
        vm.initEditValues();
        vm.isInEditMode = true;
    }

    function editRule(rule){
        const vm = this;
        vm.currentRule = rule;
        vm.isMasterView = false;
        vm.initEditValues(rule);
        vm.isInEditMode = true;
    }

    function toggleMasterView(){
        this.isMasterView = !this.isMasterView;
        this.loadRules();
    }

    function initEditValues(rule){
        const vm = this;
        if(rule){
            vm.currentRule = {
                id: rule.id,
                amount_from: rule.amount_from,
                amount_to: rule.amount_to,
                bank_account: rule.bank_account,
                custom_description: rule.custom_description,
                merchantLabel: rule.merchantLabel,
            };

            vm.selectedAccount = vm.accountSelectOptions.filter(({ value }) => value && value.id === rule.bank_account_id)[0];
            vm.selectedMerchant = rule.merchant;
        } else {
            vm.currentRule = {
                amount_from: '',
                amount_to: '',
                custom_description: '',
            };

            if(vm.isMasterView){
                vm.selectedAccount = { label: 'Select a bucket', value: null };
            } else {
                vm.selectedAccount = vm.accountSelectOptions.filter(({ value }) => value && value.id === vm.bankAccount.id)[0];
            }

            vm.selectedMerchant = '';
        }
    }

    function getBankAccountName(bankAccount){
        return bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name;
    }

    function getBankAccountIcon(bankAccount){
        return bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.icon : bankAccount.icon;
    }

    function getBankAccountColor(bankAccount){
        return bankAccount.slug === 'cc_payoff' ? bankAccount.credit_card_account.color : bankAccount.color;
    }

    function backToList(){
        this.isInEditMode = false;
        this.isMasterView = false;
    }
}
