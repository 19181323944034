export default {
    components: {},
    props: {},
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        bankAccount: {}
    };
}

function getComputed(){}

function created(){}

function getMethods(){
    return {
        openModal,
        confirmRemove,
    };

    function openModal(bankAccount){
        const vm = this;
        if(bankAccount){
            vm.bankAccount = bankAccount;
            vm.$refs.confirmRemoveModal.show();
        }
    }

    function confirmRemove(){
        const vm = this;
        vm.$emit('remove-confirmed', vm.bankAccount);
        vm.$refs.confirmRemoveModal.hide();
    }
}
