import { isEmpty } from 'lodash';

export default {
    data(){
        return {
            apiErrors: [],
            validationErrors: {},
            credentials: {
                email: null,
                password: null,
                remember_me: false,
                enableTouchId: false
            },
            loggingIn: false,
            clientPlatform: window.appEnv.clientPlatform || 'web',
            isInitializing: false,
            biometricAvailability: false
        };
    },
    created(){
        this.isInitializing = true;

        const initializationPromises = [
            Vue.clientStorage.getItem('email').then((storedEmail) => {
                this.credentials.email = storedEmail;
                this.credentials.remember_me = !!storedEmail;
            }),
            Vue.iosKeychainPlugin.getBiometricAvailability().then((availability) => {
                this.biometricAvailability = availability;
                this.credentials.enableTouchId = !!availability;
            })
        ];
        Promise.all(initializationPromises).finally(() => { this.isInitializing = false; });
    },
    computed: {
        disableSubmitButton(){
            return isEmpty(this.credentials.email) || isEmpty(this.credentials.password);
        }
    },
    methods: {
        login(){
            if(this.disableSubmitButton){
                return;
            }

            this.apiErrors = [];
            this.validationErrors = {};
            this.loggingIn = true;

            this.$store.dispatch('user/login/LOGIN', this.credentials)
                .then(() => {
                    const currentAccount = this.$store.getters.user.current_account;
                    const shouldInitiateTrial = currentAccount.subscription_plan === 'basic' &&
                        !currentAccount.is_trial_used &&
                        !currentAccount.status === 'demo';
                    if(shouldInitiateTrial){
                        return this.$store.dispatch('user/START_NEW_TRIAL').then(() => {
                            this.$router.redirectAfterLogin({ name: 'dashboard' });
                        });
                    } else {
                        this.$router.redirectAfterLogin();
                    }
                })
                .catch((response) => {
                    this.loggingIn = false;

                    if(response.data.validation_errors){
                        this.validationErrors = response.data.validation_errors;
                    }

                    if(response.data.message === 'CSRF token mismatch.'){
                        this.apiErrors = ['Please refresh the page and try again'];
                    } else if(response.data.message === 'Account is locked.'){
                        this.apiErrors = ['Your account has been locked. Please ' +
                        '<a href="mailto:support@ottsave.com">contact support</a>'];
                    } else {
                        this.apiErrors.push('Username or password is incorrect.');
                    }
                });
        },
        loginWithBiometrics(){
            if(!this.loggingIn){
                this.apiErrors = [];
                this.validationErrors = {};
                this.loggingIn = true;

                Vue.iosKeychainPlugin.retrieveStoredCredentials()
                    .then((credentials) => {
                        this.$store.dispatch('user/login/LOGIN', credentials)
                            .then(() => {
                                const currentAccount = this.$store.getters.user.current_account;
                                const shouldInitiateTrial = currentAccount.subscription_plan === 'basic' &&
                                    !currentAccount.is_trial_used &&
                                    !currentAccount.status === 'demo';
                                if(shouldInitiateTrial){
                                    return this.$store.dispatch('user/START_NEW_TRIAL').then(() => {
                                        this.$router.redirectAfterLogin({ name: 'dashboard' });
                                    });
                                } else {
                                    this.$router.redirectAfterLogin();
                                }
                            })
                            .catch(() => { this.apiErrors = [`Login to enable ${this.biometricAvailability}`]; })
                            .finally(() => { this.loggingIn = false; });
                    });
            }
        }
    },
};
