import SubscriberComponent from './subscriber.vue';
import { AccountsRoutes } from './accounts/accounts.routes';
import { SettingsRoutes } from './settings/settings.routes';
import DashboardComponent from './dashboard-v2/dashboard.vue';
import OrganizeComponent from './organize/organize.vue';
import SavingsComponent from './savings/savings.vue';
import ReportsPage from './reports/reports.vue';
import AdminRoutes from './admin/admin.routes';
import OnboardingRoutes from './onboarding/onboarding.routes';
import ProfitAndLoss from './profit-loss/profit-loss.vue';

export default {
    path: '',
    component: SubscriberComponent,
    meta: {
        requiresSubscription: true,
        requiresVerifiedEmail: true,
    },
    children: [
        {
            path: '/',
            name: 'dashboard',
            component: DashboardComponent,
            meta: {
                title: 'Dashboard'
            }
        },
        {
            path: '/organize',
            name: 'organize',
            component: OrganizeComponent,
            meta: {
                title: 'Organize'
            }
        },
        {
            path: '/savings/:bank_account_id?',
            name: 'savings',
            component: SavingsComponent,
            meta: {
                title: 'Savings'
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: ReportsPage,
            meta: {
                title: 'Reports'
            }
        },
        {
            path: '/videos',
            name: 'videos',
            beforeEnter(){
                window.open('https://www.trueview.money/tutorial-videos', '_blank');
            }
        },
        {
            path: '/profit-loss',
            name: 'profit and loss',
            component: ProfitAndLoss,
            meta: {
                title: 'Profit and Loss',
            },
        },
        AdminRoutes,
        OnboardingRoutes,
        ...AccountsRoutes,
        ...SettingsRoutes,
    ]
};
