import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

export default function(accessToken = null){
    const pusherAppKey = window.appEnv.pusherAppKey || process.env.PUSHER_APP_KEY;
    const isPusherConfigDefault = pusherAppKey === 'insert_pusher_app_key';
    if(!pusherAppKey || isPusherConfigDefault){
        console.error('ERROR: Real-time functionality is disabled until you update your environment variables with Pusher credentials.');
        return { notConfigured: true };
    }

    const config = {
        broadcaster: 'pusher',
        key: window.appEnv.pusherAppKey || process.env.PUSHER_APP_KEY,
        cluster: window.appEnv.pusherAppCluster || process.env.PUSHER_APP_CLUSTER,
        forceTLS: ((window.appEnv.pusherScheme || process.env.PUSHER_SCHEME) || 'https') === 'https',
        authorizer: (channel, options) => {
            return {
                authorize: (socketId, callback) => {
                    Vue.appApi().broadcastingAuth({
                        socket_id: socketId,
                        channel_name: channel.name
                    }).then(response => {
                        callback(null, response.data);
                    })
                        .catch((error) => {
                            console.error(error);
                            callback(error);
                        });
                }
            };
        },
    };

    return new Echo(config);
}
