// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verifyEmailComponent .logo[data-v-70358af4] {
  max-width: 200px;
}
.verifyEmailComponent .container[data-v-70358af4] {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.verifyEmailComponent .infoSection[data-v-70358af4] {
  background: white;
  padding: 39px 25px;
  border-radius: 10px;
  text-align: center;
}
.verifyEmailComponent .infoSection i.fa-check-circle[data-v-70358af4] {
  color: #36D19F !important;
}
.verifyEmailComponent .infoSection i.fa-times-circle[data-v-70358af4] {
  color: #DC2626 !important;
}
.verifyEmailComponent .infoSection p[data-v-70358af4] {
  max-width: 280px;
  text-align: center;
}
@media only screen and (max-width : 768px) {
.verifyEmailComponent .logo[data-v-70358af4] {
    display: none;
}
.verifyEmailComponent .container[data-v-70358af4] {
    background: white;
}
}`, "",{"version":3,"sources":["webpack://./_verify-email.scss"],"names":[],"mappings":"AAGI;EACI,gBAAA;AAFR;AAKI;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAHR;AAMI;EACI,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAJR;AAMQ;EACI,yBAAA;AAJZ;AAOQ;EACI,yBAAA;AALZ;AAQQ;EACI,gBAAA;EACA,kBAAA;AANZ;AAUI;AACI;IACI,aAAA;AARV;AAWM;IACI,iBAAA;AATV;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.verifyEmailComponent {\n    .logo {\n        max-width: 200px;\n    }\n\n    .container {\n        height: 100vh;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n    }\n\n    .infoSection {\n        background: white;\n        padding: 39px 25px;\n        border-radius: 10px;\n        text-align: center;\n\n        i.fa-check-circle {\n            color: #36D19F !important;\n        }\n\n        i.fa-times-circle {\n            color: #DC2626 !important;\n        }\n        \n        p {\n            max-width: 280px;\n            text-align: center;\n        }\n    }\n\n    @media #{$breakpointTabletMax} {\n        .logo {\n            display: none;\n        }\n\n        .container {\n            background: white;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
