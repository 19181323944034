// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emailPreviewComponent iframe[data-v-eed935d0] {
  border: 2px solid #D6D5D5;
}`, "",{"version":3,"sources":["webpack://./_preview-email.scss"],"names":[],"mappings":"AAGI;EACI,yBAAA;AAFR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.emailPreviewComponent {\n    iframe {\n        border: 2px solid $gray-300;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
