import { isEmpty } from 'lodash';

export default {
    data(){
        return {
            credentials: {
                email: null,
                password: null,
                password_confirmation: null,
            },
            apiErrors: [],
            successMessages: [],
            validationErrors: {},
            requestingPasswordReset: false,
        };
    },
    computed: {
        disableSubmitButton(){
            return !Object.values(this.credentials).every(v => !isEmpty(v));
        }
    },
    methods: {
        requestPasswordReset(credentials){
            this.requestingPasswordReset = true;
            this.apiErrors = [];
            this.validationErrors = {};

            if(credentials.password !== credentials.password_confirmation){
                this.apiErrors = ['Passwords must match'];
                this.requestingPasswordReset = false;
                return;
            }

            this.credentials.token = Object.keys(this.$route.query)[0];

            this.$store.dispatch('user/reset_password/RESET_PASSWORD', credentials)
                .then(() => {
                    this.requestingPasswordReset = false;
                    this.credentials.email = null;
                    this.credentials.password = null;
                    this.credentials.password_confirmation = null;
                    this.successMessages.push('Your password has been reset.');
                })
                .catch((response) => {
                    this.apiErrors.push(response.appMessage);
                    this.requestingPasswordReset = false;

                    if(response.validation_errors){
                        this.validationErrors = response.validation_errors;
                    }
                });
        }
    },
};
