export default {
    methods: {
        byModifiedStoreOrder
    }
};

function byModifiedStoreOrder(a, b){
    if([a.slug, b.slug].includes('income_deposit')){
        return a.slug === 'income_deposit' ? -1 : 1;
    }
    if([a.slug, b.slug].includes('cc_payoff')){
        return a.slug === 'cc_payoff' ? 1 : -1;
    }
    return a.sub_account_order - b.sub_account_order;
}
