import { render, staticRenderFns } from "./transaction-automation-modal.vue?vue&type=template&id=5e22cf01&scoped=true"
import script from "./transaction-automation-modal.controller.js?vue&type=script&lang=js&external"
export * from "./transaction-automation-modal.controller.js?vue&type=script&lang=js&external"
import style0 from "./_transaction-automation-modal.scss?vue&type=style&index=0&id=5e22cf01&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e22cf01",
  null
  
)

export default component.exports