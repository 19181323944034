// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingSpinnerComponent .fa-circle-notch[data-v-b4250520] {
  font-size: 48px;
}
.loadingSpinnerComponent .size-auto .fa-circle-notch[data-v-b4250520] {
  font-size: 1em;
}
.loadingSpinnerComponent .size-md .fa-circle-notch[data-v-b4250520] {
  font-size: 36px;
}
.loadingSpinnerComponent .overlay.loading-spinner[data-v-b4250520] {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #eaeaea;
  padding-top: 20%;
  background-color: #f6f8fa;
  opacity: 0.5;
}
.loadingSpinnerComponent .small.loading-spinner i[data-v-b4250520] {
  font-size: 18px;
}
.loadingSpinnerComponent .overlay-fixed[data-v-b4250520] {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.5);
  padding-top: calc(50vh - 24px);
  padding-left: calc(50vw - 24px);
}`, "",{"version":3,"sources":["webpack://./_loading-spinner.scss"],"names":[],"mappings":"AAGI;EACI,eAAA;AAFR;AAKQ;EACI,cAAA;AAHZ;AAOQ;EACI,eAAA;AALZ;AAQI;EACI,kBAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;AANR;AAQI;EACI,eAAA;AANR;AASI;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,0CAAA;EACA,8BAAA;EACA,+BAAA;AAPR","sourcesContent":[".loadingSpinnerComponent {\n    @import \"~vue_root/assets/scss/_variables.scss\";\n\n    .fa-circle-notch {\n        font-size: 48px;\n    }\n    .size-auto {\n        .fa-circle-notch {\n            font-size: 1em;\n        }\n    }\n    .size-md {\n        .fa-circle-notch {\n            font-size: 36px;\n        }\n    }\n    .overlay.loading-spinner {\n        text-align: center;\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        left: 0;\n        right: 0;\n        z-index: 100;\n        background: #eaeaea;\n        padding-top: 20%;\n        background-color: #f6f8fa;\n        opacity: 0.5;\n    }\n    .small.loading-spinner i {\n        font-size: 18px;\n    }\n\n    .overlay-fixed {\n        position: fixed;\n        top: 0;\n        left: 0;\n        height: 100vh;\n        width: 100vw;\n        z-index: 2000;\n        background-color: rgba(255,255,255,0.5);\n        padding-top: calc(50vh - 24px);\n        padding-left: calc(50vw - 24px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
