// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pastTransactionsComponent[data-v-03a06a8e] .datepicker {
  max-width: 100%;
}
.pastTransactionsComponent .fadeHeight-enter-active[data-v-03a06a8e],
.pastTransactionsComponent .fadeHeight-leave-active[data-v-03a06a8e] {
  transition: all 0.5s ease-in-out;
  max-height: 2300px;
  overflow: hidden;
}
.pastTransactionsComponent .fadeHeight-enter[data-v-03a06a8e],
.pastTransactionsComponent .fadeHeight-leave-to[data-v-03a06a8e] {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./_past-transactions.scss"],"names":[],"mappings":"AAGQ;EACI,eAAA;AAFZ;AAMI;;EAEI,gCAAA;EACA,kBAAA;EACA,gBAAA;AAJR;AAMI;;EAGI,UAAA;EACA,eAAA;EACA,gBAAA;AALR","sourcesContent":[".pastTransactionsComponent {\n    @import \"~vue_root/assets/scss/_variables.scss\";\n    ::v-deep {\n        .datepicker {\n            max-width: 100%;\n        }\n    }\n\n    .fadeHeight-enter-active,\n    .fadeHeight-leave-active {\n        transition: all 0.5s ease-in-out;\n        max-height: 2300px;\n        overflow: hidden;\n    }\n    .fadeHeight-enter,\n    .fadeHeight-leave-to\n    {\n        opacity: 0;\n        max-height: 0px;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
