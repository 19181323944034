import { isEmpty } from 'lodash';

export default {
    data(){
        return {
            credentials: {
                email: '',
            },
            apiErrors: [],
            validationErrors: {},
            requestingResetPasswordEmail: false,
            successMessages: [],
        };
    },
    computed: {
        disableSubmitButton(){
            return !Object.values(this.credentials).every(v => !isEmpty(v));
        }
    },
    methods: {
        requestResetPasswordEmail(credentials){
            this.requestingResetPasswordEmail = true;
            this.apiErrors = [];
            this.validationErrors = {};

            this.$store.dispatch('user/forgot_password/FORGOT_PASSWORD', credentials)
                .then(() => {
                    this.requestingResetPasswordEmail = false;
                    this.credentials.email = null;
                    this.successMessages = ['Please check your inbox and follow the link provided.'];
                })
                .catch((response) => {
                    this.apiErrors.push(response.appMessage || response.data.message);
                    this.requestingResetPasswordEmail = false;

                    if(response.data.validation_errors){
                        this.validationErrors = response.data.validation_errors;
                    }
                });
        }
    },
};
