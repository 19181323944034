import eventBus from 'vue_root/plugins/eventBus.js';
import echo from './echo.js';

export default {
    data,
    created,
    mounted,
    watch: getWatchers(),
    beforeDestroy,
    methods: methods()
};

function data(){
    return {
        echo: null,
        currentAccountId: null,
    };
}

function getWatchers(){
    return {
        '$store.state.guest.user.user'(){
            this.leaveUserChannel(this.currentAccountId);

            this.currentAccountId = this.$store.state.guest.user.user?.current_account.id;
            this.subscribeToUserChannel(this.currentAccountId);
        },
    };
}

function created(){
    this.echo = echo();
}

function mounted(){
    if(this.echo.notConfigured){ return false; }

    eventBus.$on('plaid-fetch-finished', this.finished);

    this.currentAccountId = this.$store.state.guest.user.user?.current_account.id;
    this.subscribeToUserChannel(this.currentAccountId);
}

function beforeDestroy(){
    if(this.echo.notConfigured){ return false; }

    eventBus.$off('plaid-fetch-finished', this.finished);

    this.leaveUserChannel(this.currentAccountId);
}

function methods(){
    return {
        async syncTransactionsFinished(payload){
            await this.dispatchFetchBankAccounts();
            await this.$store.dispatch('authorized/transactions/FETCH_UNASSIGNED_TRANSACTIONS');

            const triggeredByWebhook = payload?.trigger === 'webhook';
            if(triggeredByWebhook && !this.$store.state.authorized.isWatchingWebhooksFromPlaid){
                return;
            }

            if(this.$store.state.authorized.isFetchingDataFromPlaid){
                await this.$store.dispatch('authorized/FETCHING_TRANSACTIONS_FROM_PLAID', false);
                await this.$store.dispatch('authorized/FETCHING_ACCOUNT_BALANCES_FROM_PLAID', false);
                await this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID', false);
            }
        },

        async syncCreditCardLiabilityFinished(payload){
            await this.dispatchFetchBankAccounts();

            const triggeredByWebhook = payload?.trigger === 'webhook';
            if(triggeredByWebhook && !this.$store.state.authorized.isWatchingWebhooksFromPlaid){
                return;
            }

            if(this.$store.state.authorized.isFetchingDataFromPlaid){
                await this.$store.dispatch('authorized/FETCHING_TRANSACTIONS_FROM_PLAID', false);
                await this.$store.dispatch('authorized/FETCHING_ACCOUNT_BALANCES_FROM_PLAID', false);
                await this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID', false);
            }
        },

        async webhookReceived(payload){
            const isWatchingPlaidWebhooks = this.$store.state.authorized.isWatchingWebhooksFromPlaid;
            if(isWatchingPlaidWebhooks){
                this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID');
            }
        },

        async itemEnterInErrorMode(){
            await this.dispatchFetchBankAccounts();
        },

        async userEmailVerified(){
            this.$store.commit('user/SET_NOTIFIED_EMAIL_VERIFY', true);
        },

        async dispatchFetchBankAccounts(){
            await this.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
            eventBus.$emit('bank-accounts-refresh');
        },

        finished(){
            this.$bvToast.hide();
            const isMobileView = window.innerWidth <= 768;
            const position = isMobileView ? 'b-toaster-bottom-right' : 'b-toaster-top-right';
            this.$bvToast.toast('The sync was complete.', {
                title: 'Sync complete',
                variant: 'success',
                solid: true,
                toaster: position,
            });
        },

        subscribeToUserChannel(accountId){
            if(!accountId){
                return;
            }

            this.echo.private(`ui-notify.${accountId}`)
                .listen('BankAccountCreated', () => {
                }).listen('SyncTransactionsFinished', payload => {
                    this.syncTransactionsFinished(payload);
                }).listen('SyncCreditCardLiabilityFinished', payload => {
                    this.syncCreditCardLiabilityFinished(payload);
                }).listen('WebhookReceived', payload => {
                    this.webhookReceived(payload);
                }).listen('ItemEnterInErrorMode', () => {
                    this.itemEnterInErrorMode();
                }).listen('UserEmailVerified', () => {
                    this.userEmailVerified();
                });
        },

        leaveUserChannel(accountId){
            if(!accountId){
                return;
            }

            this.echo.leave(`ui-notify.${accountId}`);
        }
    };
}
