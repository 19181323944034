export default {
    components: {},
    props: {
        bankAccount: {
            type: Object,
            required: true
        },
    },
    data,
    computed: getComputed(),
    created,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: []
    };
}

function getComputed(){
    return {
        displayedInstitutionName(){
            const hasLinkedInstitution = this.bankAccount.institution_account && this.bankAccount.institution_account.institution;
            return hasLinkedInstitution ? this.bankAccount.institution_account.institution.name : this.bankAccount.name;
        },
        errorType(){
            const recoverableErrorsCode = this.$store.getters['authorized/bankAccounts/recoverableErrorsCode'];
            const unrecoverableErrorsCode = this.$store.getters['authorized/bankAccounts/unrecoverableErrorsCode'];
            const errorStatues = recoverableErrorsCode.concat(unrecoverableErrorsCode);
            const institutionAccount = this.bankAccount.institution_account;

            if(!institutionAccount){
                return;
            }

            const institutionCredential = institutionAccount.institution_credential;

            if(!institutionCredential){
                return;
            }

            const errorCode = institutionCredential.remote_error_code;
            const errorDetected = errorCode || errorStatues.includes(errorCode);

            if(!errorDetected){
                return;
            }

            if(recoverableErrorsCode.includes(errorCode)){
                return 'recoverable';
            }

            if(unrecoverableErrorsCode.includes(errorCode)){
                return 'error';
            }

            return 'unknown';

        }
    };
}

function created(){}

function getMethods(){
    return {};
}
