export default {
    props: [
        'name',
        'type',
        'placeholder',
        'validationErrors',
        'value',
        'readOnly',
        'mask',
        'maskOptions',
        'inputmode',
    ],
    data(){
        return {
            inputValue: ''
        };
    },
    watch: {
        'inputValue': updateParent,
        'value': syncValue
    },
    created: syncValue
};

function updateParent(){
    this.$emit('input', this.inputValue);
}

function syncValue(){
    this.inputValue = this.value;
}
