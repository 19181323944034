import { render, staticRenderFns } from "./reports.vue?vue&type=template&id=72631a14&scoped=true"
import script from "./reports.controller.js?vue&type=script&lang=js&external"
export * from "./reports.controller.js?vue&type=script&lang=js&external"
import style0 from "./_reports.scss?vue&type=style&index=0&id=72631a14&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72631a14",
  null
  
)

export default component.exports