import { sortTransactions } from '../../../sorts/transactions.sort';

export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoaded: false,
        errorMessage: null,
        data: [],
    },
    actions: {
        GET_DATA: function({ commit, state }, { force = false, accountId = '' }){
            if(!force && (state.isLoading || state.isLoaded)){
                return Promise.resolve();
            }

            commit('SET_IS_LOADING', true);

            return Vue.appApi()
                .authorized()
                .bankAccount()
                .getAssignableAccounts(accountId)
                .then((response) => {
                    commit('SET_DATA', response.data);
                    commit('SET_IS_LOADED', true);
                })
                .catch((error) => {
                    commit('SET_ERROR_MESSAGE', error.appMessage);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });
        },
        FETCH_ACCOUNT_TRANSACTIONS: function({ commit }, { accountId, showAll = '' }){
            return Vue.appApi()
                .authorized()
                .bankAccount()
                .getAccountTransactions(accountId, showAll)
                .then((response) => {
                    commit('SET_TRANSACTIONS', response.data);
                    return response.data;
                });
        }
    },
    mutations: {
        SET_DATA: function(state, payload){
            state.data = payload;
        },
        SET_ERROR_MESSAGE: function(state, payload){
            state.errorMessage = payload;
        },
        SET_IS_LOADING: function(state, payload){
            state.isLoading = payload;
        },
        SET_IS_LOADED: function(state, payload){
            state.isLoaded = payload;
        },
        REFRESH_BANK_ACCOUNT_BALANCES: function(state, payload){
            const bankAccounts = payload;
            bankAccounts.forEach(updateBankBalanceProperties);

            function updateBankBalanceProperties(bankAccount){
                const propertiesToUpdate = [
                    'balance_available',
                    'balance_current',
                    'assignment_balance_adjustment',
                    'allocation_balance_adjustment'
                ];
                const assignableBankAccount = state.data.find(({ id }) => id === +bankAccount.id);
                if(assignableBankAccount){
                    propertiesToUpdate.forEach((property) => {
                        if(bankAccount[property] !== undefined){
                            Vue.set(assignableBankAccount, property, bankAccount[property]);
                        }
                    });
                }
            }
        },
        SET_TRANSACTIONS: function(state, payload){
            const { meta, data } = payload;
            const accountIndex = state.data.findIndex((account) => account.id === Number(meta.bank_account_id));
            if(accountIndex > -1){
                state.data[accountIndex] = {
                    ...state.data[accountIndex],
                    untransferred_assignments: data,
                    hasMoreThanFiveAssignments: meta.total > 5,
                    totalAssignments: meta.total,
                };
            }
        },
        ADD_TRANSACTION: function(state, payload){
            const accountIndex = state.data.findIndex((account) => account.id === payload.id);
            if(accountIndex > -1){
                const accountTransactions = state.data[accountIndex].untransferred_assignments || [];
                state.data[accountIndex] = {
                    ...state.data[accountIndex],
                    totalAssignments: state.data[accountIndex].totalAssignments + 1,
                    is_balance_overridden: true,
                    balance_available: state.data[accountIndex].balance_available - payload.transaction.transaction.amount,
                    untransferred_assignments: [
                        ...accountTransactions,
                        payload.transaction
                    ].sort(sortTransactions),
                };
            }
        },
        REMOVE_TRANSACTION: function(state, payload){
            const accountIndex = state.data.findIndex((account) => account.id === payload.id);
            if(accountIndex > -1){
                const transactionIndex = state.data[accountIndex].untransferred_assignments.findIndex(({ id }) => id === payload.transaction.id);
                if(transactionIndex > -1){
                    state.data[accountIndex].untransferred_assignments.splice(transactionIndex, 1);
                    state.data[accountIndex].totalAssignments = state.data[accountIndex].totalAssignments - 1;
                    state.data[accountIndex].is_balance_overridden = true;
                    state.data[accountIndex].balance_available = state.data[accountIndex].balance_available + payload.transaction.transaction.amount;
                }
            }
        },
        UPDATE_TRANSACTION: function(state, payload){
            const accountIndex = state.data.findIndex((account) => account.id === payload.id);
            if(accountIndex > -1){
                const transactionIndex = state.data[accountIndex].untransferred_assignments.findIndex(({ id }) => id === payload.transaction.id);
                const payloadAmount = payload.transaction.transaction.amount;
                const transactionAmount = state.data[accountIndex].untransferred_assignments[transactionIndex].transaction.amount;
                state.data[accountIndex].untransferred_assignments[transactionIndex] = { ...payload.transaction };
                state.data[accountIndex].balance_available = state.data[accountIndex].balance_available + transactionAmount;
                state.data[accountIndex].balance_available = state.data[accountIndex].balance_available - payloadAmount;
            }
        }
    },
};
