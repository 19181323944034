// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informationalText[data-v-505fa940] {
  font-size: 12px;
  text-align: center;
  color: rgba(11, 16, 42, 0.65);
}`, "",{"version":3,"sources":["webpack://./_forgot-password.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,6BAAA;AACJ","sourcesContent":[".informationalText {\n    font-size: 12px;\n    text-align: center;\n    color: rgba(11, 16, 42, 0.65);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
