import { render, staticRenderFns } from "./past-transactions.vue?vue&type=template&id=03a06a8e&scoped=true"
import script from "./past-transactions.controller.js?vue&type=script&lang=js&external"
export * from "./past-transactions.controller.js?vue&type=script&lang=js&external"
import style0 from "./_past-transactions.scss?vue&type=style&index=0&id=03a06a8e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a06a8e",
  null
  
)

export default component.exports