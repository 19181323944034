import Vue from 'vue';
import transactions from './plaid/transactions.store';
import bankAccounts from './plaid/bank-accounts.store';
import eventBus from 'vue_root/plugins/eventBus.js';

const state = {
    displayUpgradeModal: false,
    isFetchingDataFromPlaid: false,
    isFetchingAccountBalancesFromPlaid: false,
    isFetchingTransactionsFromPlaid: false,
    isWatchingWebhooksFromPlaid: false,
    ignoreFinishEventOnLoadComplete: false,
};
export default {
    namespaced: true,
    state,
    actions: getActions(),
    mutations: getMutations(),
    getters: getGetters(),
    modules: {
        transactions,
        bankAccounts
    },
};

function getActions(){
    return {
        INITIALIZE_STATE: initializeState,
        DISPLAY_UPGRADE_MODAL: displayUpgradeModal,
        FETCHING_DATA_FROM_PLAID: fetchingDataFromPlaid,
        FETCHING_ACCOUNT_BALANCES_FROM_PLAID: fetchingAccountBalancesFromPlaid,
        FETCHING_TRANSACTIONS_FROM_PLAID: fetchingTransactionsFromPlaid,
        WATCHING_WEBHOOKS_FROM_PLAID: watchingWebhooksFromPlaid,
        REFRESH_LINKED_ACCOUNTS: refreshLinkedAccounts,
        TRANSACTION_REFRESH_ERRORS: transactionRefreshErrors,
    };

    function initializeState({ commit, dispatch }){
        return Promise.all([
            dispatch('transactions/INITIALIZE_STATE'),
            dispatch('bankAccounts/INITIALIZE_STATE'),
            dispatch('tourWalkthrough/INITIALIZE_TOUR_STATE', null, { root: true })
        ]);
    }

    function displayUpgradeModal({ commit, state }){
        commit('TOGGLE_UPGRADE_MODAL', true);
        return Promise.resolve();
    }

    function fetchingDataFromPlaid({ state, commit }, value = true){
        if(value === true){
            commit('IS_FETCHING_DATA_FROM_PLAID', true);
            commit('IS_FETCHING_ACCOUNT_BALANCES_FROM_PLAID', true);
            commit('IS_FETCHING_TRANSACTIONS_FROM_PLAID', true);
            return;
        } else if(value === 'refresh'){
            commit('IGNORE_FINISH_EVENT_ON_LOAD_COMPLETE', true);
            commit('IS_FETCHING_DATA_FROM_PLAID', true);
            return;
        }

        if(state.isFetchingDataFromPlaid){
            commit('IS_FETCHING_DATA_FROM_PLAID', false);

            if(state.ignoreFinishEventOnLoadComplete){
                commit('IGNORE_FINISH_EVENT_ON_LOAD_COMPLETE', false);
            } else {
                eventBus.$emit('plaid-fetch-finished');
            }
        }
    }

    function fetchingAccountBalancesFromPlaid({ commit }, value = true){
        commit('IS_FETCHING_ACCOUNT_BALANCES_FROM_PLAID', value);
    }

    function fetchingTransactionsFromPlaid({ commit }, value = true){
        commit('IS_FETCHING_TRANSACTIONS_FROM_PLAID', value);
    }

    function watchingWebhooksFromPlaid({ commit }, value = true){
        commit('IS_WATCHING_WEBHOOKS_FROM_PLAID', value);
    }

    function refreshLinkedAccounts({ dispatch }){
        return Vue.appApi()
            .authorized()
            .plaid()
            .refreshLinkedAccounts();
    }

    function transactionRefreshErrors({ dispatch }){
        return Vue.appApi()
            .authorized()
            .plaid()
            .getRefreshErrors();
    }
}

function getMutations(){
    return {
        TOGGLE_UPGRADE_MODAL: toggleUpgradeModal,
        IS_FETCHING_DATA_FROM_PLAID: setIsFetchingDataFromPlaid,
        IS_FETCHING_ACCOUNT_BALANCES_FROM_PLAID: setIsFetchingAccountBalancesFromPlaid,
        IS_FETCHING_TRANSACTIONS_FROM_PLAID: setIsFetchingTransactionsFromPlaid,
        IS_WATCHING_WEBHOOKS_FROM_PLAID: setIsWatchingWebhooksFromPlaid,
        IGNORE_FINISH_EVENT_ON_LOAD_COMPLETE: setIgnoreFinishEventOnLoadComplete,
    };

    function toggleUpgradeModal(state, isShown){
        if(typeof isShown === 'undefined'){
            state.displayUpgradeModal = !state.displayUpgradeModal;
        } else {
            state.displayUpgradeModal = isShown;
        }
    }

    function setIsFetchingDataFromPlaid(state, isFetching){
        state.isFetchingDataFromPlaid = isFetching;
    }

    function setIsFetchingAccountBalancesFromPlaid(state, isFetching){
        state.isFetchingAccountBalancesFromPlaid = isFetching;
    }

    function setIsFetchingTransactionsFromPlaid(state, isFetching){
        state.isFetchingTransactionsFromPlaid = isFetching;
    }

    function setIsWatchingWebhooksFromPlaid(state, isFetching){
        state.isWatchingWebhooksFromPlaid = isFetching;
    }

    function setIgnoreFinishEventOnLoadComplete(state, value){
        state.ignoreFinishEventOnLoadComplete = value;
    }
}

function getGetters(){
    return {};
}
