
export default {
    namespaced: true,
    state: {
        isLoading: {},
        isLoaded: {},
        errorMessage: {},
        data: [],
    },
    actions: {
        GET_DATA: function({ commit, state }, payload){
            const id = payload.id;
            const force = payload.force || false;

            if(typeof id === 'undefined'){
                return Promise.reject();
            }

            if(!force && (isLoading(state, id) || isLoaded(state, id))){
                return Promise.resolve();
            }

            commit('SET_IS_LOADING', { [id]: true });

            return Vue.appApi()
                .authorized()
                .bankAccount(id)
                .scheduleItem()
                .get()
                .then((response) => {
                    commit('SET_DATA', { id, data: response.data || [] });
                    commit('SET_IS_LOADED', { [id]: true });
                })
                .catch((error) => {
                    commit('SET_ERROR_MESSAGE', { [id]: error.appMessage || null });
                })
                .finally(() => {
                    commit('SET_IS_LOADING', { [id]: false });
                });
        },
    },
    mutations: {
        SET_DATA: function(state, payload){
            if(payload.data){
                const index = state.data.findIndex((item) => item.id === payload.id);
                const data = {
                    id: payload.id,
                    data: payload.data
                };

                if(index === -1){
                    state.data.push(data);

                    return;
                }

                state.data.splice(index, 1, data);
            }
        },
        SET_ERROR_MESSAGE: function(state, payload){
            Object.assign(state.errorMessage, payload);
        },
        SET_IS_LOADING: function(state, payload){
            Object.assign(state.isLoading, payload);
        },
        SET_IS_LOADED: function(state, payload){
            Object.assign(state.isLoaded, payload);
        },
    }
};

function isLoading(state, id){
    return typeof state.isLoading[id] !== 'undefined' ? state.isLoading[id] : false;
}

function isLoaded(state, id){
    return typeof state.isLoaded[id] !== 'undefined' ? state.isLoaded[id] : false;
}
