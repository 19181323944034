var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('loading-spinner',{attrs:{"show-spinner":_vm.loadingPlaidLinkModal,"custom-class":"overlay-fixed"}}),_vm._v(" "),(_vm.canManagePlaidAccounts)?_c('div',[_c('b-button',{staticClass:"plaid-link-button",attrs:{"id":"linkPlaidBtn","variant":"primary","size":"md","disabled":_vm.isPlaidButtonActive},on:{"click":_vm.openPlaidConnect}},[_c('loading-spinner',{attrs:{"show-spinner":_vm.isPlaidButtonActive,"custom-class":"size-auto"}},[_vm._v("\n                Link +\n            ")])],1)],1):_vm._e(),_vm._v(" "),_c('b-modal',{ref:"updateModeNewAccountsFlow",attrs:{"hide-header":"","hide-footer":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center p-5"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center mb-4"},[_c('i',{staticClass:"fas fa-exclamation-triangle text-warning fa-3x"})]),_vm._v(" "),_c('h2',{staticClass:"m-0"},[_vm._v("Some of your institution accounts are already linked from "+_vm._s(_vm.updateModeInstitutionCredential?.institution?.name))]),_vm._v(" "),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v("Bank accounts linked previously: "),_c('br'),_vm._v(_vm._s(_vm.updateModeInstitutionCredential?.institution_account?.map(e => `${e.name} x-${e.mask}`).join(', ')))]),_vm._v(" "),_c('p',{staticClass:"mt-4 mb-0"},[_c('strong',[_vm._v("To add new bank accounts for this institution click on the button below.")])]),_vm._v(" "),_c('b-button',{staticClass:"mt-4",on:{"click":_vm.continueToAddNewAccounts}},[_vm._v("Continue to add new bank accounts")]),_vm._v(" "),_c('b-button',{staticClass:"mt-4",attrs:{"variant":"outline-dark"},on:{"click":() => {
                _vm.$refs.updateModeNewAccountsFlow.hide();
                _vm.loadingPlaidLinkModal = false;
                _vm.isPlaidButtonActive = false;
            }}},[_vm._v("\n                Cancel\n            ")])],1)]),_vm._v(" "),_c('b-modal',{ref:"credentialOverlapErrorModal",attrs:{"hide-header":"","hide-footer":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":""}},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center p-5"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center text-center mb-4"},[_c('i',{staticClass:"fas fa-exclamation-triangle text-warning fa-3x"})]),_vm._v(" "),_c('h2',{staticClass:"m-0"},[_vm._v("There was an error while linking accounts from "+_vm._s(_vm.credentialOverlapError?.institutionName))]),_vm._v(" "),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v(_vm._s(_vm.credentialOverlapError?.error))]),_vm._v(" "),_c('b-button',{staticClass:"mt-4",attrs:{"variant":"outline-dark"},on:{"click":() => {
                _vm.$refs.credentialOverlapErrorModal.hide();
                _vm.credentialOverlapError = null;
                _vm.isPlaidButtonActive = false;
            }}},[_vm._v("\n                Close\n            ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }