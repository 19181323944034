export default {
    data: data,
    methods: getMethods(),
    computed: getComputed(),
};

function data(){
    return {
        businessOptions: [
            {
                id: 1,
                text: 'Spend <span class="d-block">Smarter</span>',
                icon: 'fa-user'
            },
            {
                id: 2,
                text: 'Automate my <span class="d-block">finances</span>',
                icon: 'fa-sync-alt'
            },
            {
                id: 3,
                text: 'Build my <span class="d-block">savings</span>',
                icon: 'fa-signal'
            },
            {
                id: 4,
                text: 'Improve my <span class="d-block">credit score</span>',
                icon: 'fa-check-double'
            },
            {
                id: 5,
                text: 'Simplify my <span class="d-block">finances</span>',
                icon: 'fa-chart-pie'
            },
            {
                id: 6,
                text: 'Pay down <span class="d-block">credit card debt</span>',
                icon: 'fa-credit-card'
            },
        ],
        selectedBusinessOptions: [],
    };
}

function getComputed(){
    return {
        user(){
            return this.$store.getters.user;
        }
    };
}

function getMethods(){
    return {
        selectBusinessOption,
        onClickContinue,
    };
    function selectBusinessOption(business){
        const vm = this;
        const foundBusinessOption = vm.selectedBusinessOptions.findIndex((data) => data === business);
        if(foundBusinessOption >= 0){
            vm.selectedBusinessOptions.splice(foundBusinessOption, 1);
        } else {
            vm.selectedBusinessOptions.push(business);
        }
    }
    function onClickContinue(){
        const vm = this;
        vm.$store.dispatch('user/GET_USER', true).then(handleRedirect);

        function handleRedirect(){
            const userVerified = vm.user && vm.user.email_verified && vm.user.phone_verified;
            const userIsSubscriber = !!vm.user.current_account.status;
            if(userVerified && !userIsSubscriber){
                vm.$router.push({ name: 'subscription-required' });
            } else {
                const destination = userIsSubscriber
                    ? { name: 'dashboard' }
                    : vm.$store.state.tourWalkthrough.tourLinks[0];

                vm.$router.push(destination);
            }
        }
    }
}
