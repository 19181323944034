import { render, staticRenderFns } from "./savings.vue?vue&type=template&id=588527d2&scoped=true"
import script from "./savings.controller.js?vue&type=script&lang=js&external"
export * from "./savings.controller.js?vue&type=script&lang=js&external"
import style0 from "./_savings.scss?vue&type=style&index=0&id=588527d2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588527d2",
  null
  
)

export default component.exports