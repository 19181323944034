import GraphPanel from './components/graph-panel/graph-panel';
import TransactionPanel from './components/transaction-panel/transaction-panel';

export default {
    components: {
        GraphPanel,
        TransactionPanel,
    },
    data,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        validationErrors: {},
    };
}

function getMethods(){
    return {
        displayErrorMessage,
        onAssignSuccess,
    };

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function onAssignSuccess(){
        this.$refs.graphPanel.fetchAssignmentsAggregate();
    }
}
