// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-bar[data-v-54849038] {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
}
.pagination-bar .rows-per-page[data-v-54849038] {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.pagination-bar .rows-per-page p[data-v-54849038] {
  margin: 0;
  border-radius: 20px;
}
.pagination-bar .rows-per-page select[data-v-54849038] {
  margin: 0;
  border-radius: 20px;
  padding: 5px;
  border: 1px solid #ced4da;
}`, "",{"version":3,"sources":["webpack://./_pagination-bar.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,uBAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;AACR;AACQ;EACI,SAAA;EACA,mBAAA;AACZ;AAEQ;EACI,SAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;AAAZ","sourcesContent":[".pagination-bar {\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n    gap: 30px;\n\n    .rows-per-page {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        gap: 10px;\n\n        p {\n            margin: 0;\n            border-radius: 20px;\n        }\n\n        select {\n            margin: 0;\n            border-radius: 20px;\n            padding: 5px;\n            border: 1px solid #ced4da;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
