import AccountScheduleList from './components/account-schedule-list/account-schedule-list';
import AccountScheduleDetails from './components/account-schedule-details/account-schedule-details.vue';

export default {
    components: {
        AccountScheduleList,
        AccountScheduleDetails,
    },
    props: {
        bankAccount: {
            type: Object,
            required: true,
        },
    },
    data,
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
};

function data(){
    return {
        defaultScheduleItem: {
            amount_monthly: '',
            amount_total: undefined,
            bank_account_id: null,
            description: '',
            id: null,
            isCalculatingMonthlyAmount: false,
            isDirty: true,
            is_selected: true,
            type: 'monthly',
            approximate_due_date: null,
        },
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        scheduleItems: [],
        pristineScheduleItems: [],
        isModalShown: false,
        isDisplayingList: true,
        activeScheduleItem: null,
        loadingScheduleItems: true,
        isFormEditting: false,
    };
}

function getComputed(){
    return {
        scheduledAmount(){
            const vm = this;
            return new Decimal(vm.totalSelectedItems || 0).toDecimalPlaces(2).toNumber();
        },
        totalSelectedItems(){
            const vm = this;
            const selectedScheduleItems = vm.scheduleItems.filter(({ is_selected }) => is_selected);
            const total = selectedScheduleItems.reduce((sum, scheduleItem) => {
                return sum.plus(scheduleItem.amount_monthly || 0).toDecimalPlaces(2);
            }, new Decimal(0));
            return total.toNumber();
        },
        bankAccountId(){
            const vm = this;
            return vm.bankAccount.id;
        },
        bankAccountName(){
            const vm = this;
            return vm.bankAccount.name;
        },
        dirtyScheduleItems(){
            const vm = this;
            return vm.scheduleItems.filter(({ isDirty, isSaving }) => isDirty && !isSaving);
        },
    };
}

function getWatchers(){
    return {
        isModalShown: {
            handler: function(newVal){
                const vm = this;
                if(newVal === true){
                    vm.pristineScheduleItems = [];
                    vm.loadScheduleItems(vm.bankAccountId);
                }
            },
            immediate: true,
        },
        loadingScheduleItems: {
            handler: function(newVal){
                const vm = this;
                vm.$emit('loading', newVal);
            },
            immediate: true
        },
        pristineScheduleItems: {
            handler: function(newVal){
                const vm = this;
                vm.$emit('schedule-items-updated', newVal);
            },
            immediate: true
        },
        scheduledAmount: {
            handler: function(newVal){
                const vm = this;
                vm.$emit('total-updated', newVal);
            },
            immediate: true
        },
        bankAccountId: {
            handler: function(newVal, oldVal){
                const vm = this;
                vm.loadScheduleItems(newVal);
            },
            immediate: true,
        },
    };
}

function getMethods(){
    return {
        show,
        loadScheduleItems,
        resetWorkingCopyOfScheduleItems,
        sortScheduleItems,
        initializeScheduleItemDisplayProperties,
        setScheduledItems,
        onClickBackToList,
        onClickScheduleItem,
        onModalClose,
        confirmUnsavedChanges,
    };
    function show(){
        const vm = this;
        vm.$refs.bankAccountScheduleModal.show();
    }

    function loadScheduleItems(bankAccountId){
        const vm = this;
        if(bankAccountId > 0){
            vm.loadingScheduleItems = true;
            vm.defaultScheduleItem.bank_account_id = bankAccountId;

            return vm.$store.dispatch('scheduleItem/GET_DATA', { id: bankAccountId, force: true }).then(vm.setScheduledItems).finally(resetLoadingState);

        } else {
            return Promise.resolve();
        }
        function resetLoadingState(){
            vm.loadingScheduleItems = false;
        }
    }

    function resetWorkingCopyOfScheduleItems(){
        const vm = this;
        const newWorkingCopy = JSON.parse(JSON.stringify(vm.pristineScheduleItems)).map(vm.initializeScheduleItemDisplayProperties);
        vm.scheduleItems = vm.sortScheduleItems(newWorkingCopy);
    }

    function sortScheduleItems(scheduleItems){
        const vm = this;
        return scheduleItems.sort(byTypeAndDueDate);

        function byTypeAndDueDate(a, b){
            const typeOrder = ['monthly', 'quarterly', 'yearly', 'target_date'];
            let sort = typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
            if(sort === 0){
                if(a.type === 'monthly' || a.type === 'quarterly'){
                    if(!a.approximate_due_date){
                        sort = 1;
                    } else if(!b.approximate_due_date){
                        sort = -1;
                    } else {
                        sort = a.approximate_due_date - b.approximate_due_date;
                    }
                } else if(a.type === 'yearly'){
                    if(!vm.months.includes(a.approximate_due_date)){
                        sort = 1;
                    } else if(!vm.months.includes(b.approximate_due_date)){
                        sort = -1;
                    } else {
                        sort = vm.months.indexOf(a.approximate_due_date) - vm.months.indexOf(b.approximate_due_date);
                    }
                } else {
                    if(!a.date_end){
                        sort = 1;
                    } else if(!b.date_end){
                        sort = -1;
                    } else {
                        sort = Vue.moment(a.date_end).isAfter(b.date_end) ? 1 : -1;
                    }
                }
            }
            return sort;
        }
    }

    function initializeScheduleItemDisplayProperties(scheduleItem){
        scheduleItem.isCalculatingMonthlyAmount = false;
        scheduleItem.isDirty = false;
        scheduleItem.is_selected = true;
        return scheduleItem;
    }

    function setScheduledItems(){
        const vm = this;
        const scheduleItemStoreData = vm.$store.state.scheduleItem.data.find(i => i.id === vm.bankAccountId);

        if(scheduleItemStoreData !== undefined){
            vm.pristineScheduleItems = scheduleItemStoreData.data;
        } else {
            vm.pristineScheduleItems = [];
        }
        vm.isFormEditting = false;
        vm.isDisplayingList = true;
        vm.activeScheduleItem = null;
        vm.resetWorkingCopyOfScheduleItems();
    }

    function onClickBackToList(){
        const vm = this;
        vm.isDisplayingList = true;
        vm.activeScheduleItem = null;
        vm.isFormEditting = false;
    }

    function onClickScheduleItem(schedule){
        const vm = this;
        vm.activeScheduleItem = schedule;
        vm.isDisplayingList = false;
    }

    function onModalClose(){
        this.isFormEditting = false;
        this.isModalShown = false;
        this.isDisplayingList = true;
        this.activeScheduleItem = null;
        this.scheduleItems = [];
        this.loadingScheduleItems = true;
        this.$refs.bankAccountScheduleModal.hide();
    }

    function confirmUnsavedChanges(event){
        const vm = this;
        if(vm.isFormEditting){
            event.preventDefault();
            return confirmCloseChanges().then(answerConfirmation);
        } else {
            vm.onModalClose();
        }
        function confirmCloseChanges(){
            const message = 'You have unsaved changes, are you sure you want to close?';
            const options = {
                size: 'sm',
                okVariant: 'light',
                cancelVariant: 'light',
                okTitle: 'Close',
                cancelTitle: 'Go back',
                hideHeader: true,
                bodyClass: 'text-center mb-0 mt-3',
                footerClass: 'd-flex justify-content-center border-0',
                centered: true,
            };
            return vm.$bvModal.msgBoxConfirm(message, options);
        }

        function answerConfirmation(isConfirmed){
            if(isConfirmed){
                vm.onModalClose();
            }
        }
    }
}
