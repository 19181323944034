import { isEmpty } from 'lodash';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
    props: {
        credentials: Object,
        validationErrors: Object,
        apiErrors: Array,
        registeringUser: Boolean,
        onClickBack: Function,
        attemptRegisterUser: Function,
    },
    components: {
        VueRecaptcha,
    },
    data: data,
    methods: getMethods(),
    computed: getComputed(),
};

function data(){
    return {
        recaptcha: {
            disabled: window.appEnv.recaptchaDisabled,
            siteKey: window.appEnv.recaptchaSiteKey,
            verified: false,
        },
        displayPasswordRequirementsPanel: false,
    };
}

function getComputed(){
    return {
        canRegister,
    };

    function canRegister(){
        const passwordCredentials = {
            password: this.credentials.password,
            password_confirmation: this.credentials.password_confirmation,
        };
        return (this.recaptcha.disabled || this.recaptcha.verified) &&
            Object.values(passwordCredentials).every(v => !isEmpty(v));
    }
}

function getMethods(){
    return {
        onVerifyRecaptcha,
        displayAsValidationFailedText
    };

    function onVerifyRecaptcha(response){
        if(response){
            this.recaptcha.verified = true;
        }
    }

    function displayAsValidationFailedText(isValidationFailed = false){
        return isValidationFailed ? 'text-danger' : '';
    }
}
