// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchInput[data-v-6219609d] {
  position: relative;
}
.searchInput input[data-v-6219609d] {
  text-indent: 24px;
}
.searchInput .fa-search[data-v-6219609d] {
  position: absolute;
  top: 10px;
  left: 18px;
}
[data-v-6219609d] .table-nostriped tbody th tr:nth-of-type(odd) {
  background-color: white;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}
[data-v-6219609d] .table-nostriped th {
  background-color: white;
}
[data-v-6219609d] th {
  font-weight: 600;
}
[data-v-6219609d] .user-list-table {
  max-height: 800px;
}`, "",{"version":3,"sources":["webpack://./_business-user-list.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;AADJ;AAEI;EACI,iBAAA;AAAR;AAEI;EACI,kBAAA;EACA,SAAA;EACA,UAAA;AAAR;AAKI;EACI,uBAAA;EACA,qBAAA;EACA,oBAAA;EACA,oBAAA;AAFR;AAKI;EACI,uBAAA;AAHR;AAMI;EACI,gBAAA;AAJR;AAQI;EACI,iBAAA;AANR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.searchInput {\n    position: relative;\n    input {\n        text-indent: 24px;\n    }\n    .fa-search {\n        position: absolute;\n        top: 10px;\n        left: 18px;\n    }\n}\n\n::v-deep {\n    .table-nostriped tbody th tr:nth-of-type(odd) {\n        background-color: white;\n        padding: 0 !important;\n        margin: 0 !important;\n        border: 0 !important;\n    }\n\n    .table-nostriped th {\n        background-color: white;\n    }\n\n    th {\n        font-weight: 600;\n\n    }\n\n    .user-list-table {\n        max-height: 800px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
