import { render, staticRenderFns } from "./automation.vue?vue&type=template&id=287b0c36&scoped=true"
import script from "./automation.controller.js?vue&type=script&lang=js&external"
export * from "./automation.controller.js?vue&type=script&lang=js&external"
import style0 from "./_automation.scss?vue&type=style&index=0&id=287b0c36&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "287b0c36",
  null
  
)

export default component.exports