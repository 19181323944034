export default {
    props: {
        bankAccount: {
            type: Object,
            required: true,
        },
        scheduleItems: {
            type: Array,
        },
        total: {
            type: Number,
        },
        loadingScheduleItems: {
            type: Boolean,
        },
    },
    computed: getComputed(),
    methods: getMethods(),
};

function getComputed(){
    return {
        groupScheduledItems(){
            const vm = this;
            const groupSchedule = vm.scheduleItems.reduce((accumulator, currentValue) => {
                accumulator[currentValue.type] = accumulator[currentValue.type] || [];
                accumulator[currentValue.type].push(currentValue);
                return accumulator;
            }, Object.create(null));

            return groupSchedule;
        },
    };
}

function getMethods(){
    return {
        formatTypes,
        formatMonthly,
        formatYearly,
        formatTargetDate,
    };

    function formatTypes(type){
        switch (type){
            case 'monthly': return 'Monthly';
            case 'yearly': return 'Yearly';
            case 'target_date': return 'Target date';
            case 'quarterly': return 'Quarterly';
            default: return '';
        }
    }

    function formatMonthly(date){
        if(date === null){
            return;
        }
        const moment = Vue.moment();
        moment.date(date);
        return Vue.moment(moment).format('Do');
    }

    function formatYearly(date){
        if(date === null){
            return;
        }
        const moment = Vue.moment();
        moment.month(date);
        return Vue.moment(moment).format('MMM');
    }

    function formatTargetDate(date){
        if(date === null){
            return;
        }
        const moment = Vue.moment(date);
        return Vue.moment(moment).format("MMM[']YY");
    }
}
