import CanUserMixin from 'vue_root/mixins/can-user.mixin.js';

export default {
    components: {
    },
    mixins: [CanUserMixin],
    data: data,
    mounted,
    watch: getWatchers(),
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        clientPlatform: window.appEnv.clientPlatform || 'web',
    };
}

function getWatchers(){
    return {
        '$route.name': onChangeRoute
    };

    function onChangeRoute(){
        this.updateOnboardingStatus();
    }
}

function mounted(){
    this.updateOnboardingStatus();
}

function getComputed(){
    return {
        user(){
            return this.$store.state.guest.user.user;
        },
        isFetchingDataFromPlaid(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        isPlusSubscriber(){
            const vm = this;
            return vm.user.current_account.subscription_plan === 'plus';
        },
        routerLinks(){
            const links = [
                { routeName: 'onboarding-link', description: 'Link accounts', exact: true },
                { routeName: 'onboarding-buckets', description: 'Create buckets', exact: true },
                { routeName: 'onboarding-organize', description: 'Organize money', exact: true },
            ];

            return links;
        },
        onboardingLinks(){
            const vm = this;
            return vm.routerLinks.filter(checkPermission);

            function checkPermission(link){
                const hasPermission = link.permissions && link.permissions.length > 0;
                if(!hasPermission){
                    return true;
                }

                return link.permissions.every(permission => vm.canUser(permission));
            }
        },
        useEmptyLayout(){
            return !['onboarding-link', 'onboarding-buckets', 'onboarding-organize'].includes(this.$route.name);
        },
        showBackButton(){
            return this.$route.name !== 'onboarding-link';
        },
        skipButtonLabel(){
            return this.$route.name === 'onboarding-organize' ? 'Close' : 'Skip';
        },
        isLinkedInstitution(){
            const hasPrimaryCheckingAccount = this.$store.state.authorized.bankAccounts.bankAccounts.filter(({ slug }) => slug === 'primary_checking')[0];
            const linkedBankAccounts = this.$store.getters['authorized/bankAccounts/bankAccountsLinked'];
            if(this.$route.name !== 'onboarding-link' && linkedBankAccounts.length !== 0 && hasPrimaryCheckingAccount){
                return true;
            }
            return linkedBankAccounts.length > 0 && this.$store.state.authorized.bankAccounts.isCheckingAccountSelected;
        }
    };
}

function getMethods(){
    return {
        logout,
        goBack,
        skipToNextStep,
        goToDashboard,
        updateOnboardingStatus,
    };

    function logout(){
        const vm = this;
        vm.$store.dispatch('user/LOGOUT').then(logoutSuccess);

        function logoutSuccess(){
            if(vm.clientPlatform === 'web'){
                vm.$router.go();
            } else {
                vm.$router.replace({ name: 'login' });
            }
        }
    }

    function goBack(){
        const vm = this;
        if(vm.$route.name === 'onboarding-buckets'){
            vm.$router.push({ 'name': 'onboarding-link' });
        } else if(vm.$route.name === 'onboarding-organize'){
            vm.$router.push({ 'name': 'onboarding-buckets' });
        }
    }

    function skipToNextStep(){
        const vm = this;
        if(vm.$route.name === 'onboarding-link'){
            vm.$router.push({ 'name': 'onboarding-buckets' });
        } else if(vm.$route.name === 'onboarding-buckets'){
            vm.$router.push({ 'name': 'onboarding-organize' });
        } else if(vm.$route.name === 'onboarding-organize'){
            vm.$refs.confirmCloseOnboardingModal.show();
        }
    }

    function goToDashboard(){
        const vm = this;
        vm.updateOnboardingStatus(true)
            .then(() => vm.$store.dispatch('user/GET_USER', true))
            .then(reloadOnDashboard);

        function reloadOnDashboard(){
            vm.$router.push({ 'name': 'dashboard' });
        }
    }

    function updateOnboardingStatus(skipped = false){
        const vm = this;
        const currentRouteName = vm.$route.name;
        var onboardingStatus;
        if(skipped){
            onboardingStatus = 'skipped';
        } else if(currentRouteName === 'onboarding-success'){
            onboardingStatus = 'completed';
        } else if(['onboarding-link', 'onboarding-buckets', 'onboarding-organize'].includes(currentRouteName)){
            onboardingStatus = currentRouteName;
        } else {
            onboardingStatus = 'onboarding-link';
        }

        return Vue.appApi().authorized().user().updateOnboardingStatus(onboardingStatus);
    }
}
