import { render, staticRenderFns } from "./session-timeout-modal.vue?vue&type=template&id=03becf3a"
import script from "./session-timeout-modal.controller.js?vue&type=script&lang=js&external"
export * from "./session-timeout-modal.controller.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports