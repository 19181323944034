export default {
    mounted,
    computed: getComputed(),
    methods: getMethods(),
};

function mounted(){
    this.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
}

function getComputed(){
    return {
        loadingBankAccounts,
    };

    function loadingBankAccounts(){
        const vm = this;
        return vm.$store.state.authorized.bankAccounts.isFetchingBankAccounts;
    }
}

function getMethods(){
    return {
        goToDashboard,
    };

    function goToDashboard(){
        const vm = this;
        vm.$store.dispatch('user/GET_USER', true).then(reloadOnDashboard);

        function reloadOnDashboard(){
            vm.$router.push({ 'name': 'dashboard' });
        }
    }
}
