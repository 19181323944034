import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';

export default {
    data,
    created,
    mixins: [sortBankAccounts],
    computed: getComputed(),
    watch: getWatchers(),
    methods: getMethods(),
};

function created(){
    this.populateBuckets();
}

function data(){
    return {
        buckets: [],
        isSaving: false,
        errorMessages: [],
    };
}

function getComputed(){
    return {
        primaryCheckingAccount,
        loadingBankAccounts,
        canContinue,
    };

    function primaryCheckingAccount(){
        return this.$store.state.authorized.bankAccounts.bankAccounts
            .filter(({ slug }) => slug === 'primary_checking')[0];
    }

    function loadingBankAccounts(){
        const vm = this;
        return vm.$store.state.authorized.bankAccounts.isFetchingBankAccounts;
    }

    function canContinue(){
        const bucketsAvailable = true;
        return this.primaryCheckingAccount && bucketsAvailable;
    }
}

function getWatchers(){
    return {
        loadingBankAccounts(newStatus, oldStatus){
            if(oldStatus && !newStatus){
                this.populateBuckets();
            }
        },
    };
}

function getMethods(){
    return {
        populateBuckets,
        saveBucket,
        saveAndContinue,
    };

    function populateBuckets(){
        const vm = this;
        const newBuckets = [];
        const defaultBuckets = [
            new BankAccount({
                name: 'Income',
                purpose: 'income',
                slug: 'income_deposit',
                appears_in_account_list: false,
                color: 'gray',
                balance_current: vm.primaryCheckingAccount ? vm.primaryCheckingAccount.balance_current : 0,
            }),
            new BankAccount({
                name: 'Bills',
                purpose: 'bills',
                slug: 'monthly_bills',
                type: 'checking',
                appears_in_account_list: false,
                color: 'pink',
            }),
            new BankAccount({
                name: 'Spending',
                purpose: 'spending',
                slug: 'everyday_checking',
                type: 'checking',
                appears_in_account_list: false,
                color: 'green',
            }),
            new BankAccount({
                name: 'Entertainment',
                purpose: 'savings',
                appears_in_account_list: false,
                color: 'violet',
            }),
            new BankAccount({
                name: 'Personal',
                purpose: 'savings',
                appears_in_account_list: false,
                color: 'yellow',
            }),
            new BankAccount({
                name: 'Other',
                purpose: 'savings',
                appears_in_account_list: false,
                color: 'orange',
            }),
        ];

        if(vm.primaryCheckingAccount){
            const checkingSubBuckets = JSON.parse(JSON.stringify(vm.primaryCheckingAccount.sub_accounts)).filter(({ slug }) => slug !== 'cc_payoff');
            newBuckets.push(...checkingSubBuckets.sort(vm.byModifiedStoreOrder));
            defaultBuckets.splice(0, newBuckets.length);
            newBuckets.push(...defaultBuckets);

            vm.buckets = newBuckets;
        } else {
            vm.buckets = defaultBuckets;
        }
    }

    function saveBucket(subAccount, order){
        const vm = this;
        subAccount.parent_bank_account_id = vm.primaryCheckingAccount.id;
        subAccount.sub_account_order = order;
        const payload = JSON.parse(JSON.stringify(subAccount));
        return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', payload).catch(updateBucketFailure);
        function updateBucketFailure(error){
            const errorMessage = error.appMessage || (error.data && error.data.message);
            if(errorMessage){
                vm.errorMessages.push(errorMessage);
            }
        }
    }

    function saveAndContinue(){
        const vm = this;
        if(!vm.primaryCheckingAccount || vm.isSaving){
            return;
        }

        vm.isSaving = true;
        vm.errorMessages = [];
        const bucketsToSave = vm.buckets.filter(({ name }) => !!name);
        const savePromises = bucketsToSave.map((bucket, index) => vm.saveBucket(bucket, index + vm.primaryCheckingAccount.sub_accounts.length));
        return Promise.all(savePromises).then(() => {
            vm.$nextTick(() => {
                vm.$router.push({ 'name': 'onboarding-organize' });
            });
        }).finally(resetLoadingState);
        function resetLoadingState(){
            vm.isSaving = false;
        }
    }
}

function BankAccount(defaults = {}){
    this.parent_bank_account_id = defaults.parent_bank_account_id || null;
    this.sub_accounts = defaults.sub_accounts || [];
    this.sub_account_order = isNaN(parseInt(defaults.sub_account_order)) ? null : defaults.sub_account_order;
    this.name = defaults.name || '';
    this.slug = defaults.slug || null;
    this.color = defaults.color || '';
    this.purpose = defaults.purpose || 'none';
    this.is_balance_overridden = defaults.is_balance_overridden || false;
    this.appears_in_account_list = defaults.appears_in_account_list || false;
    this.balance_current = defaults.balance_current || 0;
    this.balance_available = defaults.balance_available || 0;
    this.allocation_balance_adjustment = defaults.allocation_balance_adjustment || 0;
    this.assignment_balance_adjustment = defaults.assignment_balance_adjustment || 0;
    this.id = defaults.id || null;
}
