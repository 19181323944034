import Decimal from 'decimal.js';
import { Bar, mixins } from 'vue-chartjs';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin.js';

export default {
    extends: Bar,
    mixins: [
        mixins.reactiveProp,
        formatAsDecimal
    ],
    props: {
        chartData: {
            type: Object,
            required: true
        },
    },
    data(){
        const vm = this;
        return {
            options: {
                legend: false,
                aspectRatio: 1.5,
                responsive: true,
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data){
                            const name = data.datasets[tooltipItem.datasetIndex].label;
                            const amount = vm.formatAsDecimal(tooltipItem.value, true);
                            const tooltipLabel = ` ${name}: ${amount}`;
                            return tooltipLabel;
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        stacked: true,
                        categoryPercentage: 0.5,
                        barPercentage: 1,
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false
                        },
                        ticks: {
                            callback: function(value){
                                if(value >= 1000){
                                    return '$ ' + new Decimal((+value) / 1000).toFixed(1) + 'k';
                                } else if(value <= -1000){
                                    return '- $ ' + new Decimal(Math.abs(value) / 1000).toFixed(1) + 'k';
                                } else if(value < 0){
                                    return '- $ ' + Math.abs(value);
                                }
                                return '$ ' + value;
                            },
                            suggestedMin: 0,
                            suggestedMax: 200,
                            padding: 5,
                            fontFamily: 'Gibson, sans-serif',
                            fontSize: 11,
                            fontColor: '#0B102A'
                        }
                    }],
                    xAxes: [{
                        stacked: true,
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false
                        },
                        ticks: {
                            padding: 5,
                            fontFamily: 'Gibson, sans-serif',
                            fontSize: 11,
                            fontColor: '#0B102A'
                        }
                    }],
                },
            }
        };
    },
    mounted(){
        this.renderChart(this.chartData, this.options);
    }
};

