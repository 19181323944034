// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-v-7bb9c720] .guest-form {
  max-width: 410px;
}
@media only screen and (max-width : 768px) {
.vueGuestView[data-v-7bb9c720] {
    background: white;
}
}`, "",{"version":3,"sources":["webpack://./_guest.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;AADJ;AAIA;AACI;IACI,iBAAA;AADN;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n::v-deep .guest-form {\n    max-width: 410px;\n}\n\n@media #{$breakpointTabletMax} {\n    .vueGuestView {\n        background: white;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
