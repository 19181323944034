// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-success .close-btn[data-v-6e48a1fe] {
  cursor: pointer;
}
.fadeHeight-enter-active[data-v-6e48a1fe],
.fadeHeight-leave-active[data-v-6e48a1fe] {
  transition: all 0.5s ease-in-out;
  max-height: 2300px;
  overflow: hidden;
}
.fadeHeight-enter[data-v-6e48a1fe],
.fadeHeight-leave-to[data-v-6e48a1fe] {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./_app-message.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;AADR;AAKA;;EAEI,gCAAA;EACA,kBAAA;EACA,gBAAA;AAFJ;AAIA;;EAGI,UAAA;EACA,eAAA;EACA,gBAAA;AAFJ","sourcesContent":[".alert-success {\n\n    .close-btn {\n        cursor: pointer;\n    }\n\n}\n.fadeHeight-enter-active,\n.fadeHeight-leave-active {\n    transition: all 0.5s ease-in-out;\n    max-height: 2300px;\n    overflow: hidden;\n}\n.fadeHeight-enter,\n.fadeHeight-leave-to\n{\n    opacity: 0;\n    max-height: 0px;\n    overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
