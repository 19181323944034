export default {
    props: {
        incomeAccount: {
            type: Object,
        },
        saveAutomation: {
            type: Function,
        },
    },
    data,
    methods: getMethods(),
    watch: getWatchers(),
};

function data(){
    return {
        apiErrors: [],
        isModalShown: false,
        automation: {
            id: null,
            bankAccountId: null,
            amount: 0,
            isEnabled: false,
            isDirty: false,
        },
    };
}

function getWatchers(){
    return {
        isModalShown(newValue){
            if(newValue){
                const incomeOrganizeAutomation = this.incomeAccount.organize_automation;
                this.automation.bankAccountId = this.incomeAccount.id;
                if(incomeOrganizeAutomation){
                    this.automation.isEnabled = incomeOrganizeAutomation.is_enabled;
                    this.automation.amount = incomeOrganizeAutomation.amount;
                    this.automation.id = incomeOrganizeAutomation.id;
                }
            }
        }
    };
}

function getMethods(){
    return {
        show,
        onStoreAutomation,
        closeModal,
    };

    function show(){
        this.automation.isDirty = false;
        this.isModalShown = true;
        this.$refs.automationModal.show();
    }

    function onStoreAutomation(){
        const vm = this;
        const formData = {
            id: this.automation.id,
            bank_account_id: this.automation.bankAccountId,
            amount: this.automation.amount,
            is_enabled: this.automation.isEnabled,
        };
        Vue.appApi().authorized().organize().storeAutomation(formData)
            .then((response) => {
                vm.$emit('save-automation', response.data);
                vm.closeModal();
            })
            .catch(displayError);

        function displayError(error){
            if(error.appMessage){
                vm.apiErrors.push(error.appMessage);
            }
        }
    }

    function closeModal(){
        this.$refs.automationModal.hide();
        this.isModalShown = false;
    }
}

