export default {
    props: {
        allocationAccounts: {
            type: Array,
            required: true
        }
    },
    data,
    computed: getComputed(),
    methods: getMethods(),
    watch: getWatchers(),
};

function data(){
    return {
        editValues: {},
        errorMessages: [],
        validationErrors: {},
        isModalShown: false,
        isEditing: false,
        isMobileView: false,
    };
}

function getWatchers(){
    return {
        isModalShown(){
            const vm = this;
            if(vm.isModalShown){
                const newEditValues = {};
                vm.allocationAccounts.forEach(allocationAccount => {
                    newEditValues[allocationAccount.id] = allocationAccount.allocatePercentage || 0;
                });

                vm.editValues = newEditValues;
            }
        },
    };
}

function getComputed(){
    return {
        remainingPercentage,
    };

    function remainingPercentage(){
        const vm = this;
        const totalPercentage = vm.allocationAccounts.reduce((total, allocationAccount) => total + (parseFloat(vm.editValues[allocationAccount.id]) || 0), 0);
        return 100 - totalPercentage;
    }
}

function getMethods(){
    return {
        show,
        save,
        validatePercentage,
        onEnterPercentage,
        onGoBack,
        confirmUnsavedChanges,
        hideModal,
    };

    function show(){
        const vm = this;
        vm.$refs.allocatePercentageModal.show();
    }

    function validatePercentage(bankAccount, event){
        const vm = this;
        const remainingPercentage = vm.remainingPercentage;
        const accountIndex = vm.allocationAccounts.findIndex((account) => account.id === bankAccount.id);
        const currentValue = parseFloat(event.target.value) || 0;
        vm.allocationAccounts[accountIndex].isDirty = true;
        if(remainingPercentage < 0){
            vm.editValues[bankAccount.id] = Math.max(0, currentValue + remainingPercentage).toFixed(0);
        } else {
            vm.editValues[bankAccount.id] = currentValue.toFixed(0);
        }
    }

    function onEnterPercentage(event, bankAccountId){
        this.isEditing = true;
        const currentValue = parseFloat(event.target.value) || 0;
        this.editValues[bankAccountId] = currentValue;
    }

    function save(){
        const vm = this;
        vm.isEditing = false;
        vm.allocationAccounts.forEach(allocationAccount => {
            allocationAccount.allocatePercentage = vm.editValues[allocationAccount.id] || 0;
        });

        vm.$refs.allocatePercentageModal.hide();
        vm.$emit('allocate-percentage-success');
    }

    function onGoBack(){
        this.$refs.confirmUnsavedChangesModal.hide();
        this.$refs.allocatePercentageModal.show();
    }

    function confirmUnsavedChanges(event){
        const vm = this;
        if(vm.isEditing){
            event.preventDefault();

            return confirmCloseChanges().then(answerConfirmation);
        }

        function confirmCloseChanges(){
            const message = 'You have unsaved changes, are you sure you want to close?';
            const options = {
                size: 'sm',
                okVariant: 'light',
                cancelVariant: 'light',
                okTitle: 'Close',
                cancelTitle: 'Go back',
                hideHeader: true,
                bodyClass: 'text-center mb-0 mt-3',
                footerClass: 'd-flex justify-content-center border-0',
                centered: true
            };
            return vm.$bvModal.msgBoxConfirm(message, options);
        }

        function answerConfirmation(isConfirmed){
            if(isConfirmed){
                vm.hideModal();
            }
        }
    }

    function hideModal(){
        const vm = this;
        vm.isEditing = false;
        vm.isModalShown = false;
        vm.$refs.allocatePercentageModal.hide();
    }
}
