
      import API from "!../../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../node_modules/style-loader/dist/runtime/setAttributesWithAttributesAndNonce.js";
      import insertStyleElement from "!../../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-6.use[1]!../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../node_modules/resolve-url-loader/index.js??clonedRuleSet-6.use[2]!../../../../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-6.use[3]!./_handoff-messages.scss?vue&type=style&index=0&id=29c27bf1&prod&lang=scss&scoped=true&external";
      
      

var options = {"attributes":{"nonce":"8IBTHwOdqNKAWeKl7plt8g=="}};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-6.use[1]!../../../../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../node_modules/resolve-url-loader/index.js??clonedRuleSet-6.use[2]!../../../../../../node_modules/sass-loader/dist/cjs.js??clonedRuleSet-6.use[3]!./_handoff-messages.scss?vue&type=style&index=0&id=29c27bf1&prod&lang=scss&scoped=true&external";
       export default content && content.locals ? content.locals : undefined;
