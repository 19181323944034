import { VueRecaptcha } from 'vue-recaptcha';
import Dashboard from './components/dashboard/dashboard.vue';
import AccountForm from './components/account-form/account-form.vue';
import PasswordForm from './components/password-form/password-form.vue';

export default {
    name: 'UserRegister',
    components: {
        VueRecaptcha,
        Dashboard,
        AccountForm,
        PasswordForm,
    },
    data: () => {
        return {
            credentials: {
                first_name: null,
                last_name: null,
                email: null,
                password: null,
                password_confirmation: null,
                acceptance_status: false,
            },
            apiErrors: [],
            validationErrors: {},
            registeringUser: false,
            clientPlatform: window.appEnv.clientPlatform || 'web',
            recaptcha: {
                disabled: window.appEnv.recaptchaDisabled,
                siteKey: window.appEnv.recaptchaSiteKey,
                verified: false
            },
            currentDisplay: 'dashboard',
            isMobileView: false,
        };
    },
    mounted(){
        this.$store.commit('user/register/SET_REFERRAL_CODE', this.$route.query.ref || '');
    },
    created(){
        this.checkIsMobileView();
        window.addEventListener('resize', this.checkIsMobileView);
    },
    beforeDestroy(){
        window.removeEventListener('resize', this.checkIsMobileView);
    },
    methods: {
        attemptRegisterUser(data){
            this.registeringUser = true;
            this.apiErrors = [];
            this.validationErrors = {};
            this.credentials = data;

            const payload = {
                ...this.credentials,
                ...data,
                name: `${data.first_name} ${data.last_name}`,
            };

            this.$store.dispatch('user/register/REGISTER_USER', payload)
                .then(() => {
                    const router = this.$router;
                    const queryParameter = this.$route.query;
                    const redirectedFromAcceptInvitation = router.redirectedFrom &&
                        router.redirectedFrom.name === 'accept-invite';

                    if(queryParameter.fpr && queryParameter.fpr !== '' && window.appEnv.clientPlatform === 'web'){
                        sendEmailToFirstPromoter(payload.email);
                    }
                    if(redirectedFromAcceptInvitation){
                        router.redirectAfterLogin();
                        return;
                    }

                    router.redirectAfterLogin({ name: 'dashboard' });
                })
                .catch((response) => {
                    this.apiErrors.push(response.data.message || response.appMessage);
                    this.validationErrors = response.data.errors || {};
                })
                .finally(() => {
                    this.registeringUser = false;
                });

            async function sendEmailToFirstPromoter(email){
                window.fpr('referral', { email: email });
            }
        },
        onSubmitUserInformation(){
            const vm = this;
            const userInformation = {
                first_name: vm.credentials.first_name,
                last_name: vm.credentials.last_name,
                email: vm.credentials.email,
            };
            vm.$store.dispatch('user/register/VALIDATE_USER_INFORMATION', userInformation)
                .then(() => {
                    vm.currentDisplay = 'password-form';
                    vm.apiErrors = [];
                    vm.validationErrors = {};
                })
                .catch((response) => {
                    this.apiErrors.push(response.data.message || response.appMessage);
                    this.validationErrors = response.data.errors || {};
                })
                .finally(() => {
                    this.registeringUser = false;
                });
        },
        checkIsMobileView(){
            this.isMobileView = window.innerWidth <= 768;
        }
    },
};
