// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inAppPurchaseModalComponent h2[data-v-3565b5d3] {
  font-size: 30px;
}
.inAppPurchaseModalComponent h3[data-v-3565b5d3] {
  font-size: 20px;
}
.inAppPurchaseModalComponent p[data-v-3565b5d3] {
  font-size: 16px;
}
[data-v-3565b5d3] .inAppPurchaseModalComponent.fullScreen {
  max-width: 100%;
  max-height: 100%;
  height: 100vh;
  margin: 0;
}
[data-v-3565b5d3] .inAppPurchaseModalComponent.fullScreen .modal-content {
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}
[data-v-3565b5d3] .inAppPurchaseModalComponent.fullScreen .modal-content .modal-body {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  margin-bottom: 30px;
}
@media only screen and (max-width : 768px) {
[data-v-3565b5d3] .inAppPurchaseModalComponent.fullScreen .modal-content {
    padding: 0;
}
[data-v-3565b5d3] .inAppPurchaseModalComponent.fullScreen .modal-content .modal-body {
    width: 100%;
}
}`, "",{"version":3,"sources":["webpack://./_in-app-purchase.scss"],"names":[],"mappings":"AAGI;EACI,eAAA;AAFR;AAKI;EACI,eAAA;AAHR;AAMI;EACI,eAAA;AAJR;AASI;EACI,eAAA;EACA,gBAAA;EACA,aAAA;EACA,SAAA;AANR;AAQQ;EACI,aAAA;EACA,cAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;AANZ;AAQY;EACI,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,mBAAA;AANhB;AASY;AAnBJ;IAoBQ,UAAA;AANd;AAQc;IACI,WAAA;AANlB;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.inAppPurchaseModalComponent {\n    h2 {\n        font-size: 30px;\n    }\n\n    h3 {\n        font-size: 20px;\n    }\n\n    p {\n        font-size: 16px;\n    }\n}\n\n::v-deep {\n    .inAppPurchaseModalComponent.fullScreen {\n        max-width: 100%;\n        max-height: 100%;\n        height: 100vh;\n        margin: 0;\n\n        .modal-content {\n            height: 100vh;\n            overflow: auto;\n            align-items: center;\n            justify-content: center;\n            border: none;\n            border-radius: 0;\n            padding-top: 100px;\n            padding-bottom: 100px;\n\n            .modal-body {\n                padding: 0;\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                flex: none;\n                margin-bottom: 30px;\n            }\n\n            @media #{$breakpointTabletMax} {\n                padding: 0;\n\n                .modal-body {\n                    width: 100%;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
