import { render, staticRenderFns } from "./split-transaction-modal.vue?vue&type=template&id=aba9511c&scoped=true"
import script from "./split-transaction-modal.controller.js?vue&type=script&lang=js&external"
export * from "./split-transaction-modal.controller.js?vue&type=script&lang=js&external"
import style0 from "./_split-transaction-modal.scss?vue&type=style&index=0&id=aba9511c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aba9511c",
  null
  
)

export default component.exports