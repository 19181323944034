// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plaid-link-button[data-v-d4615344] {
  border: none;
  font-size: 18px;
  padding-left: 6rem;
  padding-right: 6rem;
  display: block;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  width: 350px;
}
@media only screen and (max-width : 768px) {
.plaid-link-button[data-v-d4615344] {
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
}
}`, "",{"version":3,"sources":["webpack://./_plaid-link.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,qBAAA;EACA,kBAAA;EACA,YAAA;AADF;AAGE;AAVF;IAWI,kBAAA;IACA,mBAAA;IACA,WAAA;AAAF;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.plaid-link-button {\n  border: none;\n  font-size: 18px;\n  padding-left: 6rem;\n  padding-right: 6rem;\n  display: block;\n  margin-bottom: 1.5rem;\n  margin-top: 1.5rem;\n  width: 350px;\n\n  @media #{$breakpointTabletMax} {\n    padding-left: 1rem;\n    padding-right: 1rem;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
