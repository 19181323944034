export default {
    data: data,
    computed: getComputed(),
    mounted,
    methods: getMethods(),
};

function data(){
    return {
        showHandoffMessages: false,
        messageIndex: 0,
    };
}

function getComputed(){
    return {
    };
}

function mounted(){
    this.showHandoffMessages = this.$store.state.guest.user.user.show_handoff_messages;
}

function getMethods(){
    return {
        showNextMessage,
        showPrevMessage,
        showMessage,
        closeMessage,
    };

    function showNextMessage(){
        this.messageIndex = (this.messageIndex + 1) % 4;
    }

    function showPrevMessage(){
        this.messageIndex = Math.max(0, this.messageIndex - 1);
    }

    function showMessage(index){
        this.messageIndex = index;
    }

    function closeMessage(){
        this.showHandoffMessages = false;
        return Vue.appApi().authorized().user().updateHandoffMessageStatus(false);
    }
}
