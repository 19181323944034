import Vue from 'vue';
import selectDateRange from 'vue_root/mixins/selectDateRange.mixin.js';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

export default {
    mixins: [
        selectDateRange,
    ],
    data,
    created,
    watch: getWatcher(),
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        selectedDateRange: null,
        startDate: Vue.moment(),
        endDate: Vue.moment(),
        incomeAccount: {},
        checkingSubAccounts: [],
        errorMessages: [],
        validationErrors: {},
        downloadingPDF: false,
        isIOS: window.appEnv.clientPlatform === 'ios',
    };
}

function created(){
    const defaultDateRange = this.$route.query.date_range;
    this.selectedDateRange = defaultDateRange !== undefined ? defaultDateRange : this.dateRangeOptions[0];
}

function getWatcher(){
    return {
        selectedDateRange(){
            const vm = this;
            const dateRange = vm.getDatesByOptionValue(vm.selectedDateRange);
            if(dateRange){
                vm.startDate = dateRange[0].toDate();
                vm.endDate = dateRange[1].toDate();
            }

            vm.fetchAssignmentsAggregate();
        },
        '$store.state.authorized.bankAccounts.bankAccounts'(){
            this.fetchAssignmentsAggregate();
        },
    };
}

function getComputed(){
    return {
        userName(){
            return this.$store.state.guest.user.user.name;
        },
        selectedExpensesAmount(){
            return this.checkingSubAccounts.reduce((total, subAccount) => subAccount.showBucket ? subAccount.amountAssigned + total : total, 0);
        },
        summaryAmount(){
            const incomeAmount = this.incomeAccount.showBucket ? this.incomeAccount.amountAssigned : 0;
            const summaryAmount = incomeAmount + this.selectedExpensesAmount;
            if(summaryAmount < 0){
                return {
                    label: 'Loss',
                    amount: summaryAmount,
                };
            } else if(summaryAmount > 0){
                return {
                    label: 'Profit',
                    amount: summaryAmount,
                };
            } else {
                return null;
            }
        },
        allBankAccounts(){
            return [this.incomeAccount, ...this.checkingSubAccounts];
        },
    };
}

function getMethods(){
    return {
        fetchAssignmentsAggregate,
        displayErrorMessage,
        resetAccounts,
        setBankAccountAmountAssigned,
        getPercentAmongTotalAssignments,
        downloadPDF,
    };

    function fetchAssignmentsAggregate(){
        const vm = this;
        vm.resetAccounts();
        const bankAccountIds = vm.allBankAccounts.map(subAccount => subAccount.id);

        const query = {
            start_date: Vue.moment(vm.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(vm.endDate).format('YYYY-MM-DD'),
            bank_account_ids: bankAccountIds
        };

        vm.loadingAssignments = true;
        return Vue.appApi().authorized().account().reports().getAssignmentsAggregate(query)
            .then(setAssignments)
            .catch(handleLoadingError)
            .finally(resetLoadingState);

        function setAssignments({ data }){
            vm.aggregateData = data;
            vm.allBankAccounts.forEach(vm.setBankAccountAmountAssigned);
        }

        function handleLoadingError(error){
            vm.assignments = [];
            vm.displayErrorMessage(error);
        }

        function resetLoadingState(){
            vm.loadingAssignments = false;
        }
    }

    function resetAccounts(){
        const vm = this;
        resetIncomeAccount();
        resetCheckingSubAccounts();

        function resetIncomeAccount(){
            const localCopyOfIncomeAccount = Vue.dymUtilities.cloneObject(vm.$store.state.authorized.bankAccounts.bankAccounts)
                .filter(bankAccount => bankAccount.purpose === 'income')[0];
            if(!localCopyOfIncomeAccount){
                vm.incomeAccount = {};
                return;
            }

            localCopyOfIncomeAccount.amountAssigned = 0;
            localCopyOfIncomeAccount.showBucket = true;

            vm.incomeAccount = localCopyOfIncomeAccount;
        }

        function resetCheckingSubAccounts(){
            const primaryCheckingAccount = vm.$store.state.authorized.bankAccounts.bankAccounts.filter(bankAccount => bankAccount.slug === 'primary_checking')[0];
            if(!primaryCheckingAccount){
                vm.checkingSubAccounts = [];
                return;
            }

            const hiddenPurpose = ['cc_payoff', 'income'];
            const localCopyOfCheckingSubAccounts = Vue.dymUtilities.cloneObject(primaryCheckingAccount.sub_accounts.filter(subAccount => !hiddenPurpose.includes(subAccount.purpose)));
            vm.checkingSubAccounts = localCopyOfCheckingSubAccounts.map(subAccount => {
                subAccount.amountAssigned = 0;
                subAccount.showBucket = true;
                return subAccount;
            });
        }
    }

    function setBankAccountAmountAssigned(bankAccount){
        const vm = this;
        if(bankAccount && vm.aggregateData && vm.aggregateData.data[bankAccount.id]){
            bankAccount.amountAssigned = vm.aggregateData.data[bankAccount.id]?.total || 0;
        }
    }

    function getPercentAmongTotalAssignments(bankAccount){
        const vm = this;
        const totalAbsExpenseAmount = vm.checkingSubAccounts.reduce((total, subAccount) => Math.abs(subAccount.amountAssigned) + total, 0);
        if(totalAbsExpenseAmount <= 0){
            return 0;
        }

        const percent = Math.abs(bankAccount.amountAssigned) * 100 / totalAbsExpenseAmount;
        return percent.toFixed(0);
    }

    function displayErrorMessage(error){
        const vm = this;
        const isValidationError = error && error.status === 422 && error.data.errors;
        if(isValidationError){
            vm.validationErrors = error.data.errors;
        } else {
            const errorMessage = typeof error === 'string' ? error : (error.appMessage || error.message);
            vm.errorMessages.push(errorMessage);
        }
    }

    function downloadPDF(){
        const vm = this;
        const showAccounts = this.checkingSubAccounts.filter((account) => account.showBucket).map((account) => account.id);
        const queryParameter = {
            start_date: Vue.moment(this.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(this.endDate).format('YYYY-MM-DD'),
            show_income: this.incomeAccount.showBucket,
            sub_accounts: showAccounts,
        };

        vm.downloadingPDF = true;
        Vue.appApi().authorized().bankAccount().downloadPDFAssignableAccounts(queryParameter).then(downloadFile);

        async function downloadFile(response){
            if(vm.isIOS){
                const blob = new Blob([response.data], { type: 'application/pdf' });
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async function(){
                    var base64data = reader.result;
                    const filename = 'Proft and Loss Statement.pdf';
                    await Filesystem.writeFile({
                        path: filename,
                        data: base64data,
                        directory: Directory.Documents,
                    });

                    const { uri } = await Filesystem.getUri({
                        path: filename,
                        directory: Directory.Documents,
                    });

                    const fileOpenerOptions = {
                        filePath: uri,
                        contentType: 'application/pdf',
                        openWithDefault: true,
                    };
                    await FileOpener.open(fileOpenerOptions);

                    vm.downloadingPDF = false;
                };
            } else {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'Proft and Loss Statement.pdf';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                vm.downloadingPDF = false;
            }
        }
    }
}
