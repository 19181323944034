import { render, staticRenderFns } from "./notifications.vue?vue&type=template&id=b52dc29a&scoped=true"
import script from "./notifications.controller.js?vue&type=script&lang=js&external"
export * from "./notifications.controller.js?vue&type=script&lang=js&external"
import style0 from "./_notifications.scss?vue&type=style&index=0&id=b52dc29a&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b52dc29a",
  null
  
)

export default component.exports