// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculatorPopoverComponent[data-v-75b36b01] .popover {
  white-space: nowrap;
}
.calculatorPopoverComponent[data-v-75b36b01] .popover .arrow {
  display: none;
}
.calculatorPopoverComponent[data-v-75b36b01] .total {
  font-weight: 600;
}
.calculatorPopoverComponent[data-v-75b36b01] .bank-account-icon::before {
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./_calculator-popover.scss"],"names":[],"mappings":"AAGQ;EACI,mBAAA;AAFZ;AAIY;EACI,aAAA;AAFhB;AAKQ;EACI,gBAAA;AAHZ;AAMQ;EACI,cAAA;EACA,eAAA;AAJZ","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n.calculatorPopoverComponent {\n    ::v-deep {\n        .popover {\n            white-space: nowrap;\n\n            .arrow {\n                display: none;\n            }\n        }\n        .total {\n            font-weight: 600;\n        }\n\n        .bank-account-icon::before {\n            margin-left: 0;\n            margin-right: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
