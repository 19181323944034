// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verificationRequiredWarning .fa-times[data-v-58aead28] {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./_verification-required.scss"],"names":[],"mappings":"AAEI;EACI,eAAA;AADR","sourcesContent":[".verificationRequiredWarning {\n    @import \"~vue_root/assets/scss/_variables.scss\";\n    .fa-times {\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
