
export default {
    namespaced: true,
    state: {
        isLoading: false,
        isLoaded: false,
        errorMessage: null,
        data: [],
    },
    actions: {
        GET_DATA: function({ commit, state }, force = false){
            if(!force && (state.isLoading || state.isLoaded)){
                return Promise.resolve();
            }

            commit('SET_IS_LOADING', true);

            return Vue.appApi()
                .authorized()
                .bankAccount()
                .getCreditCardOverviews()
                .then((response) => {
                    commit('SET_DATA', response.data);
                    commit('SET_IS_LOADED', true);
                })
                .catch((error) => {
                    commit('SET_ERROR_MESSAGES', error.appMessage);
                })
                .finally(() => {
                    commit('SET_IS_LOADING', false);
                });
        },
    },
    mutations: {
        SET_DATA: function(state, payload){
            state.data = payload;
        },
        SET_ERROR_MESSAGES: function(state, payload){
            state.errorMessage = payload;
        },
        SET_IS_LOADING: function(state, payload){
            state.isLoadingCreditCards = payload;
        },
        SET_IS_LOADED: function(state, payload){
            state.isLoaded = payload;
        },
    },
};

