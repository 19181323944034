export default {
    data(){
        return {
            dateRangeOptions: [
                'Last 30 Days',
                'This Month',
                'Last Month',
                'Last 6 months',
                'This Year',
                'Last Year',
                'Custom'
            ],
        };
    },
    methods: {
        getDatesByOptionValue,
    }
};

function getDatesByOptionValue(option){
    if(option === 'Last 30 Days'){
        return [Vue.moment().subtract(30, 'days'), Vue.moment()];
    } else if(option === 'This Month'){
        return [Vue.moment().startOf('month'), Vue.moment()];
    } else if(option === 'Last Month'){
        return [Vue.moment().subtract(1, 'months').startOf('month'), Vue.moment().subtract(1, 'months').endOf('month')];
    } else if(option === 'Last 6 months'){
        return [Vue.moment().subtract(6, 'months').startOf('month'), Vue.moment()];
    } else if(option === 'This Year'){
        return [Vue.moment().startOf('year'), Vue.moment()];
    } else if(option === 'Last Year'){
        return [Vue.moment().subtract(1, 'years').startOf('year'), Vue.moment().subtract(1, 'years').endOf('year')];
    }

    return null;
}
