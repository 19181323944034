// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canel-modal .cancel-modal__title[data-v-1115d243] {
  font-weight: 600;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./_cancel-modal.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;EACA,mBAAA;AADR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n.canel-modal {\n    .cancel-modal__title {\n        font-weight: 600;\n        margin-bottom: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
