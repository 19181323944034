import subscriptionVerificationMixin from 'vue_root/mixins/subscriptionVerification.mixin';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin';
import CanUserMixin from 'vue_root/mixins/can-user.mixin.js';
import DeleteInstitutionModal from './delete-institution-modal/delete-institution-modal.vue';
import ConfirmDeleteModal from './confirm-delete-modal/confirm-delete-modal.vue';
import ConfirmRemoveModal from './confirm-remove-modal/confirm-remove-modal.vue';
import ConfirmPurposeChangeModal from './confirm-purpose-change-modal/confirm-purpose-change-modal.vue';
import BankConnectionErrorIcon from 'vue_root/components/bank-connection-error-icon/bank-connection-error-icon.vue';
import PlaidLink from 'vue_root/authorized/subscriber/components/plaid-link/plaid-link.vue';
import eventBus from 'vue_root/plugins/eventBus.js';
import draggable from 'vuedraggable';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

export default {
    components: {
        DeleteInstitutionModal,
        ConfirmDeleteModal,
        ConfirmRemoveModal,
        ConfirmPurposeChangeModal,
        BankConnectionErrorIcon,
        PlaidLink,
        draggable,
    },
    mixins: [subscriptionVerificationMixin, sortBankAccounts, CanUserMixin, formatAsDecimal],
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    mounted: mounted,
    beforeDestroy: beforeDestroy,
    created: created,
    beforeRouteLeave: confirmNavigation,
    methods: getMethods()
};

function data(){
    return {
        errorMessages: [],
        bankAccounts: [],
        addedBankAccounts: [],
        clientPlatform: window.appEnv.clientPlatform || 'web',
        bankAccountToEdit: null,
        isBulkSaving: false,
        accountPurposesWithoutScheduleItems: ['income', 'cc_payoff', 'credit', 'none'],
        downloadTransaction: {
            bankAccount: null,
            startDate: null,
            endDate: null,
            sendingRequest: false,
            errors: []
        },
        attachAccount: {
            sendingRequest: false,
            unlinkedAccount: null,
            targetAccount: null,
            checked: {},
            errors: []
        },
        isIOS: window.appEnv.clientPlatform === 'ios',
    };
}

function getComputed(){
    return {
        accountList,
        dirtyBankAccounts,
        loadingBankAccounts,
        canManagePlaidAccounts
    };

    function accountList(){
        return this.bankAccounts
            .filter(({ appears_in_account_list }) => appears_in_account_list)
            .concat(this.addedBankAccounts);
    }

    function dirtyBankAccounts(){
        const vm = this;
        return vm.accountList.filter(({ isDirty }) => isDirty);
    }

    function loadingBankAccounts(){
        const vm = this;
        return vm.$store.state.authorized.bankAccounts.isFetchingBankAccounts;
    }

    function canManagePlaidAccounts(){
        return this.verifySubscriptionPlan('plus') && this.canUser('manage plaid-accounts') && !this.$store.getters['user/isLoading'];
    }
}

function getWatchers(){
    return {
        loadingBankAccounts(newStatus, oldStatus){
            if(oldStatus && !newStatus){
                this.refreshBankAccountsList(true);
            }
        },
        '$route'(to){
            this.openPlaidUpdateMode(to);
        },
    };
}

function created(){
    addCryptoLib();

    this.initializeView().then(() => this.openPlaidUpdateMode(this.$route));

    function addCryptoLib(){
        const scriptUrlCore = 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/core.js';
        const scriptUrlLib = 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/md5.js';
        const isCryptoLibLoaded = checkIsCryptoLibLoaded();

        if(!isCryptoLibLoaded){
            addScript(scriptUrlCore, () => addScript(scriptUrlLib));
        }

        function checkIsCryptoLibLoaded(){
            for(var i = 0; i < document.scripts.length; i++){
                if(document.scripts[i].src === scriptUrlLib){
                    return true;
                }
            }
            return false;
        }

        function addScript(src, callback){
            var s = document.createElement('script');
            s.setAttribute('src', src);
            if(callback){
                s.onload = callback;
            }
            document.head.appendChild(s);
        }
    }
}

function mounted(){
    eventBus.$on('bank-accounts-refresh', this.refreshBankAccountsList);
}

function beforeDestroy(){
    eventBus.$off('bank-accounts-refresh', this.refreshBankAccountsList);
}

function confirmNavigation(to, from, next){
    const vm = this;
    const hasUnsavedChanges = vm.dirtyBankAccounts.length;

    if(hasUnsavedChanges){
        const modalOptions = {
            okVariant: 'muted-success',
            okTitle: 'Save all',
            cancelTitle: 'Leave page',
            bodyClass: 'text-center pt-5 pb-4 font-weight-semibold text-dark',
            footerClass: 'pb-5 justify-content-center border-0 flex-row-reverse',
            hideHeaderClose: false,
            cancelVariant: 'light',
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
        };
        vm.$bvModal.msgBoxConfirm('You have unsaved changes.', modalOptions)
            .then(handleConfirmation)
            .then(next);
    } else {
        next();
    }

    function handleConfirmation(saveAll){
        if(saveAll){
            return vm.saveAllChanges();
        }
    }
}

function getMethods(){
    return {
        refreshBankAccountsList,
        refreshSubAccounts,
        sortBankAccounts,
        setBankAccountDisplayProperties,
        confirmDelete,
        confirmRemove,
        addNewAccount,
        deleteBankAccount,
        openPlaidUpdateMode,
        displayError,
        initializeView,
        updateBankAccount,
        saveAllChanges,
        updateBankAccountPurpose,
        addBankAccountStructure,
        removeAccountsForInstitution,
        openEditAccountModal,
        openDownloadTransactionsModal,
        downloadTransactionWithDateRange,
        saveEditAccountInfo,
        removeSubAccount,
        addSubAccount,
        cancelAccountBalanceEdits,
        overrideAccountBalance,
        attachLinkedAccount,
        onSelectAccountToAttach,
        confirmAttachLinkedAccount,
        checkIfPrimaryCheckingExists,
        unattachLinkedAccount,
        isAttachedAccountStructure,
        accountPurposeOptions,
        getUnassignedBalance,
        findCreditCardAccount,
        upgradeSubscription,
        paymentSubmitted,
        openPlaidLink,
    };

    function refreshBankAccountsList(reset = false){
        const vm = this;
        const localCopyOfBankAccounts = JSON.parse(JSON.stringify(vm.$store.state.authorized.bankAccounts.bankAccounts));
        localCopyOfBankAccounts.forEach(vm.setBankAccountDisplayProperties);
        if(reset){
            vm.bankAccounts = localCopyOfBankAccounts;
            vm.addedBankAccounts = [];
        } else {
            localCopyOfBankAccounts.forEach(updateDisplayedAccountList);
        }
        vm.sortBankAccounts();
        vm.bankAccounts.forEach(vm.refreshSubAccounts);
        return Promise.resolve();
        function updateDisplayedAccountList(bankAccount){
            const workingCopy = vm.bankAccounts.find(({ id }) => id === +bankAccount.id);
            if(!workingCopy){
                vm.bankAccounts.push(bankAccount);
            } else if(!workingCopy.isDirty){
                Object.assign(workingCopy, bankAccount);
            }
        }
    }

    function refreshSubAccounts(bankAccount){
        const vm = this;
        const unalteredBankAccount = vm.$store.getters['authorized/bankAccounts/getBankAccountById'](bankAccount.id);
        if(unalteredBankAccount){
            Object.assign(bankAccount, unalteredBankAccount);
        }
        bankAccount.isDirty = false;
        const subAccounts = bankAccount.sub_accounts.map(getLoadedSubAccount).filter(subAccount => subAccount).sort(vm.byModifiedStoreOrder);
        vm.$set(bankAccount, 'sub_accounts', subAccounts);
        function getLoadedSubAccount(subAccount){
            let unalteredSubAccount = vm.$store.getters['authorized/bankAccounts/getBankAccountById'](subAccount.id);
            if(unalteredSubAccount){
                unalteredSubAccount = JSON.parse(JSON.stringify(unalteredSubAccount));
                vm.setBankAccountDisplayProperties(unalteredSubAccount);
            }
            return unalteredSubAccount || null;
        }
    }

    function sortBankAccounts(){
        const vm = this;
        vm.bankAccounts = vm.bankAccounts.sort((a, b) => {
            const accountPurposeOrder = [
                'unassigned',
                'primary_checking',
                'income',
                'bills',
                'primary_savings',
                'savings',
                'cc_payoff',
                'credit',
                'none'
            ];
            const checkedPurposes = [];
            let isHigher = false;
            accountPurposeOrder.forEach(purpose => {
                isHigher = isHigher || a.purpose === purpose && !checkedPurposes.includes(b.purpose);
                if(!isHigher){
                    checkedPurposes.push(purpose);
                }
            });
            if(a.purpose === b.purpose){
                isHigher = a.created_at < b.created_at;
            }
            return isHigher ? -1 : 0;
        });
    }

    function setBankAccountDisplayProperties(bankAccount, defaults = {}){
        const vm = this;
        vm.$set(bankAccount, 'isLoading', (defaults.isLoading || false));
        vm.$set(bankAccount, 'isSaving', (defaults.isSaving || false));
        vm.$set(bankAccount, 'isDeleting', (defaults.isDeleting || false));
        vm.$set(bankAccount, 'isDirty', (defaults.isDirty || false));
        vm.$set(bankAccount, 'balanceError', (defaults.balanceError || ''));
        vm.$set(bankAccount, 'newBucketBalance', (defaults.newBucketBalance || bankAccount.balance_available));
        vm.$set(bankAccount, 'isEditingCurrentAccountBalance', (defaults.isEditingCurrentAccountBalance || false));
        vm.$set(bankAccount, 'isEditingSubAccountBalances', (defaults.isEditingSubAccountBalances || false));
        vm.$set(bankAccount, 'canCurrentUserManage', !bankAccount.institution_account || vm.canManagePlaidAccounts);
        vm.$set(bankAccount, 'isCanReOrder', (defaults.isCanReOrder || false));
    }

    function confirmDelete(bankAccount){
        const vm = this;
        if(!bankAccount.id){
            const index = vm.bankAccounts.indexOf(bankAccount);
            vm.bankAccounts.splice(index, 1);
        } else {
            vm.$refs.confirmDeleteModal.openModal(bankAccount);
        }
    }

    function confirmRemove(bankAccount){
        const vm = this;
        vm.$refs.confirmRemoveModal.openModal(bankAccount);
    }

    function addNewAccount(institutionCredential){
        this.$refs.plaidLink.openPlaidConnectUpdateModeForNewAccounts(institutionCredential.id);
    }

    function deleteBankAccount(bankAccount){
        const vm = this;
        bankAccount.isDeleting = true;
        const deletePromise = vm.$store.dispatch('authorized/bankAccounts/DELETE_BANK_ACCOUNT', bankAccount.id);

        return deletePromise.then(removeFromAccontList).catch(handleDeleteError).finally(resetLoadingState);

        function removeFromAccontList(){
            const accountIndex = vm.bankAccounts.findIndex(({ id }) => id === bankAccount.id);
            if(accountIndex >= 0){
                vm.bankAccounts.splice(accountIndex, 1);
            }
            if(bankAccount.parent_bank_account_id){
                const parentBankAccount = vm.bankAccounts.find(({ id }) => id === bankAccount.parent_bank_account_id);
                if(parentBankAccount){
                    const subAccountIndex = parentBankAccount.sub_accounts.findIndex(({ id }) => id === bankAccount.id);
                    if(subAccountIndex >= 0){
                        parentBankAccount.sub_accounts.splice(subAccountIndex, 1);
                        const mainSubAccount = parentBankAccount.sub_accounts.find(sub_account => sub_account.sub_account_order === 0);
                        mainSubAccount.balance_current = new Decimal(mainSubAccount.balance_current || 0)
                            .plus(bankAccount.balance_current || 0)
                            .toDecimalPlaces(2)
                            .toNumber();
                    }
                }
            }
        }

        function handleDeleteError(error){
            if(error.data && error.data.slug === 'finicity_oauth_delete'){
                vm.$refs.deleteInstitutionModal.openModal(bankAccount.institution_account.institution);
            } else if(error.appMessage){
                vm.errorMessages.push(error.appMessage);
            }
        }
        function resetLoadingState(){
            bankAccount.isDeleting = false;
        }
    }

    function displayError(errorMessage){
        this.errorMessages = [errorMessage];
    }

    function openPlaidUpdateMode(route){
        if(route.query.settings === 'open' && route.query.institution_credential_id){
            const institutionCredentialId = Number(route.query.institution_credential_id);

            this.$refs.plaidLink.openPlaidConnectUpdateMode(institutionCredentialId);

            this.$router.replace({ query: null });

            return;
        }

        if(route.query.settings === 'migrating-to-plaid' && route.query.institution_credential_id){
            const institutionCredentialId = Number(route.query.institution_credential_id);

            this.$refs.plaidLink.openPlaidConnect(institutionCredentialId);

            this.$router.replace({ query: null });

            return;
        }

        if(route.query.openConnectModal === true){
            this.$router.replace({ query: null });

            this.$refs.plaidLink.openPlaidConnect();
        }
    }

    function initializeView(){
        return this.refreshBankAccountsList();
    }

    function updateBankAccount(bankAccount){
        if(!bankAccount){
            return;
        }

        const vm = this;
        if(bankAccount.id){
            const localCopy = vm.bankAccounts.find(({ id }) => id === bankAccount.id);
            Object.assign(localCopy, bankAccount);
            bankAccount = localCopy;
        }
        const subAccounts = JSON.parse(JSON.stringify(bankAccount.sub_accounts || []));
        subAccounts.forEach(updateOrderSubAccounts);
        bankAccount.isSaving = true;
        vm.errorMessages = [];
        vm.validationErrors = {};
        return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', bankAccount)
            .then(updateBankAccountSuccess)
            .catch(updateBankAccountFailure)
            .finally(resetLoadingState);

        function updateBankAccountSuccess(response){
            const bankAccountId = +response.data.id;
            const saveSubAccountPromises = subAccounts.map(saveSubAccount);
            return Promise.all(saveSubAccountPromises).then(updateLocalBankAccount);

            function saveSubAccount(subAccount, index){
                subAccount.parent_bank_account_id = bankAccountId;
                const isMainSubAccount = subAccount.sub_account_order === 0;
                subAccount.sub_account_order = isMainSubAccount ? subAccount.sub_account_order : Math.max(index, 1);
                const balanceUpdated = subAccount.isDirty && subAccount.newBucketBalance !== subAccount.balance_available;
                if(balanceUpdated){
                    subAccount.balance_current = subAccount.balance_current + (new Decimal(subAccount.newBucketBalance) - subAccount.balance_available);
                }

                const payload = JSON.parse(JSON.stringify(subAccount));
                return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', payload).then(updateSubAccount);
                function updateSubAccount(response){
                    vm.setBankAccountDisplayProperties(subAccount);
                    Object.assign(subAccount, JSON.parse(JSON.stringify(response.data)));
                }
            }
            function updateLocalBankAccount(){
                if(!bankAccount.id){
                    const addedBankAccountIndex = vm.addedBankAccounts.indexOf(bankAccount);
                    if(addedBankAccountIndex >= 0){
                        vm.addedBankAccounts.splice(bankAccountIndex, 1);
                    }
                    const bankAccountIndex = vm.bankAccounts.findIndex(({ id }) => id === bankAccountId);
                    if(bankAccountIndex < 0){
                        vm.bankAccounts.push(response.data);
                    }
                    vm.sortBankAccounts();
                }
                const localCopy = vm.bankAccounts.find(({ id }) => id === response.data.id);
                Object.assign(localCopy, response.data);
                vm.setBankAccountDisplayProperties(localCopy);
                vm.refreshSubAccounts(localCopy);
            }
        }

        function updateBankAccountFailure(error){
            const errorMessage = error.appMessage || (error.data && error.data.message);
            if(errorMessage){
                vm.errorMessages.push(errorMessage);
            }
        }

        function resetLoadingState(){
            bankAccount.isSaving = false;
        }

        function updateOrderSubAccounts(account, order){
            account.sub_account_order = order;
        }
    }

    function saveAllChanges(){
        const vm = this;
        vm.isBulkSaving = true;
        const savingAccounts = vm.dirtyBankAccounts.filter(({ isSaving }) => !isSaving);
        const primaryCheckingAccount = savingAccounts.filter(({ purpose }) => purpose === 'primary_checking')[0];
        const saveOtherPromises = savingAccounts.filter(({ purpose }) => purpose !== 'primary_checking').map(vm.updateBankAccount);
        return Promise.all(saveOtherPromises)
            .then(() => vm.updateBankAccount(primaryCheckingAccount))
            .then(() => {
                vm.bankAccounts.splice(0);
                vm.addedBankAccounts.splice(0);
                vm.$nextTick(() => {
                    vm.refreshBankAccountsList(true);
                });
            }).finally(resetLoadingState);
        function resetLoadingState(){
            vm.isBulkSaving = false;
        }
    }

    function updateBankAccountPurpose(bankAccount, purpose){
        const vm = this;
        const originalPurpose = bankAccount.purpose;
        confirmPurposeChange().then(changePurpose).catch(cancelChange);

        function confirmPurposeChange(){
            const purposesRequiringConfirmation = ['primary_checking'];
            if(purposesRequiringConfirmation.includes(originalPurpose)){
                return promptForConfirmation();
            } else {
                return Promise.resolve();
            }
            function promptForConfirmation(){
                return vm.$refs.confirmPurposeChangeModal.openModal(originalPurpose);
            }
        }

        function changePurpose(){
            bankAccount.purpose = purpose;
            const option = vm.accountPurposeOptions().find(({ value }) => value === purpose);
            if(option){
                bankAccount.name = option.defaultName;
            }
            bankAccount.icon = purpose === 'credit' ? 'credit-card' : 'square';
            if(['primary_checking', 'primary_savings'].includes(purpose)){
                populateSubAccounts();
            } else {
                bankAccount.sub_accounts = [];
                setBankAccountColor(bankAccount);
            }
            bankAccount.isDirty = true;

            function setBankAccountColor(bankAccount){
                const colorsByPurpose = {
                    savings: [
                        'violet',
                        'orange',
                        'cyan',
                        'yellow',
                        'purple',
                    ],
                    credit: [
                        'gold',
                        'silver',
                        'bronze',
                    ],
                    income: ['gray'],
                    bills: ['pink'],
                    spending: ['green'],
                    cc_payoff: ['gray-alt'],
                    none: [''],
                };
                const allBankAccounts = vm.bankAccounts.reduce((acc, bankAccount) => {
                    acc.push(bankAccount);
                    if(bankAccount.sub_accounts){
                        acc.push(...bankAccount.sub_accounts);
                    }
                    return acc;
                }, []).filter((bankAccount, index, array) => {
                    const bankAccountIndex = array.findIndex(({ id }) => id === bankAccount.id);
                    return !bankAccount.id || bankAccountIndex === index;
                });

                const accountsForPurpose = allBankAccounts.filter(({ purpose }) => purpose === bankAccount.purpose);
                let colorChoices = JSON.parse(JSON.stringify(colorsByPurpose[bankAccount.purpose]));
                accountsForPurpose.forEach(account => {
                    const colorIndex = colorChoices.indexOf(account.color);
                    if(colorIndex >= 0){
                        colorChoices.splice(colorIndex, 1);
                    }
                    if(colorChoices.length === 0){
                        if(purpose === 'credit'){
                            const savingsChoices = JSON.parse(JSON.stringify(colorsByPurpose.savings));
                            const creditChoices = JSON.parse(JSON.stringify(colorsByPurpose.credit));
                            colorChoices = savingsChoices.concat(creditChoices);
                        } else {
                            colorChoices = JSON.parse(JSON.stringify(colorsByPurpose[bankAccount.purpose]));
                        }
                    }
                });
                bankAccount.color = colorChoices.shift();
            }

            function populateSubAccounts(){
                let subAccounts = [];
                if(purpose === 'primary_checking'){
                    subAccounts = [
                        new BankAccount({
                            name: 'Income',
                            purpose: 'income',
                            slug: 'income_deposit',
                            appears_in_account_list: false,
                            color: 'gray',
                            balance_current: bankAccount.balance_current || 0
                        }),
                        new BankAccount({
                            name: '',
                            purpose: 'bills',
                            slug: 'monthly_bills',
                            type: 'checking',
                            appears_in_account_list: false,
                            color: 'pink',
                            balance_current: 0
                        }),
                        new BankAccount({
                            name: '',
                            purpose: 'spending',
                            slug: 'everyday_checking',
                            type: 'checking',
                            appears_in_account_list: false,
                            color: 'green',
                        })
                    ];
                } else if(purpose === 'primary_savings'){
                    subAccounts = [
                        new BankAccount({
                            name: '',
                            purpose: 'savings',
                            color: 'cyan',
                            balance_current: bankAccount.balance_current || 0
                        })
                    ];
                }
                bankAccount.sub_accounts = subAccounts;
                subAccounts.forEach(subAccount => vm.setBankAccountDisplayProperties(subAccount, { isDirty: true }));
                subAccounts.forEach((subAccount, index) => {
                    vm.$set(subAccount, 'sub_account_order', index);
                });
            }
        }
        function cancelChange(){
            vm.$set(bankAccount, 'isRerenderingPurposeDropdown', true);
            Vue.nextTick(() => {
                bankAccount.isRerenderingPurposeDropdown = false;
                bankAccount.purpose = originalPurpose;
            });
        }
    }

    function addBankAccountStructure(purpose){
        const vm = this;
        const newBankAccount = new BankAccount({ name: '', appears_in_account_list: true });
        vm.updateBankAccountPurpose(newBankAccount, purpose);
        vm.setBankAccountDisplayProperties(newBankAccount);
        newBankAccount.isDirty = true;
        vm.addedBankAccounts.push(newBankAccount);
    }

    function checkIfPrimaryCheckingExists(){
        const vm = this;
        const hasAddedBankAccount = vm.addedBankAccounts.some(account => account.purpose === 'primary_checking');
        const hasUpdatedBankAccount = vm.bankAccounts.some(account => account.purpose === 'primary_checking');
        if(hasAddedBankAccount || hasUpdatedBankAccount){
            return true;
        }
        return false;
    }

    function removeAccountsForInstitution(institutionId){
        const vm = this;
        const accountsRelatedToInstitution = vm.bankAccounts.filter(({ institution_account }) => institution_account && institution_account.institution_id === institutionId);
        const removalPromises = accountsRelatedToInstitution.map(removeAccount);
        return Promise.all(removalPromises);

        function removeAccount(bankAccount){
            bankAccount.institution_account_id = null;
            vm.$store.commit('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', Vue.dymUtilities.cloneObject(bankAccount));
            return vm.deleteBankAccount(bankAccount);
        }
    }

    function openEditAccountModal(bankAccount){
        const vm = this;
        vm.bankAccountToEdit = JSON.parse(JSON.stringify(bankAccount));
        vm.bankAccountToEdit.balance_limit_override = vm.bankAccountToEdit.balance_limit;
        const isCreditCard = vm.bankAccountToEdit.purpose === 'credit';
        if(isCreditCard){
            vm.bankAccountToEdit.next_payment_due_date = vm.bankAccountToEdit.liability?.next_payment_due_date || '';
            vm.bankAccountToEdit.last_statement_balance = vm.bankAccountToEdit.liability?.last_statement_balance || '';
        }

        vm.$refs.editAccountInfoModal.show();
    }

    function openDownloadTransactionsModal(bankAccount){
        const vm = this;
        vm.downloadTransaction.bankAccount = JSON.parse(JSON.stringify(bankAccount));
        vm.downloadTransaction.errors = [];
        vm.downloadTransaction.startDate = null;
        vm.downloadTransaction.endDate = null;
        vm.$refs.downloadAccountTransactionsModal.show();
    }

    function downloadTransactionWithDateRange(){
        const vm = this;
        const canSendRequest = !vm.downloadTransaction.sendingRequest &&
            vm.downloadTransaction.bankAccount && vm.downloadTransaction.startDate && vm.downloadTransaction.endDate &&
            Vue.moment(vm.downloadTransaction.startDate).isBefore(Vue.moment(vm.downloadTransaction.endDate));
        if(!canSendRequest){
            return;
        }

        if(vm.downloadTransaction.bankAccount && vm.downloadTransaction.bankAccount.institution_account){
            const possibleEarliestDate = Vue.moment(vm.downloadTransaction.bankAccount.institution_account.linked_at).subtract(3, 'months');
            if(possibleEarliestDate.isAfter(Vue.moment(vm.downloadTransaction.startDate))){
                vm.downloadTransaction.errors.push(`You can only download transactions from ${possibleEarliestDate.format('MM/DD/YYYY')}`);
                return;
            }
        }

        const payload = {
            start_date: Vue.moment(vm.downloadTransaction.startDate).format('YYYY-MM-DD'),
            end_date: Vue.moment(vm.downloadTransaction.endDate).format('YYYY-MM-DD'),
        };
        vm.downloadTransaction.sendingRequest = true;
        vm.downloadTransaction.errors = [];
        Vue.appApi().authorized().bankAccount(vm.downloadTransaction.bankAccount.id).requestTransactionDownload(payload)
            .then(executeOnSuccess)
            .catch(displayError)
            .finally(resetLoadingState);

        function executeOnSuccess({ data }){
            const downloadUrl = formatDownloadUrl(data.token);
            if(downloadUrl){
                clearDownloadTransactionInfo();
                vm.$refs.downloadAccountTransactionsModal.hide();
                if(!vm.isIOS){
                    window.open(downloadUrl, '_blank');
                } else {
                    fetch(downloadUrl).then(saveCsvFile);
                }
            }

            function formatDownloadUrl(token){
                const baseURI = `${window.appEnv.baseURL}/download-account-transactions`;
                return `${baseURI}/${token}`;
            }

            async function saveCsvFile(res){
                const csvBlob = new Blob([await res.blob()]);
                const csvContent = await csvBlob.text();
                const filename = 'transactions.csv';

                await Filesystem.writeFile({
                    path: filename,
                    data: csvContent,
                    directory: Directory.Documents,
                    encoding: Encoding.UTF8
                });

                const { uri } = await Filesystem.getUri({
                    path: filename,
                    directory: Directory.Documents,
                });

                const fileOpenerOptions = {
                    filePath: uri,
                    contentType: 'text/csv',
                    openWithDefault: true,
                };
                await FileOpener.open(fileOpenerOptions);
            }
        }

        function displayError(error){
            if(error && error.appMessage){
                vm.downloadTransaction.errors.push(error.appMessage);
            }
        }

        function clearDownloadTransactionInfo(){
            vm.downloadTransaction.bankAccount = null;
            vm.downloadTransaction.startDate = null;
            vm.downloadTransaction.endDate = null;
            vm.downloadTransaction.errors = [];
        }

        function resetLoadingState(){
            vm.downloadTransaction.sendingRequest = false;
        }
    }

    function saveEditAccountInfo(){
        const vm = this;
        vm.bankAccountToEdit.isSaving = true;
        const bank_account_id = vm.bankAccountToEdit.id;
        const bankPayload = vm.bankAccountToEdit;
        const institutionPayload = vm.bankAccountToEdit.institution_account;
        return saveBankAccountToEdit(bankPayload);

        function saveBankAccountToEdit(bankPayload){
            return vm.$store.dispatch('authorized/bankAccounts/UPDATE_BANK_ACCOUNT', bankPayload)
                .then(saveInstitutionAccount)
                .catch(handleError);
            function saveInstitutionAccount(bankResponse){
                if(bankPayload.institution_account){
                    return vm.$store.dispatch('authorized/bankAccounts/UPDATE_INSTITUTION_ACCOUNT', { bank_account_id, institutionPayload })
                        .then(updateLocalInstitution);
                } else {
                    updateLocalBankAccountEditing(bankResponse);
                }

                function updateLocalInstitution(institutionResponse){
                    bankResponse.data.institution_account = institutionResponse.data;
                    updateLocalBankAccountEditing(bankResponse);
                }
                function updateLocalBankAccountEditing(bankResponse){
                    const bankAccount = vm.bankAccounts.find(({ id }) => id === +bank_account_id);
                    Object.assign(bankAccount, bankResponse.data);
                    handleFinal();
                }
            }
            function handleError(error){
                vm.errorMessages.push(error.appMessage || error.data.message);
            }
            function handleFinal(){
                vm.bankAccountToEdit = null;
                vm.$refs.editAccountInfoModal.hide();
            }
        }
    }

    function removeSubAccount(subAccount, bankAccount){
        const vm = this;
        if(subAccount.id){
            vm.$refs.confirmDeleteModal.openModal(subAccount);
        } else {
            const subAccountIndex = bankAccount.sub_accounts.findIndex(sub_account => sub_account === subAccount);
            if(subAccountIndex >= 0){
                bankAccount.sub_accounts.splice(subAccountIndex, 1);
            }
        }
    }

    function addSubAccount(bankAccount){
        const vm = this;
        const subAccount = new BankAccount({
            parent_bank_account_id: bankAccount.id,
            sub_account_order: bankAccount.sub_accounts.length,
            purpose: 'savings'
        });
        vm.updateBankAccountPurpose(subAccount, 'savings');
        subAccount.name = '';
        vm.setBankAccountDisplayProperties(subAccount, { isDirty: true });
        bankAccount.isDirty = true;
        const ccPayoffIndex = bankAccount.sub_accounts.findIndex((account) => account.purpose === 'cc_payoff');
        if(ccPayoffIndex > -1 && subAccount.slug !== 'cc_payoff'){
            bankAccount.sub_accounts.splice(ccPayoffIndex, 0, subAccount);
        } else {
            bankAccount.sub_accounts.push(subAccount);
        }
    }

    function cancelAccountBalanceEdits(bankAccount){
        const vm = this;
        vm.refreshSubAccounts(bankAccount);
        bankAccount.isEditingCurrentAccountBalance = false;
        bankAccount.isEditingSubAccountBalances = false;
        bankAccount.isCanReOrder = false;
    }

    function overrideAccountBalance(bankAccount){
        bankAccount.isDirty = true;
        bankAccount.is_balance_overridden = true;
    }

    function attachLinkedAccount(bankAccount){
        const vm = this;
        vm.attachAccount.unlinkedAccount = bankAccount;
        vm.$refs.attachUnlinkedAccountModal.show();
    }

    function onSelectAccountToAttach(account, value){
        const vm = this;

        if(value){
            vm.attachAccount.checked = { [account.id]: true };
            vm.attachAccount.targetAccount = account;
        } else {
            vm.attachAccount.checked = {};
            vm.attachAccount.targetAccount = null;
        }
    }

    function confirmAttachLinkedAccount(){
        const vm = this;
        vm.attachAccount.sendingRequest = true;
        vm.attachAccount.errors = [];
        Vue.appApi().authorized().bankAccount(vm.attachAccount.unlinkedAccount.id).attachLinkedAccount({
            toAccountId: vm.attachAccount.targetAccount.id
        })
            .then(executeOnSuccess)
            .catch(displayError)
            .finally(resetLoadingState);

        function executeOnSuccess(){
            vm.$refs.attachUnlinkedAccountModal.hide();
            vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
        }

        function displayError(error){
            if(error && error.appMessage){
                vm.attachAccount.errors.push(error.appMessage);
            }
        }

        function resetLoadingState(){
            vm.attachAccount.sendingRequest = false;
        }
    }

    function unattachLinkedAccount(bankAccount){
        const vm = this;
        Vue.appApi().authorized().bankAccount(bankAccount.id).unattachLinkedAccount().then(
            () => vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS')
        );
    }

    function isAttachedAccountStructure(bankAccount){
        return !['unassigned', 'none'].includes(bankAccount.purpose) && Boolean(bankAccount.institution_account);
    }

    function accountPurposeOptions(purpose = null){
        const vm = this;
        const primaryCheckingOptionDisabled = vm.accountList.some(({ purpose }) => purpose === 'primary_checking');
        const options = [
            { text: 'Select structure', value: purpose, disabled: true, defaultName: 'Select structure' },
            { text: 'Checking Account', value: 'primary_checking', disabled: primaryCheckingOptionDisabled, defaultName: '' },
            { text: 'Savings Account', value: 'primary_savings', disabled: false, defaultName: '' },
            { text: 'Credit Card', value: 'credit', disabled: false, defaultName: 'Credit Card' },
        ];
        return options;
    }

    function getUnassignedBalance(bankAccount){
        const hasSubAccounts = bankAccount.sub_accounts && bankAccount.sub_accounts.length;
        if(!hasSubAccounts){
            return 0;
        }

        const unassignedBalance = bankAccount.sub_accounts.reduce(
            (accumulator, subAccount) => accumulator.minus(new Decimal(bankAccount.isEditingSubAccountBalances ? subAccount.newBucketBalance : subAccount.balance_available)),
            new Decimal(bankAccount.balance_current)
        ).toDecimalPlaces(2);
        return unassignedBalance;
    }

    function findCreditCardAccount(creditCardAccountId){
        const ccAccount = this.bankAccounts.find(({ id }) => id === creditCardAccountId);
        return ccAccount;
    }

    function upgradeSubscription(){
        const vm = this;
        vm.errorMessages = [];
        vm.$refs.paymentComponent.displayPaymentComponent('purchase');
    }

    function paymentSubmitted(){
        const vm = this;
        vm.$store.dispatch('user/GET_USER', true).catch(displayError);
        function displayError(response){
            if(response.appMessage){
                vm.errorMessages.push(response.appMessage);
            }
        }
    }

    function openPlaidLink(){
        const plaidButton = document.getElementById('linkPlaidBtn');
        plaidButton.click();
    }
}

function BankAccount(defaults = {}){
    this.parent_bank_account_id = defaults.parent_bank_account_id || null;
    this.sub_accounts = defaults.sub_accounts || [];
    this.sub_account_order = isNaN(parseInt(defaults.sub_account_order)) ? null : defaults.sub_account_order;
    this.name = defaults.name || '';
    this.slug = defaults.slug || null;
    this.color = defaults.color || '';
    this.purpose = defaults.purpose || 'none';
    this.is_balance_overridden = defaults.is_balance_overridden || false;
    this.appears_in_account_list = defaults.appears_in_account_list || false;
    this.balance_current = defaults.balance_current || 0;
    this.balance_available = defaults.balance_available || 0;
    this.allocation_balance_adjustment = defaults.allocation_balance_adjustment || 0;
    this.assignment_balance_adjustment = defaults.assignment_balance_adjustment || 0;
    this.id = defaults.id || null;
}
