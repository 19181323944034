import _ from 'lodash';

export default {
    data: function(){
        return {
            institutionAccount: null,
        };
    },
    computed: {
        expirationDateText(){
            const now = new Date();
            const institution = this.institutionAccount.institution;
            const expirationDate = new Date(this.institutionAccount.institution_credential.remote_consent_expiration_time);

            if(now < expirationDate){
                return `The connection to <strong>${institution.name}</strong> will expire on ${expirationDate.toLocaleString()}`;
            }

            return `The connection to <strong>${institution.name}</strong> timed out on ${expirationDate.toLocaleString()}`;
        }
    },
    methods: {
        getInstitutionsToMigrate(){
            const bankAccountsWithConnectionExpiration = this.$store.getters['authorized/bankAccounts/bankAccountsWithConnectionExpiration'];
            const institutionAccounts = _.unionBy(_.map(bankAccountsWithConnectionExpiration, 'institution_account'), 'id');

            this.institutionAccount = institutionAccounts[0];
        },
    },
    watch: {
        '$store.state.authorized.bankAccounts.bankAccounts'(newVal, oldVal){
            if(newVal.length){
                this.getInstitutionsToMigrate();
            }
        }
    }
};

