import AuthorizedComponent from './authorized.vue';
import SubscriberRoutes from './subscriber/subscriber.routes';
import ManageSubscriptionComponent from './manage-subscription/manage-subscription.vue';
import VerificationRequiredComponent from './verification-required/verification-required.vue';
import DeactivatedAccountComponent from './deactivated-account/deactivated-account.vue';
import SubscriptionRequiredComponent from './subscription-required/subscription-required.vue';
import BusinessPriorities from './business-priorities/business-priorities.vue';

export default {
    path: '',
    component: AuthorizedComponent,
    meta: {
        requiresAuth: true,
    },
    children: [
        SubscriberRoutes,
        {
            path: 'subscription',
            name: 'manage-subscription',
            component: ManageSubscriptionComponent,
            meta: {
                title: 'Manage Subscription',
                requiresVerifiedEmail: true,
            }
        },
        {
            path: 'verification-required',
            name: 'verification-required',
            component: VerificationRequiredComponent,
            meta: {
                title: 'Verification Required',
            }
        },
        {
            path: 'subscription-required',
            name: 'subscription-required',
            component: SubscriptionRequiredComponent,
            meta: {
                title: 'Subscription Required',
            }
        },
        {
            path: 'deactivated-account',
            name: 'deactivated-account',
            component: DeactivatedAccountComponent,
            meta: {
                title: 'Account Deactivated',
                requiresVerifiedEmail: true,
            }
        },
        {
            path: 'business-priorities',
            name: 'business-priorities',
            component: BusinessPriorities,
            meta: {
                title: 'Business Priorities',
            }
        }
    ]
};
