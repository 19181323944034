// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscriber-view .subscriber-view__demo-mode-alert[data-v-0e6dbc69] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
@media only screen and (max-width : 768px) {
.subscriber-view .subscriber-view__demo-mode-alert[data-v-0e6dbc69] {
    font-size: 12px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
}
.subscriber-view .subscriber-view__account-name[data-v-0e6dbc69] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  color: #5FCEA0;
  border: 1px solid #5FCEA0;
  background-color: #EFFAF5;
  font-weight: 550;
}
@media only screen and (max-width : 768px) {
.subscriber-view .subscriber-view__account-name[data-v-0e6dbc69] {
    font-size: 12px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
}`, "",{"version":3,"sources":["webpack://./_subscriber.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;AADR;AAEQ;AANJ;IAOQ,eAAA;IACA,oBAAA;IACA,qBAAA;AACV;AACF;AACI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;EACA,yBAAA;EACA,yBAAA;EACA,gBAAA;AACR;AAAQ;AAVJ;IAWQ,eAAA;IACA,oBAAA;IACA,qBAAA;AAGV;AACF","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n.subscriber-view {\n    .subscriber-view__demo-mode-alert {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        padding-bottom: 0.5rem;\n        padding-top: 0.5rem;\n        @media #{$breakpointTabletMax} {\n            font-size: 12px;\n            padding-left: 0.5rem;\n            padding-right: 0.5rem;\n        }\n    }\n    .subscriber-view__account-name {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        padding-bottom: 0.5rem;\n        padding-top: 0.5rem;\n        color: #5FCEA0;\n        border: 1px solid #5FCEA0;\n        background-color: #EFFAF5;\n        font-weight: 550;\n        @media #{$breakpointTabletMax} {\n            font-size: 12px;\n            padding-left: 0.5rem;\n            padding-right: 0.5rem;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
