import RefreshAccountsButton from './components/refresh-accounts/refresh-accounts';
import InstitutionsConnectionExpirationComponent from './components/institutions-connection-expiration/institutions-connection-expiration';
import HandoffMessages from './components/handoff-messages/handoff-messages';
import CanUserMixin from 'vue_root/mixins/can-user.mixin.js';
import { Keyboard } from '@capacitor/keyboard';

export default {
    components: {
        InstitutionsConnectionExpirationComponent,
        RefreshAccountsButton,
        HandoffMessages,
    },
    mixins: [CanUserMixin],
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    mounted,
    beforeDestroy,
    methods: getMethods(),
};

function data(){
    return {
        adminLinks: [
            { routeName: 'admin-business-user-list', icon: 'fas fa-users', description: 'Business User List' },
            { routeName: 'admin-user-list', icon: 'fas fa-users', description: 'User List' },
            { routeName: 'admin-notifications-preview', icon: 'fas fa-envelope', description: 'Preview Notifications' },
            { routeName: 'admin-coupons', icon: 'fas fa-barcode', description: 'Manage Coupons' },
            { routeName: 'admin-test-deposit', icon: 'fas fa-credit-card', description: 'Test Deposit' },
            { routeName: 'admin-test-plaid', icon: 'fas fa-code', description: 'Test Plaid API' },
        ],
        showMenu: false,
        isAdminNavOpen: false,
        isAccountRecoveryWarningDismissed: false,
        isAccountErrorCodeMessageDismissed: false,
        isSidebarTransitionDone: true,
        isKeyboardActive: false,
        clientPlatform: window.appEnv.clientPlatform || 'web',
        currentActiveAccount: null,
    };
}

function getComputed(){
    return {
        user(){
            return this.$store.state.guest.user.user;
        },
        isFetchingDataFromPlaid(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        isPlusSubscriber(){
            const vm = this;
            return vm.user.current_account.subscription_plan === 'plus';
        },
        shouldDisplayCCPayoffWarning(){
            const vm = this;
            return vm.$store.state.authorized.shouldDisplayCCPayoffWarning;
        },
        routerLinks(){
            const vm = this;
            const countUnassignedCheckingAndCCTransactions = vm.$store.getters['authorized/transactions/countOfUnassignedCheckingTransactions'] + vm.$store.getters['authorized/transactions/countOfUnassignedCCTransactions'];
            const countUnassignedSavingsTransactions = vm.$store.getters['authorized/transactions/countOfUnassignedSavingsTransactions'];
            const hasSavingsAccount = vm.$store.state.authorized.bankAccounts.bankAccounts.filter((bankAccount) => bankAccount.slug === 'primary_savings').length > 0;
            const links = [
                { routeName: 'dashboard', icon: 'icon-dym-dashboard', description: 'Dashboard', notificationCount: countUnassignedCheckingAndCCTransactions, exact: true, iosPosition: 'footer-menu' },
                { routeName: 'organize', icon: 'icon-dym-organize', description: 'Organize', exact: true, iosPosition: 'footer-menu' },
                { routeName: 'savings', description: 'Savings', notificationCount: countUnassignedSavingsTransactions, exact: true, iosPosition: 'footer-menu' },
                { routeName: 'reports', icon: 'fas fa-chart-bar', description: 'Reports', iosPosition: 'sidebar' },
                { routeName: 'accounts', icon: 'fas fa-university', description: 'Accounts', iosPosition: hasSavingsAccount ? 'sidebar' : 'footer-menu' },
                { routeName: 'settings', icon: 'icon-dym-settings', description: 'Settings', iosPosition: 'sidebar', permissions: ['update account-settings'] },
            ];

            if(!hasSavingsAccount){
                links.splice(2, 1);
            }

            return links;
        },
        isAccountRecoveryWarningDisplayed: {
            get(){
                return !this.isFetchingDataFromPlaid &&
                    this.$store.getters['authorized/bankAccounts/bankAccountsWithRecoverableErrors'].length &&
                    !this.isAccountRecoveryWarningDismissed;
            },
            set(value){
                this.isAccountRecoveryWarningDismissed = value;
            }
        },
        isAccountErrorCodeMessageDisplayed: {
            get(){
                return !this.isFetchingDataFromPlaid &&
                    this.accountsErrorCodes.length &&
                    !this.isAccountErrorCodeMessageDismissed;
            },
            set(value){
                this.isAccountErrorCodeMessageDismissed = value;
            }
        },
        accountsErrorCodes(){
            const unrecoverableErrorCodes = this.$store.getters['authorized/bankAccounts/bankAccountsWithUnrecoverableErrors'].map(({ institution_account }) => institution_account.institution_credential.remote_error_code);
            const unknownErrorCodes = this.$store.getters['authorized/bankAccounts/bankAccountsWithUnknownErrors'].map(({ institution_account }) => institution_account.institution_credential.remote_error_code);
            const allErrorCodes = [].concat(unknownErrorCodes, unrecoverableErrorCodes);
            return [...new Set(allErrorCodes)];
        },
        accountsErroredInstitutionList(){
            const vm = this;
            const unrecoverableErrorInstitutions = vm.$store.getters['authorized/bankAccounts/bankAccountsWithUnrecoverableErrors'].map(({ institution_account }) => institution_account.institution.name);
            const unknownErrorInstitutions = vm.$store.getters['authorized/bankAccounts/bankAccountsWithUnknownErrors'].map(({ institution_account }) => institution_account.institution.name);
            const allErrorInstitutions = [].concat(unrecoverableErrorInstitutions, unknownErrorInstitutions);
            const uniqueErrorInstitutions = allErrorInstitutions.filter((item, index, array) => array.indexOf(item) === index);
            if(uniqueErrorInstitutions.length > 1){
                uniqueErrorInstitutions[uniqueErrorInstitutions.length - 1] = 'and ' + uniqueErrorInstitutions[uniqueErrorInstitutions.length - 1];
            }
            const institutionList = uniqueErrorInstitutions.join(', ');
            return institutionList;
        },
        totalNotificationCount(){
            const vm = this;
            return vm.routerLinks.reduce((accumulator, link) => accumulator + (link.notificationCount || 0), 0);
        },
        useIosMenu(){
            return window.appEnv.clientPlatform === 'ios';
        },
        sidebarLinks(){
            const vm = this;
            const availableLinks = vm.useIosMenu ? vm.routerLinks.filter(({ iosPosition }) => iosPosition === 'sidebar') : vm.routerLinks;
            return availableLinks.filter(checkPermission);

            function checkPermission(link){
                const hasPermission = link.permissions && link.permissions.length > 0;
                if(!hasPermission){
                    return true;
                }

                return link.permissions.every(permission => vm.canUser(permission));
            }
        },
        iosFooterLinks(){
            const vm = this;
            return vm.routerLinks.filter(({ iosPosition }) => iosPosition === 'footer-menu');
        },
        accessibleAccounts(){
            const vm = this;
            return (vm.$store.state.guest.user.accessible_accounts || []).map(setLabelOnOption);

            function setLabelOnOption(account){
                account.label = account.user.name;
                return account;
            }
        },
        accountSwitchable(){
            const vm = this;
            return vm.accessibleAccounts && vm.accessibleAccounts.length > 1;
        }
    };
}

function getWatchers(){
    return {
        showMenu(newVal){
            const vm = this;
            if(newVal){
                vm.$refs.sidebarMenu.focus();
            }
        }
    };
}

function mounted(){
    const vm = this;
    const viewingAdminRoute = vm.$route.matched.some(({ name }) => name === 'admin');

    if(viewingAdminRoute){
        vm.isAdminNavOpen = true;
    }

    if(vm.useIosMenu){
        const keyboardOptions = { isVisible: true };
        Keyboard.setAccessoryBarVisible(keyboardOptions);
        Keyboard.addListener('keyboardWillShow', () => {
            vm.isKeyboardActive = true;
        });
        Keyboard.addListener('keyboardWillHide', () => {
            vm.isKeyboardActive = false;
        });
    }

    vm.currentActiveAccount = vm.accessibleAccounts.find(account => account.account_id === vm.$store.getters['user/currentAccountId']);
}

function beforeDestroy(){
    const vm = this;
    if(vm.useIosMenu){
        Keyboard.removeAllListeners();
    }
}

function getMethods(){
    return {
        hideMenuAndAdminNav,
        logout,
        toggleSidebar,
        onSwitchAccount,
    };

    function hideMenuAndAdminNav(){
        const vm = this;
        vm.$refs.sidebarMenu.blur();
        vm.isAdminNavOpen = false;
    }
    function logout(){
        const vm = this;
        vm.$store.dispatch('user/LOGOUT').then(logoutSuccess);

        function logoutSuccess(){
            if(vm.clientPlatform === 'web'){
                vm.$router.go();
            } else {
                vm.$router.replace({ name: 'login' });
            }
        }
    }
    function toggleSidebar(blurEvent){
        const vm = this;
        const clickedOnAccountSelect = blurEvent && blurEvent.currentTarget.contains(blurEvent.relatedTarget);
        if(clickedOnAccountSelect){
            return;
        }

        if(vm.isSidebarTransitionDone){
            vm.isSidebarTransitionDone = false;
            vm.showMenu = !vm.showMenu;
            setTimeout(() => {
                vm.isSidebarTransitionDone = true;
            }, 200);
        }
    }

    function onSwitchAccount(){
        const vm = this;
        Vue.appApi().authorized().account().switchAccount(vm.currentActiveAccount.account_id).then(handleSuccess).catch(vm.logout);

        function handleSuccess(response){
            vm.$store.commit('user/SET_CURRENT_ACCOUNT', response.data);
            if(vm.showMenu){
                vm.toggleSidebar();
            }

            vm.$store.dispatch('user/GET_USER').then(() => {
                vm.$store.dispatch('renderView/FORCE_REMOUNT');
            });
        }
    }
}
