import OnboardingComponent from './onboarding.vue';
import LinkAccountsComponent from './link-accounts/link-accounts.vue';
import CreateBucketsComponent from './create-buckets/create-buckets.vue';
import OrganizeMoneyComponent from './organize-money/organize-money.vue';
import OnboardingSuccessComponent from './success/success.vue';

export default {
    path: '/onboarding',
    name: 'onboarding',
    component: OnboardingComponent,
    children: [
        {
            path: 'link',
            name: 'onboarding-link',
            component: LinkAccountsComponent,
            meta: {
                title: 'Onboarding - Link accounts'
            }
        },
        {
            path: 'buckets',
            name: 'onboarding-buckets',
            component: CreateBucketsComponent,
            meta: {
                title: 'Onboarding - Create buckets'
            }
        },
        {
            path: 'organize',
            name: 'onboarding-organize',
            component: OrganizeMoneyComponent,
            meta: {
                title: 'Onboarding - Organize money'
            }
        },
        {
            path: 'success',
            name: 'onboarding-success',
            component: OnboardingSuccessComponent,
            meta: {
                title: 'Onboarding - Success'
            }
        },
    ]
};
