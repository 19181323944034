export default {
    mounted,
    methods: getMethods(),
};

function mounted(){
    this.$refs.paymentComponent.displayPaymentComponent('signup');
}

function getMethods(){
    return {
        proceedToApplication,
    };

    function proceedToApplication(){
        this.$router.push({ name: 'dashboard' });
    }
}
