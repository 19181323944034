export default {
    props: {
        creditCardAccounts: {
            type: Array,
            required: true
        },
    },
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MMMM Do');
        }
    },
    data,
    methods: getMethods(),
};

function data(){
    return {
        cardExpanded: [],
    };
}

function getMethods(){
    return {
        getLimitUsagePercent,
        getStatementCoverPercent,
        getCreditUtilizationColor,
        coveredStatementBalance,
        coveredCurrentBalance,
        toggleCard,
    };

    function getLimitUsagePercent(bankAccount){
        let limitUsage = '0';
        const creditLimit = Math.abs(bankAccount.balance_limit);
        const creditUsage = Math.abs(bankAccount.balance_current);
        if(creditLimit){
            const usagePercent = Math.floor((creditUsage / creditLimit) * 100);
            limitUsage = usagePercent;
        }

        return limitUsage + '%';
    }

    function getStatementCoverPercent(bankAccount){
        const balanceCurrent = Math.abs(bankAccount.balance_current);
        const balanceStatement = Math.abs(bankAccount.liability?.last_statement_balance || 0);
        if(balanceCurrent){
            const coverPercent = Math.floor((balanceStatement / balanceCurrent) * 100);
            return `${coverPercent}`;
        }

        return '0';
    }

    function getCreditUtilizationColor(bankAccount){
        const vm = this;
        let usageColor = 'gray';
        if(bankAccount.balance_limit){
            const limitUsage = parseInt(vm.getLimitUsagePercent(bankAccount));
            if(limitUsage >= 0 && limitUsage <= 10){
                usageColor = 'green';
            } else if(limitUsage > 10 && limitUsage <= 30){
                usageColor = 'yellow';
            } else if(limitUsage > 30 && limitUsage <= 50){
                usageColor = 'orange';
            } else {
                usageColor = 'red';
            }
        }
        return usageColor;
    }

    function coveredStatementBalance(bankAccount){
        if(bankAccount.liability?.last_statement_balance > bankAccount.balance_current){
            return true;
        }
        return new Decimal(bankAccount.liability?.last_statement_balance || 0) - new Decimal(bankAccount.balance_payoff || 0) <= 0;
    }

    function coveredCurrentBalance(bankAccount){
        return new Decimal(bankAccount.balance_current || 0) - new Decimal(bankAccount.balance_payoff || 0) <= 0;
    }

    function toggleCard(bankAccount){
        const vm = this;
        vm.$set(vm.cardExpanded, bankAccount.id, !vm.cardExpanded[bankAccount.id]);
    }
}
