import draggable from 'vuedraggable';
import AccountScheduleModal from 'vue_root/components/account-schedule-modal/account-schedule-modal';
import SplitTransactionModal from 'vue_root/authorized/subscriber/components/split-transaction-modal/split-transaction-modal';
import CustomTransactionModal from 'vue_root/authorized/subscriber/components/custom-transaction-modal/custom-transaction-modal';
import MoneyMoverModal from 'vue_root/authorized/subscriber/components/money-mover-modal/money-mover-modal.vue';
import PastTransactions from 'vue_root/authorized/subscriber/components/past-transactions/past-transactions';
import TransactionAutomationModal from 'vue_root/authorized/subscriber/components/transaction-automation-modal/transaction-automation-modal.vue';
import EditTransactionsModal from './components/edit-transactions-modal/edit-transactions-modal.vue';
import CreditCardTracker from './components/cc-tracker/cc-tracker';
import { cloneDeep } from 'lodash';
import formatAsDecimal from 'vue_root/mixins/formatAsDecimal.mixin';
import sortBankAccounts from 'vue_root/mixins/sortBankAccounts.mixin.js';

export default {
    components: {
        draggable,
        EditTransactionsModal,
        AccountScheduleModal,
        SplitTransactionModal,
        CustomTransactionModal,
        PastTransactions,
        CreditCardTracker,
        MoneyMoverModal,
        TransactionAutomationModal,
    },
    filters: {
        formatDate: function(date){
            return Vue.moment(date).format('MM/DD/YY');
        }
    },
    mixins: [formatAsDecimal, sortBankAccounts],
    data: data,
    computed: getComputed(),
    watch: getWatchers(),
    created: created,
    beforeDestroy,
    methods: getMethods(),
};

function created(){
    const vm = this;
    vm.isInitializingView = true;

    vm.refreshAllData(true).finally(resetLoadingState);
    window.addEventListener('resize', vm.toggleDragAndDropFeature);
    vm.toggleDragAndDropFeature();

    function resetLoadingState(){
        vm.isInitializingView = false;
        vm.isFinishedAssigningAllTransactions = !vm.pendingAssignments.length;
    }
}

function beforeDestroy(){
    const vm = this;
    if(vm.isBankAccountStoreOutOfSync){
        vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
    }
    window.removeEventListener('resize', vm.toggleDragAndDropFeature);

}

function data(){
    return {
        pendingAssignments: [],
        assignableAccounts: [],
        accountCardExpanded: [],
        creditCards: [],
        apiErrors: [],
        isInitializingView: false,
        isBankAccountStoreOutOfSync: false,
        selectedTransactions: [],
        selectedAccount: { label: 'Choose a bucket', value: null },
        bulkAssignmentError: '',
        isBulkAssigningTransactions: false,
        isMobileScreenSize: false,
        isFinishedAssigningAllTransactions: true,
        pendingAccountsReload: null,
        isIOS: window.appEnv.clientPlatform === 'ios',
        selectedBankAccount: null,
        ccPayoffAccounts: [],
        primaryCheckingAccount: {},
        unassignedTransactionFolder: 'checking',
        shouldClosePayoffAfterAssign: false,
        selectAllTransactions: false,
        updateSubscriptionErrors: [],
    };
}

function getComputed(){
    return {
        savingsAccessCC(){
            return this.$store.state.savingsCC.data;
        },
        fetchingTransactions(){
            return this.$store.state.authorized.transactions.isFetchingTransactions;
        },
        assignableTransactions(){
            const vm = this;
            return vm.$store.state.authorized.transactions.unassignedTransactions.filter(transaction => transaction.type === vm.unassignedTransactionFolder);
        },
        sortedAssignableAccounts(){
            const vm = this;
            return vm.assignableAccounts
                .map(addAttribute)
                .sort(vm.byModifiedStoreOrder);

            function addAttribute(account){
                const hasAccountSchedule = account.slug !== 'income_deposit' && account.slug !== 'cc_payoff';
                account.hasAccountSchedule = hasAccountSchedule;
                return account;
            }
        },
        isFetchingDataFromPlaid(){
            return this.$store.state.authorized.isFetchingDataFromPlaid;
        },
        creditCardsKeyedById(){
            const vm = this;
            return vm.creditCards.reduce(keyByBankAccountId, {});
            function keyByBankAccountId(accumulator, creditCard){
                accumulator[creditCard.id] = creditCard;
                return accumulator;
            }
        },
        pendingAssignmentsByDate(){
            const vm = this;
            return vm.pendingAssignments.sort(vm.sortByTransactionDate);
        },
        totalOfSelectedTransactions(){
            const vm = this;
            return vm.selectedTransactions.reduce(sumSelectedTransactions, new Decimal(0)).toDecimalPlaces(2).toNumber();
            function sumSelectedTransactions(accumulator, pendingAssignment){
                return accumulator.plus(pendingAssignment.transaction.amount).toDecimalPlaces(2);
            }
        },
        totalOfSelectedCCDeposits(){
            const vm = this;
            return vm.selectedTransactions.reduce(sumSelectedTransactions, new Decimal(0)).toDecimalPlaces(2).toNumber();
            function sumSelectedTransactions(accumulator, pendingAssignment){
                const isCCDeposit = pendingAssignment.transaction.type === 'credit' && pendingAssignment.transaction.amount < 0;
                return isCCDeposit ? accumulator.plus(pendingAssignment.transaction.amount).toDecimalPlaces(2) : accumulator;
            }
        },
        accountSelectOptions(){
            const vm = this;
            const nullOptions = [{ label: 'Choose a bucket', value: null }];

            const selectableAccountOptions = [...vm.sortedAssignableAccounts, ...vm.ccPayoffAccounts].map((bankAccount) => {
                return {
                    label: bankAccount.slug === 'cc_payoff' ? `${bankAccount.credit_card_account.name} Payoff` : bankAccount.name,
                    value: bankAccount
                };
            });
            return nullOptions.concat(selectableAccountOptions);
        },
        isBulkAssignmentAllowed(){
            const vm = this;
            const hasSelectedAccount = vm.selectedAccount && !!vm.selectedAccount.value;
            const isAssigningToPayoff = hasSelectedAccount && vm.selectedAccount.value.slug === 'cc_payoff';
            const assigningCCExpense = vm.selectedTransactions.some(({ transaction }) => transaction.type === 'credit' && transaction.amount > 0);
            const assigningCCDepositToPayoff = vm.totalOfSelectedCCDeposits < 0 && isAssigningToPayoff;
            const isAssigningCCExpenseToPayoff = isAssigningToPayoff && assigningCCExpense;
            const isProjectedBalanceNegative = hasSelectedAccount && (
                isAssigningToPayoff
                    ? vm.selectedAccount.value.balance_available < vm.totalOfSelectedTransactions - vm.totalOfSelectedCCDeposits
                    : vm.selectedAccount.value.balance_available < vm.totalOfSelectedTransactions
            );
            let errorMessage = '';
            if(isAssigningCCExpenseToPayoff){
                errorMessage = 'You cannot assign a negative credit card transaction to a payoff bucket.';
            } else if(isProjectedBalanceNegative){
                errorMessage = 'There is not enough money in this bucket. Please choose a different bucket.';
            } else if(assigningCCDepositToPayoff){
                const isAssignableCCDeposit = vm.selectedTransactions.every(({ transaction }) => {
                    const isCCDeposit = transaction.type === 'credit' && transaction.amount < 0;
                    const isFromSameCCAccount = transaction.bank_account_id === vm.selectedAccount.value.payoff_parent_credit_account_id;
                    const isAssignable = !isCCDeposit || isFromSameCCAccount;

                    return isAssignable;
                }
                );
                if(!isAssignableCCDeposit){
                    errorMessage = 'This is not the correct payoff bucket. Please choose the payoff bucket associated with this credit card.';
                }
            }
            if(errorMessage){
                vm.bulkAssignmentError = errorMessage;
                if(vm.$refs.bulkAssignmentError){
                    Vue.nextTick(() => {
                        vm.$refs.bulkAssignmentError.focus();
                    });
                }
            }
            return hasSelectedAccount && !errorMessage && !vm.isBulkAssigningTransactions;
        },
        isBulkAssignmentPanelDisplayed(){
            const vm = this;
            return vm.selectedTransactions.length || vm.isBulkAssigningTransactions;
        },
        allTransactionsSelected: {
            get(){
                const vm = this;
                return vm.selectedTransactions.length === vm.pendingAssignmentsByDate.length;
            },
            set(isChecked){
                const vm = this;
                vm.selectedTransactions = isChecked ? vm.pendingAssignmentsByDate.slice() : [];
            }
        },
        isDragAssignmentDisabled(){
            const vm = this;
            return vm.selectedTransactions.length || vm.isBulkAssigningTransactions || vm.isMobileScreenSize;
        },
        countOfUnassignedCCTransactions(){
            return this.$store.getters['authorized/transactions/countOfUnassignedCCTransactions'];
        },
        countOfUnassignedCheckingTransactions(){
            return this.$store.getters['authorized/transactions/countOfUnassignedCheckingTransactions'];
        },
        unassignedCCTransactions(){
            return this.$store.getters['authorized/transactions/unassignedCCTransactions'];
        },
        unassignedBankTransactions(){
            return this.$store.getters['authorized/transactions/unassignedBankTransactions'];
        },
        ccPayoffTotalBalance(){
            const vm = this;
            const totalBalance = vm.ccPayoffAccounts ? vm.ccPayoffAccounts.reduce((balance, card) => balance + card.balance_available, 0) : 0;
            return totalBalance;
        },
        bankAccountsForEdit(){
            return this.$store.state.authorized.bankAccounts.bankAccounts.filter(filterAttachedCheckingAndCreditCards);

            function filterAttachedCheckingAndCreditCards(bankAccount){
                const isAttachedCheckingAccount = bankAccount.institution_account && bankAccount.slug === 'primary_checking';
                const isCreditCardAccount = bankAccount.type === 'credit';
                return isAttachedCheckingAccount || isCreditCardAccount;
            }
        },
        linkedBankAccountsForEdit(){
            return this.bankAccountsForEdit.filter(bankAccount => !!bankAccount.institution_account);
        },
    };
}

function getWatchers(){
    return {
        isFetchingDataFromPlaid(oldStatus, newStatus){
            if(oldStatus && !newStatus){
                created.apply(this);
            }
        },
        unassignedTransactionFolder(newTransactions){
            this.setPendingAssignments();
        },
        assignableTransactions(newTransactions){
            this.setPendingAssignments();
        },
        isFinishedAssigningAllTransactions(hasCompletedAssignments){
            const vm = this;
            const user = vm.$store.getters.user;
            const isUserAccountOlderThanThreeMonths = Vue.moment().subtract(3, 'months').isAfter(user.current_account.created_at);
            if(hasCompletedAssignments && isUserAccountOlderThanThreeMonths){
                Vue.iosInAppReview.requestReview();
            }
        },
        '$store.state.assignableAccounts.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
        '$store.state.savingsCC.errorMessage'(newValue){
            this.apiErrors.push(newValue);
        },
        selectedTransactions(newSelectedTransactions){
            const isAllSelected = newSelectedTransactions.length === this.pendingAssignments.length;
            this.selectAllTransactions = isAllSelected;
        }
    };
}

function getMethods(){
    return {
        setAssignableAccounts,
        setPayoffAccounts,
        loadAssignableTransactions,
        setPendingAssignments,
        loadAssignableAccounts,
        removeAssignment,
        toggle,
        assignTransaction,
        applyMoveStyles,
        loadSavingsAccessCC,
        loadCreditCards,
        getCCPayoffAccount,
        resetDropTransactionHere,
        editUnassignedTransactions,
        displayApiError,
        sortByTransactionDate,
        bulkAssignTransactions,
        toggleDragAndDropFeature,
        openAccountSchedule,
        loadPrimaryCheckingAccount,
        refreshAllData,
        onTransactionUpdated,
        onRemoteTransactionUpdated,
        openMoveMoneyModal,
        isTransactionSelected,
        editTransaction,
        updateBankAccountBalances,
        refreshBankAccountBalances,
        onChangeSelectAllTransactions,
        refreshCreditAccounts,
        openTransactionAutomationModal,
        updatePrimaryAccountBalance,
        updateTransactionList,
        quickRefreshAccountBalanceAvailable,
        updateAssignableAccountBalanceAvailable,
        updateBankAccountBalanceAvailable,
        upgradeSubscription,
        paymentSubmitted,
        loadAccountTransactions,
        loadPayoffAccountTransactions,
        updateTransferredTransaction,
        removeTransactionState,
    };

    function setAssignableAccounts(){
        const vm = this;
        const data = cloneDeep(vm.$store.state.assignableAccounts.data);
        const assignableBankAccounts = data.map((bankAccount) => {
            bankAccount.loading = false;
            bankAccount.isLoadingTransaction = false;
            bankAccount.apiError = '';
            return bankAccount;
        }).sort(this.byModifiedStoreOrder);

        vm.assignableAccounts = assignableBankAccounts;
    }

    function setPayoffAccounts(){
        const vm = this;
        const data = cloneDeep(vm.$store.state.payoffAccountOverview.data);
        const assignableBankAccounts = data.map((bankAccount) => {
            bankAccount.loading = false;
            bankAccount.isLoadingTransaction = false;
            bankAccount.apiError = '';
            return bankAccount;
        });
        vm.ccPayoffAccounts = assignableBankAccounts;
    }

    function loadAssignableTransactions(){
        const vm = this;
        return vm.$store.dispatch('authorized/transactions/FETCH_UNASSIGNED_TRANSACTIONS', ['checking', 'credit']);
    }

    function setPendingAssignments(){
        const vm = this;
        vm.pendingAssignments = vm.assignableTransactions.map(formatTransactionAsAssignment);

        const updatedSelectedTransactions = vm.pendingAssignments.filter(({ transaction }) => vm.isTransactionSelected(transaction));
        vm.selectedTransactions = updatedSelectedTransactions;
    }

    function loadAssignableAccounts(isInitialLoad, accountId){
        const vm = this;
        if(!isInitialLoad){
            vm.isBankAccountStoreOutOfSync = true;
        }

        return this.$store.dispatch('assignableAccounts/GET_DATA', { force: true, accountId });
    }

    function removeAssignment(assignment, assignableAccount){
        const vm = this;
        vm.$set(assignableAccount, 'loading', true);
        const index = assignableAccount.untransferred_assignments.findIndex(({ id }) => id === assignment.id);
        assignableAccount.untransferred_assignments.splice(index, 1);

        return Vue.appApi().authorized().bankAccount(assignment.bank_account_id).assignment(assignment.id).deleteAssignment()
            .then(() => {
                vm.$store.commit('authorized/transactions/ADD_UNASSIGNED_TRANSACTION', assignment.transaction);
                vm.removeTransactionState(assignment, assignableAccount);
            })
            .catch(displayErrors)
            .finally(resetLoadingState);

        function displayErrors(response){
            if(response.appMessage){
                vm.apiErrors.push(response.appMessage);
                assignableAccount.untransferred_assignments.splice(index, 0, assignment);
            }
        }
        function resetLoadingState(){
            assignableAccount.loading = false;
        }
    }

    function removeTransactionState(assignment, assignableAccount){
        const vm = this;
        const isPayoffAccount = assignableAccount.slug === 'cc_payoff';
        if(!isPayoffAccount){
            vm.$store.commit('assignableAccounts/REMOVE_TRANSACTION', { id: assignableAccount.id, transaction: assignment });
        } else {
            vm.$store.commit('payoffAccountOverview/REMOVE_TRANSACTION', { id: assignableAccount.id, transaction: assignment });
            vm.setPayoffAccounts();
        }
        if(assignment.transaction.type !== 'credit'){
            vm.updatePrimaryAccountBalance(assignment.transaction.amount);
        }
        if(!isPayoffAccount && assignment.transaction.type === 'credit'){
            const accountIndex = vm.ccPayoffAccounts.findIndex((account) => account.payoff_parent_credit_account_id === assignment.transaction.bank_account_id);
            if(accountIndex > -1){
                vm.ccPayoffAccounts[accountIndex].balance_available = vm.ccPayoffAccounts[accountIndex].balance_available - assignment.transaction.amount;
            }
        }
        vm.refreshCreditAccounts();
        vm.quickRefreshAccountBalanceAvailable(assignableAccount);
    }

    function toggle(assignableAccount){
        const vm = this;

        vm.$set(vm.accountCardExpanded, assignableAccount.id, !vm.accountCardExpanded[assignableAccount.id]);
        vm.$set(assignableAccount, 'showAllAssignments', false);
    }

    function assignTransaction(bankAccount, event){
        const vm = this;
        vm.selectedTransactions = [];
        const transaction_id = parseInt(event.item.getAttribute('data-id'));
        const pendingAssignmentIndex = vm.assignableTransactions.findIndex(assignment => assignment.id === transaction_id);
        const pendingAssignment = formatTransactionAsAssignment(vm.assignableTransactions[pendingAssignmentIndex]);
        let newAccountBalance = pendingAssignment && new Decimal(bankAccount.balance_available).minus(pendingAssignment.transaction.amount).toDecimalPlaces(2).toNumber();

        const isExpenseTransaction = pendingAssignment && pendingAssignment.transaction.amount > 0;
        const isCCTransaction = pendingAssignment && pendingAssignment.transaction.type === 'credit';
        const isAssigningToPayoff = bankAccount.slug === 'cc_payoff';
        const shouldKeepBalance = isAssigningToPayoff && isCCTransaction && !isExpenseTransaction;
        if(shouldKeepBalance){
            newAccountBalance = bankAccount.balance_available;
        }
        const assignmentCancellationMessage = getAssignmentCancellationMessage();
        const shouldCancelAssignment = !!assignmentCancellationMessage;
        if(shouldCancelAssignment){
            const transactionIndex = bankAccount.untransferred_assignments?.findIndex((assignment) => assignment.transaction.id === transaction_id);
            bankAccount.untransferred_assignments?.splice(transactionIndex, 1);
            vm.pendingAssignments.push(pendingAssignment);
            bankAccount.apiError = assignmentCancellationMessage;
            Vue.nextTick(() => {
                vm.$refs[`assignable-account-error-${bankAccount.id}`][0].focus();
            });
            vm.shouldClosePayoffAfterAssign = false;
            return false;
        } else {
            vm.$set(bankAccount, 'loading', true);
            return Vue.appApi().authorized().bankAccount(bankAccount.id).assignment().postAssignTransaction({ transaction_id })
                .then((response) => {
                    const { assignment } = response.data;
                    vm.updateTransactionList(bankAccount, transaction_id, assignment);
                    vm.refreshCreditAccounts();
                    vm.quickRefreshAccountBalanceAvailable(bankAccount);
                })
                .catch(displayErrors)
                .finally(resetLoadingState);
        }

        function displayErrors(response){
            vm.apiErrors.push(response.appMessage);
        }

        function resetLoadingState(){
            vm.$set(bankAccount, 'loading', false);
            if(vm.shouldClosePayoffAfterAssign){
                vm.$set(vm.accountCardExpanded, 'payoff', false);
                vm.shouldClosePayoffAfterAssign = false;
            }
        }

        function getAssignmentCancellationMessage(){
            let assignmentCancellationMessage = '';
            const isAssigningCCTransactionToPayoff = isAssigningToPayoff && isCCTransaction;
            if(isAssigningCCTransactionToPayoff){
                if(isExpenseTransaction){
                    assignmentCancellationMessage = 'You cannot assign a negative credit card transaction to a payoff bucket.';
                } else {
                    const isAssigningToCorrectPayoff = pendingAssignment.transaction.bank_account_id === bankAccount.payoff_parent_credit_account_id;
                    if(!isAssigningToCorrectPayoff){
                        assignmentCancellationMessage = 'This is not the correct payoff bucket. Please choose the payoff bucket associated with this credit card.';
                    }
                }
            } else {
                const balanceWillBeNegative = pendingAssignment && newAccountBalance < 0;
                if(balanceWillBeNegative){
                    assignmentCancellationMessage = 'There is not enough money in this bucket. Please choose a different bucket.';
                }
            }

            return assignmentCancellationMessage;
        }
    }

    function applyMoveStyles(event, originalEvent){
        const vm = this;

        vm.resetDropTransactionHere();
        if(event.to.id !== null){
            // Open Payoff Placeholder if it's a payoff bucket
            const toPayoffAccount = event.to.id === 'payOffBucket';
            const shouldOpenPayoffPlaceholder = toPayoffAccount && !vm.accountCardExpanded['payoff'];
            if(shouldOpenPayoffPlaceholder){
                vm.$set(vm.accountCardExpanded, 'payoff', true);
                vm.shouldClosePayoffAfterAssign = true;
                return;
            }

            // Add a frame
            var dropAssignableAccountElement = document.getElementById(event.to.id + '-body');
            if(dropAssignableAccountElement){
                dropAssignableAccountElement.classList.add('assignable-account-border-' + dropAssignableAccountElement.dataset.color);
            }
        }

        return true;
    }

    function loadSavingsAccessCC(){
        return this.$store.dispatch('savingsCC/GET_DATA');
    }

    function loadCreditCards(){
        const vm = this;
        return Vue.appApi().authorized().bankAccount().loadWithInstitutionAccounts({ type: 'credit' }).then(setCreditCards).catch(vm.displayApiError);

        function setCreditCards(response){
            vm.creditCards = response.data.map(cc => ({
                ...cc,
                untransferred_assignments: []
            }));
        }
    }

    function loadPrimaryCheckingAccount(){
        const vm = this;
        return Vue.appApi().authorized().bankAccount().loadWithInstitutionAccounts({ slug: 'primary_checking' }).then(setPrimaryCheckingAccount).catch(vm.displayApiError);

        function setPrimaryCheckingAccount(response){
            if(response.data){
                vm.primaryCheckingAccount = response.data[0] || {};
                vm.primaryCheckingAccount.unassignedBalance = vm.primaryCheckingAccount.sub_accounts.reduce((accumulator, subAccount) => accumulator.minus(new Decimal(subAccount.balance_available)), new Decimal(vm.primaryCheckingAccount.balance_current)).toDecimalPlaces(2);
            }
            return vm.primaryCheckingAccount;
        }
    }

    function getCCPayoffAccount(isInitialLoad){
        const vm = this;
        if(!isInitialLoad){
            vm.isBankAccountStoreOutOfSync = true;
        }

        return this.$store.dispatch('payoffAccountOverview/GET_DATA', true).then(setPayoffAccounts);
        function setPayoffAccounts(){
            vm.ccPayoffAccounts = cloneDeep(vm.$store.state.payoffAccountOverview.data);
        }
    }

    function resetDropTransactionHere(){
        const vm = this;
        [...vm.sortedAssignableAccounts, ...vm.ccPayoffAccounts].forEach(resetMoveStyles);

        function resetMoveStyles(assignableAccount){
            var assignableAccountElement = document.getElementById('assignableAccount-' + assignableAccount.id + '-body');
            if(assignableAccountElement !== null){
                assignableAccountElement.classList.remove('assignable-account-border-' + assignableAccountElement.dataset.color);
            }
            var assignableAccountElementParent = document.getElementById('assignableAccount-' + assignableAccount.id + '-header-parent');
            if(assignableAccountElementParent){
                assignableAccountElementParent.style.display = 'none';
            }
        }
    }

    function editUnassignedTransactions(){
        const vm = this;
        vm.$refs.editTransactionsModal.openModal();
    }

    function displayApiError(response){
        const vm = this;
        if(response && response.appMessage){
            vm.apiErrors.push(response.appMessage);
        }
    }

    function sortByTransactionDate(a, b){
        const firstDate = a.transaction ? a.transaction.remote_transaction_date : a.remote_transaction_date;
        const secondDate = b.transaction ? b.transaction.remote_transaction_date : b.remote_transaction_date;
        return Vue.moment(firstDate).isAfter(secondDate) ? -1 : 1;
    }

    function bulkAssignTransactions(){
        const vm = this;
        vm.isBulkAssigningTransactions = true;
        const bankAccount = vm.selectedAccount.value;
        const transaction_ids = vm.selectedTransactions.map(({ transaction }) => transaction.id);
        transaction_ids.forEach((id) => vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id }));
        vm.$set(bankAccount, 'loading', true);
        const selectedTransactions = vm.selectedTransactions;
        vm.selectedTransactions = [];

        return Vue.appApi().authorized().bankAccount(bankAccount.id).assignment().postAssignTransaction({ transaction_ids })
            .then((response) => {
                response.data.forEach(({ assignment }) => assignTransaction(assignment));
                vm.refreshCreditAccounts();
                vm.quickRefreshAccountBalanceAvailable(bankAccount);
            })
            .catch(handleAssignmentErrors)
            .finally(resetLoadingState);

        function assignTransaction(assignment){
            const { transaction_id } = assignment;
            vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id: transaction_id });
            vm.updateTransactionList(bankAccount, transaction_id, assignment);
        }

        function handleAssignmentErrors(error){
            vm.selectedTransactions = selectedTransactions;
            vm.selectedTransactions.forEach(({ transaction }) => vm.$store.commit('authorized/transactions/ADD_UNASSIGNED_TRANSACTION', transaction));
            vm.bulkAssignmentError = error.appMessage || (error.data && error.data.message);
        }

        function resetLoadingState(){
            vm.$set(bankAccount, 'loading', false);
            vm.isBulkAssigningTransactions = false;
            vm.selectedAccount = vm.accountSelectOptions[0];
        }
    }

    function toggleDragAndDropFeature(){
        const vm = this;
        const mobileBreakpoint = 576;
        if(vm.isMobileScreenSize && window.innerWidth > mobileBreakpoint){
            vm.isMobileScreenSize = false;
        } else if(!vm.isMobileScreenSize && window.innerWidth <= mobileBreakpoint){
            vm.isMobileScreenSize = true;
        }
    }

    function openAccountSchedule(bankAccount){
        if(!bankAccount.hasAccountSchedule){
            return;
        }

        const vm = this;
        vm.selectedBankAccount = bankAccount;
        Vue.nextTick(() => {
            vm.$refs.accountScheduleModal.show();
            vm.toggle(bankAccount);
        });
    }

    function openMoveMoneyModal(fromAccount){
        this.$refs.moneyMoverModal.openModal(fromAccount);
        this.toggle(fromAccount);
    }

    async function refreshAllData(_isInitialLoad = false){
        const vm = this;
        const isInitialLoad = _isInitialLoad === true;
        if(isInitialLoad){
            if(!vm.$store.state.authorized.bankAccounts.bankAccounts.length){
                vm.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS');
            }
        }
        const primaryAccount = await vm.loadPrimaryCheckingAccount();
        if(!primaryAccount){
            return;
        }
        const dataPromises = [
            vm.loadSavingsAccessCC(),
            vm.getCCPayoffAccount(isInitialLoad),
            vm.loadCreditCards(),
            vm.loadAssignableAccounts(isInitialLoad, primaryAccount.id),
            vm.loadAssignableTransactions(),
        ];

        return Promise.all(dataPromises).then(onRefreshComplete);

        function onRefreshComplete(){
            vm.setPendingAssignments();
            vm.setAssignableAccounts();

            if(isInitialLoad){
                vm.updateBankAccountBalances();
            }
        }
    }

    async function refreshCreditAccounts(){
        const vm = this;
        const dataPromises = [
            vm.loadSavingsAccessCC(),
            vm.loadCreditCards(),
        ];

        return Promise.all(dataPromises).then(onRefreshComplete);

        function onRefreshComplete(){
            vm.setPendingAssignments();
            vm.setAssignableAccounts();
        }
    }

    function updatePrimaryAccountBalance(amount){
        const vm = this;
        vm.primaryCheckingAccount.unassignedBalance = vm.primaryCheckingAccount.unassignedBalance.minus(new Decimal(amount));
    }

    function updateTransactionList(bankAccount, transactionId, assignment){
        const vm = this;
        const bankAccountId = bankAccount.id;
        const isPayoffAccount = bankAccount.slug === 'cc_payoff';
        vm.$store.commit('authorized/transactions/REMOVE_UNASSIGNED_TRANSACTION', { id: transactionId });
        if(!isPayoffAccount){
            vm.$store.commit('assignableAccounts/ADD_TRANSACTION', { id: bankAccountId, transaction: assignment });
        } else {
            vm.$store.commit('payoffAccountOverview/ADD_TRANSACTION', { id: bankAccountId, transaction: assignment });
            vm.setPayoffAccounts();
        }
        if(assignment.transaction.type !== 'credit'){
            vm.updatePrimaryAccountBalance(-(assignment.transaction.amount));
        }
        if(!isPayoffAccount && assignment.transaction.type === 'credit'){
            const accountIndex = vm.ccPayoffAccounts.findIndex((account) => account.payoff_parent_credit_account_id === assignment.transaction.bank_account_id);
            if(accountIndex > -1){
                vm.ccPayoffAccounts[accountIndex].balance_available = vm.ccPayoffAccounts[accountIndex].balance_available + assignment.transaction.amount;
            }
        }
    }

    async function onTransactionUpdated(){
        const vm = this;
        const dataPromises = [
            vm.getCCPayoffAccount(),
            vm.loadAssignableAccounts(false, vm.primaryCheckingAccount.id),
            vm.loadAssignableTransactions(),
        ];

        return Promise.all(dataPromises).then(onLoadComplete);

        function onLoadComplete(){
            vm.setAssignableAccounts();
            vm.updateBankAccountBalances();
        }
    }

    async function onRemoteTransactionUpdated(){
        const vm = this;
        vm.loadAssignableTransactions().then(onLoadComplete);

        function onLoadComplete(){
            vm.setAssignableAccounts();
            vm.updateBankAccountBalances();
        }
    }

    function isTransactionSelected(transactionToCheck){
        return this.selectedTransactions.some(({ transaction }) => transaction.id === transactionToCheck.id);
    }

    function editTransaction(transaction, type = 'unassigned', bankAccount = null){
        const vm = this;
        if(type === 'assigned'){
            const isCustomTransaction = !transaction.transaction.parent_transaction_id && !transaction.transaction.remote_transaction_id;
            if(isCustomTransaction){
                vm.$refs.customTransactionModal.openModal(transaction, true, bankAccount);
            } else {
                vm.$refs.splitTransactionModal.openModal(transaction, true, bankAccount);
            }
        } else {
            const isCustomTransaction = !transaction.parent_transaction_id && !transaction.remote_transaction_id;
            if(isCustomTransaction){
                vm.$refs.customTransactionModal.openModal(transaction);
            } else {
                vm.$refs.splitTransactionModal.openModal(transaction);
            }
        }
    }

    function updateBankAccountBalances(){
        const vm = this;
        const allBankAccounts = [...vm.assignableAccounts, ...vm.ccPayoffAccounts];
        allBankAccounts.forEach(updateBankAccountBalance);

        function updateBankAccountBalance(bankAccount){
            vm.$store.commit('authorized/bankAccounts/UPDATE_BANK_BALANCE_PROPERTIES', bankAccount);
        }
    }

    function quickRefreshAccountBalanceAvailable(assignableAccount){
        const vm = this;
        vm.updateAssignableAccountBalanceAvailable(assignableAccount);
        vm.updateBankAccountBalanceAvailable(assignableAccount.id, assignableAccount.balance_available);
    }

    function updateAssignableAccountBalanceAvailable(assignableAccount){
        const vm = this;
        const data = vm.$store.state.assignableAccounts.data;
        const accountIndex = data.findIndex((account) => account.id === assignableAccount.id);
        if(accountIndex > -1){
            assignableAccount.balance_available = data[accountIndex].balance_available;
        }
    }

    function updateBankAccountBalanceAvailable(accountId, balanceAvailable){
        const vm = this;
        vm.$store.commit(
            'authorized/bankAccounts/UPDATE_BANK_BALANCE_AVAILABLE',
            { id: accountId, balance_available: balanceAvailable }
        );
    }

    function refreshBankAccountBalances(){
        this.$store.commit('assignableAccounts/REFRESH_BANK_ACCOUNT_BALANCES', this.$store.state.authorized.bankAccounts.bankAccounts);
        this.setAssignableAccounts();
        this.getCCPayoffAccount();
        this.loadCreditCards();
    }

    function onChangeSelectAllTransactions(){
        const vm = this;
        if(vm.selectAllTransactions){
            vm.selectedTransactions = vm.pendingAssignments;
        } else {
            vm.selectedTransactions = [];
        }
    }

    function openTransactionAutomationModal(bankAccount){
        const vm = this;
        Vue.nextTick(() => {
            vm.$refs.transactionAutomationModal.openModal(bankAccount);
            vm.toggle(bankAccount);
        });
    }

    function upgradeSubscription(){
        const vm = this;
        vm.apiErrors = [];
        vm.$refs.paymentComponent.displayPaymentComponent('purchase');
    }

    function paymentSubmitted(){
        const vm = this;
        vm.$store.dispatch('user/GET_USER', true).catch(displayError);
        function displayError(response){
            if(response.appMessage){
                vm.apiErrors.push(response.appMessage);
            }
        }
    }

    function loadAccountTransactions(account, showAll = ''){
        const vm = this;
        if(!account.untransferred_assignments || showAll !== ''){
            account.isLoadingTransaction = true;
            return this.$store.dispatch('assignableAccounts/FETCH_ACCOUNT_TRANSACTIONS', { accountId: account.id, showAll })
                .then(vm.setAssignableAccounts)
                .catch(displayError);
        }

        function displayError(response){
            if(response.appMessage){
                vm.apiErrors.push(response.appMessage);
            }
        }
    }

    function loadPayoffAccountTransactions(account, showAll = ''){
        const vm = this;
        if(!account.untransferred_assignments || showAll !== ''){
            account.isLoadingTransaction = true;
            this.$store.dispatch('payoffAccountOverview/FETCH_ACCOUNT_TRANSACTIONS', { accountId: account.id, showAll })
                .then(vm.setPayoffAccounts)
                .catch(displayError);
        }

        function displayError(response){
            if(response.appMessage){
                vm.account.apiError.push(response.appMessage);
            }
        }
    }

    function updateTransferredTransaction(data){
        const vm = this;
        const transactionType = data.type;
        const assignment = data.assignment;
        const assignableAccount = data.bankAccount;
        const isPayoffAccount = assignableAccount.slug === 'cc_payoff';
        if(transactionType === 'update'){
            if(isPayoffAccount){
                vm.$store.commit('payoffAccountOverview/UPDATE_TRANSACTION', { id: assignableAccount.id, transaction: assignment });
                vm.setPayoffAccounts();
            } else {
                vm.$store.commit('assignableAccounts/UPDATE_TRANSACTION', { id: assignableAccount.id, transaction: assignment });
                vm.setAssignableAccounts();
            }
            vm.quickRefreshAccountBalanceAvailable(assignableAccount);
            if(assignment.transaction.type !== 'credit'){
                vm.loadPrimaryCheckingAccount();
            }
        }
        if(transactionType === 'delete'){
            vm.removeTransactionState(assignment, assignableAccount);
        }
    }
}
function formatTransactionAsAssignment(transaction){
    return {
        allocated_amount: 0,
        transaction
    };
}
