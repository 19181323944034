import SubscriptionVerificationMixin from 'vue_root/mixins/subscriptionVerification.mixin';
import CanUserMixin from 'vue_root/mixins/can-user.mixin.js';

export default {
    data,
    computed: getComputed(),
    methods: getMethods(),
    mixins: [CanUserMixin, SubscriptionVerificationMixin],
};

function data(){
    return {
        loadingPlaidLinkModal: false,
        isPlaidButtonActive: false,
        updateModeInstitutionCredential: null,
        credentialOverlapError: null
    };
}

function getComputed(){
    return {
        canManagePlaidAccounts,
    };

    function canManagePlaidAccounts(){
        return this.verifySubscriptionPlan('plus') && this.canUser('manage plaid-accounts') && !this.$store.getters['user/isLoading'];
    }
}

function getMethods(){
    return {
        getAllPlaidIframes,
        ensureClearPreviousPlaidIframes,
        hideDefaultLoadingPlaidTemporarily,
        showToastMessage,
        showModalPlaid,
        syncInstitutionAndAccounts,
        exchangePublicToken,
        wasIntegratedPreviously,
        updateInstitutionItemExisting,
        continueToAddNewAccounts,
        handlerExchangeToken,
        addNewAccounts,
        handlerUpdateModeForNewAccounts,
        openPlaidConnect,
        openPlaidConnectUpdateMode,
        openPlaidConnectUpdateModeForNewAccounts,
        connectPlaidComplete,
        updateModePlaidComplete,
        canUsePlaid,
    };

    function getAllPlaidIframes(){
        return Array.from(document.querySelectorAll('[id^="plaid-link-iframe-"]'));
    }

    function ensureClearPreviousPlaidIframes(){
        this.getAllPlaidIframes().forEach(e => e.remove());
    }

    function hideDefaultLoadingPlaidTemporarily(){
        const lastIFramePlaid = this.getAllPlaidIframes().pop();
        lastIFramePlaid.style.opacity = 0;

        return lastIFramePlaid;
    }

    function showToastMessage(error, customMessage = null, title = null, variant = null){
        let errorMessage = 'Sorry, we are experiencing instability and it was not possible to complete your request.';

        if(error){
            console.error(error);

            if(error.data){
                errorMessage = error.data.message;
            }
        }

        const message = customMessage || (error.statusText || errorMessage);

        this.$bvToast.toast(message, {
            title: title || 'Error',
            variant: variant || 'danger',
            solid: true,
        });
    }

    function showModalPlaid(lastIframePlaid){
        lastIframePlaid.style.removeProperty('opacity');
    }

    async function syncInstitutionAndAccounts(data, metadata){
        const payload = {
            accessToken: data.access_token,
            itemId: data.item_id,
            requestId: data.request_id,
            accounts: metadata.accounts.map(i => i.id),
            institution: metadata.institution,
            linkSessionId: metadata.link_session_id,
            accountId: metadata.account_id,
        };

        try {
            await Vue.appApi().authorized().plaid().syncIntegration(payload);

            this.connectPlaidComplete();
        } catch(err){
            if(err.data && typeof err.data.data !== 'undefined'){
                Object.values(err.data.data).forEach((item) => {
                    item.forEach((msg) => this.showToastMessage(err, msg));
                });

                return;
            }

            this.showToastMessage(err);
        } finally {
            this.isPlaidButtonActive = false;
        }
    }

    function exchangePublicToken(publicToken, metadata, institutionId = null){
        return Vue.appApi()
            .authorized()
            .plaid()
            .exchangePublicToken(publicToken, metadata.institution.institution_id)
            .then(({ data }) => {
                this.syncInstitutionAndAccounts(data, metadata);
            }).catch(err => {
                this.isPlaidButtonActive = false;

                if(typeof err.data.data !== 'undefined'){
                    Object.values(err.data.data).forEach((item) => {
                        item.forEach((msg) => this.showToastMessage(err, msg));
                    });

                    return;
                }

                this.showToastMessage(err);
            });
    }

    async function wasIntegratedPreviously(payload){
        try {
            const response = await Vue.appApi()
                .authorized()
                .plaid()
                .isInstitutionIntegrated(payload);

            return response.data;
        } catch(err){
            this.isPlaidButtonActive = false;
            this.showToastMessage(err);
        }

        return false;
    }

    function updateInstitutionItemExisting(metadata, institutionCredential){
        this.updateModeInstitutionCredential = institutionCredential;
        this.$refs.updateModeNewAccountsFlow.show();
    }

    function continueToAddNewAccounts(){
        this.loadingPlaidLinkModal = true;
        this.isPlaidButtonActive = true;

        if(this.updateModeInstitutionCredential !== null){
            this.$refs.updateModeNewAccountsFlow.hide();
            this.openPlaidConnectUpdateModeForNewAccounts(this.updateModeInstitutionCredential.id);

            return;
        }

        this.loadingPlaidLinkModal = false;
        this.isPlaidButtonActive = false;

        this.showToastMessage(null, 'Not able identify institution.', 'Add new account error');
    }

    async function handlerExchangeToken(linkToken){
        const plaid = await Plaid.create({
            token: linkToken,
            onSuccess: async(publicToken, metadata) => {
                const { data: institutionCredentials } = await this.wasIntegratedPreviously(metadata);

                if(institutionCredentials && institutionCredentials.length > 1){
                    this.credentialOverlapError = {
                        institutionName: institutionCredentials[0].institution.name,
                        error: 'You have multiple plaid items linked with the same credential. Please unlink one of them and try again.'
                    };
                    this.$refs.credentialOverlapErrorModal.show();
                    return;
                }

                const institutionCredential = institutionCredentials && institutionCredentials[0];

                if(institutionCredential){
                    this.updateInstitutionItemExisting(metadata, institutionCredential);
                    return;
                }

                this.exchangePublicToken(publicToken, metadata);
            },
            onExit: () => {
                this.isPlaidButtonActive = false;
            },
            onEvent: (eventName, metadata) => {
                this.loadingPlaidLinkModal = false;
                this.showModalPlaid(lastIframePlaid);
            }
        });

        const lastIframePlaid = this.hideDefaultLoadingPlaidTemporarily();

        return plaid;
    }

    async function addNewAccounts(metadata, institutionCredentialId, allowNoAccountChange = false){
        const payload = {
            accounts: metadata.accounts.map(i => i.id),
            institution: metadata.institution,
            institutionCredentialId,
            allowNoAccountChange
        };

        try {
            const response = await Vue.appApi().authorized().plaid().addNewAccounts(payload);

            if(response.status === 202){
                this.showToastMessage(null, response.data.message, 'Info', 'info');

                return;
            }

            this.connectPlaidComplete();
        } catch(err){
            if(err.data && typeof err.data.data !== 'undefined'){
                Object.values(err.data.data).forEach((item) => {
                    item.forEach((msg) => this.showToastMessage(err, msg));
                });

                return;
            }

            this.showToastMessage(err);
        } finally {
            this.isPlaidButtonActive = false;
        }
    }

    async function handlerUpdateModeForNewAccounts(linkToken, institutionCredentialId, allowNoAccountChange = false){
        const plaid = await Plaid.create({
            token: linkToken,
            onSuccess: async(publicToken, metadata) => {
                const { data: institutionCredentials } = await this.wasIntegratedPreviously(metadata);
                if(institutionCredentials && institutionCredentials.length > 0){
                    const overlappedWithOtherCredential = institutionCredentials.some(institutionCredential => institutionCredential.id !== institutionCredentialId);
                    if(overlappedWithOtherCredential){
                        this.credentialOverlapError = {
                            institutionName: institutionCredentials[0].institution.name,
                            error: 'Some of your new accounts are already linked. Please unlink them and try again.'
                        };
                        this.$refs.credentialOverlapErrorModal.show();
                        return;
                    }
                }

                await this.addNewAccounts(metadata, institutionCredentialId, allowNoAccountChange);
            },
            onExit: () => {
                this.isPlaidButtonActive = false;
            },
            onEvent: () => {
                this.loadingPlaidLinkModal = false;
                this.showModalPlaid(lastIframePlaid);
            }
        });

        const lastIframePlaid = this.hideDefaultLoadingPlaidTemporarily();

        return plaid;
    }

    async function openPlaidConnect(){
        if(!this.canUsePlaid()){
            return;
        }

        this.isPlaidButtonActive = true;
        this.loadingPlaidLinkModal = true;
        this.ensureClearPreviousPlaidIframes();

        try {
            const createLinkToken = await Vue.appApi().authorized().plaid().createLinkToken();
            const handlerExchangeToken = await this.handlerExchangeToken(createLinkToken.data.link_token);

            handlerExchangeToken.open();
        } catch(err){
            this.isPlaidButtonActive = false;
            this.loadingPlaidLinkModal = false;
            this.showToastMessage(err);
        }
    }

    async function openPlaidConnectUpdateMode(institutionCredentialId){
        if(!this.canUsePlaid()){
            return;
        }

        this.isPlaidButtonActive = true;
        this.loadingPlaidLinkModal = true;
        this.ensureClearPreviousPlaidIframes();

        try {
            const createLinkToken = await Vue.appApi().authorized().plaid().createLinkUpdateModeToken(institutionCredentialId);
            const data = createLinkToken.data.data;
            const handlerUpdateMode = await this.handlerUpdateModeForNewAccounts(data.link_token, institutionCredentialId, true);

            handlerUpdateMode.open();
        } catch(err){
            this.isPlaidButtonActive = false;
            this.loadingPlaidLinkModal = false;
            this.showToastMessage(err);
        }
    }

    async function openPlaidConnectUpdateModeForNewAccounts(institutionCredentialId){
        if(!this.canUsePlaid()){
            return;
        }

        this.isPlaidButtonActive = true;
        this.loadingPlaidLinkModal = true;
        this.ensureClearPreviousPlaidIframes();

        try {
            const createLinkToken = await Vue.appApi().authorized().plaid().createLinkUpdateModeTokenForNewAccounts(institutionCredentialId);
            const data = createLinkToken.data.data;
            const handlerUpdateMode = await this.handlerUpdateModeForNewAccounts(data.link_token, institutionCredentialId);

            handlerUpdateMode.open();
        } catch(err){
            this.isPlaidButtonActive = false;
            this.loadingPlaidLinkModal = false;
            this.showToastMessage(err);
        }
    }

    function connectPlaidComplete(){
        this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID');
    }

    function updateModePlaidComplete(){
        this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID');
        this.$store.dispatch('authorized/bankAccounts/FETCH_BANK_ACCOUNTS')
            .then(() => {
                const successMessage = `We will now refresh your account to bring in your latest balances and credit card charges. This may take a minute or two.`;

                this.$bvToast.toast(successMessage, {
                    title: 'Link Repaired',
                    variant: 'success',
                });

                this.$store.dispatch('authorized/FETCHING_DATA_FROM_PLAID', false);
            }).finally(() => {
                this.isPlaidButtonActive = false;
                this.loadingPlaidLinkModal = false;
                this.$parent.bankAccountToRepair = null;
            });
    }

    function canUsePlaid(){
        return this.canManagePlaidAccounts;
    }
}
