// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.couponsComponent .searchInput[data-v-7e3c2d4f] {
  position: relative;
}
.couponsComponent .searchInput input[data-v-7e3c2d4f] {
  text-indent: 24px;
}
.couponsComponent .searchInput .fa-search[data-v-7e3c2d4f] {
  position: absolute;
  top: 10px;
  left: 18px;
}
.couponsComponent[data-v-7e3c2d4f] th {
  font-weight: 600;
}
.couponsComponent .fadeHeight-enter-active[data-v-7e3c2d4f],
.couponsComponent .fadeHeight-leave-active[data-v-7e3c2d4f] {
  transition: all 0.5s ease-in-out;
  max-height: 2300px;
  overflow: hidden;
}
.couponsComponent .fadeHeight-enter[data-v-7e3c2d4f],
.couponsComponent .fadeHeight-leave-to[data-v-7e3c2d4f] {
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}
.couponsComponent .fade-enter-active[data-v-7e3c2d4f],
.couponsComponent .fade-leave-active[data-v-7e3c2d4f] {
  transition: all 0.5s ease-in-out;
}
.couponsComponent .fade-enter[data-v-7e3c2d4f],
.couponsComponent .fade-leave-to[data-v-7e3c2d4f] {
  opacity: 0;
}`, "",{"version":3,"sources":["webpack://./_coupons.scss"],"names":[],"mappings":"AAII;EACI,kBAAA;AAHR;AAIQ;EACI,iBAAA;AAFZ;AAIQ;EACI,kBAAA;EACA,SAAA;EACA,UAAA;AAFZ;AAOQ;EACI,gBAAA;AALZ;AAQI;;EAEI,gCAAA;EACA,kBAAA;EACA,gBAAA;AANR;AAQI;;EAGI,UAAA;EACA,eAAA;EACA,gBAAA;AAPR;AASI;;EAEI,gCAAA;AAPR;AASI;;EAGI,UAAA;AARR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.couponsComponent {\n\n    .searchInput {\n        position: relative;\n        input {\n            text-indent: 24px;\n        }\n        .fa-search {\n            position: absolute;\n            top: 10px;\n            left: 18px;\n        }\n    }\n\n    ::v-deep {\n        th {\n            font-weight: 600;\n        }\n    }\n    .fadeHeight-enter-active,\n    .fadeHeight-leave-active {\n        transition: all 0.5s ease-in-out;\n        max-height: 2300px;\n        overflow: hidden;\n    }\n    .fadeHeight-enter,\n    .fadeHeight-leave-to\n    {\n        opacity: 0;\n        max-height: 0px;\n        overflow: hidden;\n    }\n    .fade-enter-active,\n    .fade-leave-active {\n        transition: all 0.5s ease-in-out;\n    }\n    .fade-enter,\n    .fade-leave-to\n    {\n        opacity: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
