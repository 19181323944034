export default {
    namespaced: true,
    state: {
        logs: []
    },
    actions: {
        log({ commit }, payload){
            commit('pushLog', payload);
        }
    },
    mutations: {
        pushLog(state, payload){
            const msg = '[' + new Date().toISOString() + '] ' + payload;
            state.logs.push(msg);
            console.log(msg);
        }
    },
};

