export default {
    props: {
        bankAccount: {
            type: Object,
            required: true,
        },
        activeScheduleItem: {
            type: Object
        },
        months: {
            type: Array,
        },
        onFormEdit: { type: Function }
    },
    data,
    created,
    watch: getWatchers(),
    computed: getComputed(),
    methods: getMethods(),
};

function data(){
    return {
        scheduleItemForm: {
            amount_monthly: '',
            amount_total: undefined,
            bank_account_id: null,
            description: '',
            id: null,
            isCalculatingMonthlyAmount: false,
            isDirty: false,
            is_selected: true,
            type: 'monthly',
            approximate_due_date: null,
        },
        defaultTypeOptions: [
            { value: null, text: 'frequency', disabled: true },
            { value: 'monthly', text: 'Monthly' },
            { value: 'yearly', text: 'Yearly' },
            { value: 'target_date', text: 'Target Date' },
        ],
        isSaving: false,
        isDeleting: false,
        daysOptions: [],
        monthsOptions: [],
        errorMessages: [],
        isConfirmingDeletion: false,
    };
}

function created(){
    const vm = this;
    initializeDayOptions();
    initializeMonthOptions();

    function initializeDayOptions(){
        vm.daysOptions = [
            { value: null, text: '~ due date', disabled: true }
        ];
        for(var i = 1; i <= 31; i++){
            vm.daysOptions.push({ value: i, text: getOrdinalNumber(i) });
        }
    }

    function initializeMonthOptions(){
        vm.monthsOptions = [
            { value: null, text: '~ due date', disabled: true }
        ];
        vm.months.forEach(month => {
            vm.monthsOptions.push({ value: month, text: month });
        });
    }

    function getOrdinalNumber(number){
        if(number % 100 >= 11 && number % 100 <= 13){
            return number + 'th';
        }
        switch (number % 10){
            case 1:
                return number + 'st';
            case 2:
                return number + 'nd';
            case 3:
                return number + 'rd';
            default:
                return number + 'th';
        }
    }
}

function getComputed(){
    return {
        bankAccountId(){
            const vm = this;
            return vm.bankAccount.id;
        },
    };
}

function getWatchers(){
    return {
        activeScheduleItem: {
            deep: true,
            immediate: true,
            handler: function(newVal){
                const vm = this;
                if(newVal){
                    vm.scheduleItemForm = Object.assign({}, newVal);
                }
            },
        },
    };
}

function getMethods(){
    return {
        onChangeTotalTypeOrDate,
        saveScheduleItem,
        deleteScheduleItem,
        displayErrorMessage,
        onEdittingForm,
    };

    function onChangeTotalTypeOrDate(changeType){
        const vm = this;

        vm.scheduleItemForm.isCalculatingMonthlyAmount = true;
        vm.scheduleItemForm.isDirty = true;
        vm.$emit('onFormEdit');
        if(changeType === 'type'){
            vm.scheduleItemForm.approximate_due_date = null;
        }

        if(vm.scheduleItemForm.sourceAppHttpCancelToken){
            vm.scheduleItemForm.sourceAppHttpCancelToken.cancel();
            vm.scheduleItemForm.sourceAppHttpCancelToken = false;
        }
        const promiseCalculateMonthlyAmount = Vue.appApi()
            .authorized()
            .bankAccount()
            .scheduleItem()
            .calculateMonthlyAmount(vm.scheduleItemForm);

        vm.scheduleItemForm.sourceAppHttpCancelToken = promiseCalculateMonthlyAmount.sourceAppHttpCancelToken;
        promiseCalculateMonthlyAmount
            .then(updateMonthlyAmount)
            .catch(vm.displayErrorMessage)
            .finally(_ => {
                vm.scheduleItemForm.isCalculatingMonthlyAmount = false;
                vm.scheduleItemForm.sourceAppHttpCancelToken = false;
            });

        function updateMonthlyAmount(response){
            vm.scheduleItemForm.amount_monthly = response.data.amount_monthly;
        }
    }

    function saveScheduleItem(){
        const vm = this;
        const scheduleItem = { ...vm.scheduleItemForm, bank_account_id: vm.bankAccountId };

        vm.isSaving = true;
        return Vue.appApi().authorized().bankAccount().scheduleItem().store(scheduleItem)
            .then(updateScheduleItem)
            .catch(vm.displayErrorMessage)
            .finally(clearFlags);

        function updateScheduleItem(){
            return vm.$store.dispatch('scheduleItem/GET_DATA', { id: vm.bankAccountId, force: true })
                .then(() => vm.$emit('setScheduledItems'))
                .catch(vm.displayErrorMessage)
                .finally();
        }

        function clearFlags(){
            vm.isSaving = false;
        }
    }

    function deleteScheduleItem(){
        const vm = this;
        const scheduleItem = vm.activeScheduleItem;
        vm.isDeleting = true;
        const scheduleItemId = scheduleItem.id;

        if(scheduleItemId){
            Vue.appApi().authorized().bankAccount().scheduleItem().destroy(vm.activeScheduleItem.id)
                .then(() => {
                    return vm.$store.dispatch('scheduleItem/GET_DATA', { id: vm.bankAccountId, force: true })
                        .then(() => vm.$emit('setScheduledItems'))
                        .catch(vm.displayErrorMessage)
                        .finally(() => {
                            vm.isDeleting = false;
                        });
                })
                .catch(vm.displayErrorMessage);
        }
    }

    function displayErrorMessage(error){
        const vm = this;
        if(error.appMessage){
            vm.errorMessages.push(error.appMessage);
        }
    }

    function onEdittingForm(){
        this.scheduleItemForm.isDirty = true;
        this.$emit('onFormEdit');
    }
}
