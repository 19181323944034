// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iosLoginOverlay[data-v-e1e82f32] {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 5000;
}`, "",{"version":3,"sources":["webpack://./_ios-login-overlay.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,aAAA;AACJ","sourcesContent":[".iosLoginOverlay {\n    position: fixed;\n    top: 0;\n    height: 100vh;\n    width: 100vw;\n    background-color: white;\n    z-index: 5000;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
