export default {
    props: [
        'name',
        'type',
        'placeholder',
        'validationErrors',
        'value',
        'readOnly',
        'disabled',
        'mode',
        'focus',
    ],
    data: data,
    watch: {
        'inputValue': updateParent,
        'value': syncValue
    },
    created: syncValue,
    methods: {
        displayAsValidationFailedText(isValidationFailed = false){
            return isValidationFailed ? 'text-danger' : '';
        },
        onInputFocus(){
            this.displayPasswordRequirementsPanel = true;
            this.$emit('focus');
        }
    }
};

function data(){
    return {
        inputValue: '',
        displayPasswordRequirementsPanel: false,
    };
}

function updateParent(){
    this.$emit('input', this.inputValue);
}

function syncValue(){
    this.inputValue = this.value;
}
