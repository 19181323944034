// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inlineTourComponent[data-v-38f7e7be] {
  z-index: 1001;
  width: 100%;
}
.inlineTourComponent .modal-content[data-v-38f7e7be] {
  border: 1px solid #3E32C4;
}
.inlineTourComponent.animatedTourExit[data-v-38f7e7be] {
  position: absolute;
  background-color: white;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./_tour-inline.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,WAAA;AADJ;AAEI;EACI,yBAAA;AAAR;AAEI;EACI,kBAAA;EACA,uBAAA;EACA,gBAAA;AAAR","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.inlineTourComponent {\n    z-index: 1001;\n    width: 100%;\n    .modal-content {\n        border: 1px solid $primary;\n    }\n    &.animatedTourExit {\n        position: absolute;\n        background-color: white;\n        overflow: hidden;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
