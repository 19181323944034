import { render, staticRenderFns } from "./purchase.vue?vue&type=template&id=c3c9094c"
import script from "./purchase.controller.js?vue&type=script&lang=js&external"
export * from "./purchase.controller.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports