// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-view .text-reduced[data-v-054fd70a] {
  font-size: 0.8rem;
}
.login-view .login-view__biometric-button[data-v-054fd70a] {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: rgba(62, 50, 196, 0.1);
  margin-right: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.login-view .login-view__biometric-button i[data-v-054fd70a] {
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./_login.scss"],"names":[],"mappings":"AAGI;EACI,iBAAA;AAFR;AAKI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,wCAAA;EACA,iBAAA;EAEA,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AAJR;AAKQ;EACI,eAAA;AAHZ","sourcesContent":["@import \"~vue_root/assets/scss/_variables.scss\";\n\n.login-view {\n    .text-reduced {\n        font-size: 0.8rem;\n    }\n\n    .login-view__biometric-button {\n        width: 36px;\n        height: 36px;\n        border-radius: 100%;\n        background-color: rgba($primary, 0.1);\n        margin-right: 3px;\n\n        display: inline-flex;\n        justify-content: center;\n        align-items: center;\n        padding: 0;\n        i {\n            font-size: 20px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
